import { useRef, useState, useCallback } from 'react';
import { getUser } from '../../../../helpers/apiHelper';


interface WebRTCConfig {
  api: string;
  videoTransform: string;
  clientId: string;
}

interface UseWebRTCResult {
  pcRef: React.RefObject<RTCPeerConnection>;
  dataChannelRef: React.RefObject<RTCDataChannel>;
  webrtcVideoRef: React.RefObject<HTMLVideoElement>;
  isInferencing: boolean;
  setIsInferencing: React.Dispatch<React.SetStateAction<boolean>>;
  videoMaskStream: MediaStream | null;
  setVideoMaskStream: React.Dispatch<React.SetStateAction<MediaStream | null>>;
  initializeConnection: (stream: MediaStream) => void;
  closeConnection: () => void;
  createPeerConnection: () => RTCPeerConnection;
  negotiate: () => Promise<void>;
}

export const useWebRTC = ({ api, videoTransform, clientId }: WebRTCConfig): UseWebRTCResult => {
  const pcRef = useRef<RTCPeerConnection | null>(null);
  const dataChannelRef = useRef<RTCDataChannel | null>(null);
  const webrtcVideoRef = useRef<HTMLVideoElement>(null);
  const [isInferencing, setIsInferencing] = useState(false);
  const [videoMaskStream, setVideoMaskStream] = useState<MediaStream | null>(null);

  const createPeerConnection = useCallback(() => {
    console.log("============================ createPeerConnection");
    
    const config = {
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    };
    const pc = new RTCPeerConnection(config);
    console.log('test ecent55',webrtcVideoRef.current);
    pc.ontrack = event => {
      console.log('test ecent', event.track.kind);
      console.log('webrtcVideoRef.current', webrtcVideoRef.current);
      if (event.track.kind === 'video' && webrtcVideoRef.current) {
        webrtcVideoRef.current.srcObject = event.streams[0];
        console.log('webrtcVideoRef.current', webrtcVideoRef.current);
        setVideoMaskStream(event.streams[0]);
      }
    };

    return pc;
  }, []);

  const negotiate = useCallback(async () => {
    console.log("============================ negotiate");

    console.log("==================== testing", pcRef.current);
    
    if (!pcRef.current) return;

    try {
      const offer = await pcRef.current.createOffer();
      console.log("==================== testing 2");

      await pcRef.current.setLocalDescription(offer);

      // Wait for ICE gathering to complete
      await new Promise<void>(resolve => {
        if (pcRef.current?.iceGatheringState === 'complete') {
          resolve();
        } else {
          const checkState = () => {
            if (pcRef.current?.iceGatheringState === 'complete') {
              pcRef.current?.removeEventListener('icegatheringstatechange', checkState);
              resolve();
            }
          };
          pcRef.current?.addEventListener('icegatheringstatechange', checkState);
        }
      });

      const response = await fetch(`${api}/offer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sdp: pcRef.current.localDescription?.sdp,
          type: pcRef.current.localDescription?.type,
          video_transform: videoTransform,
          client_id: clientId,
        }),
      });

      const data = await response.json();
      await pcRef.current.setRemoteDescription(new RTCSessionDescription(data));
    } catch (error) {
      console.error('Negotiation failed:', error);
    }
  }, [api, videoTransform, clientId]);

  const initializeConnection = useCallback((stream: MediaStream) => {
    console.log("============================ initializeConnection");

    pcRef.current = createPeerConnection();

    // Create data channel
    const dataChannel = pcRef.current.createDataChannel('chat');
    dataChannelRef.current = dataChannel;

    // Add tracks from the cropped stream
    stream.getTracks().forEach(track => {
      pcRef.current?.addTrack(track, stream);
    });

    setIsInferencing(true);
    negotiate();
  }, [createPeerConnection, negotiate]);

  const closeConnection = useCallback(() => {
    console.log("============================ closeConnection");

    if (dataChannelRef.current) {
      dataChannelRef.current.close();
      dataChannelRef.current = null;
    }

    if (pcRef.current) {
      pcRef.current.close();
      pcRef.current = null;
    }

    setIsInferencing(false);
    setVideoMaskStream(null);
  }, []);

  return {
    pcRef,
    dataChannelRef,
    webrtcVideoRef,
    isInferencing,
    setIsInferencing,
    videoMaskStream,
    setVideoMaskStream,
    initializeConnection,
    closeConnection,
    createPeerConnection,
    negotiate
  };
};