import { useInfiniteQuery, useMutation, useQuery as useQuery_temp, useQueryClient, UseQueryOptions, QueryFunctionContext} from '@tanstack/react-query';
import pushNotification from '../../UI/Pages/PatientManagement/notifications';

type MutationFn<TData = unknown> = (data: TData) => Promise<any>;
type UpdaterFn = () => void;
export type QueryKeyT = [string, object | undefined] | [string] | any;

import { APIClient } from '../apiHelper';

export const api = new APIClient();


export function useGenericMutation<TData = unknown>(
  mutationFn: MutationFn<TData>,
  queryKey: string[],
  errorMsg: string = 'Error while performing mutation',
  successMsg: string = 'Mutation performed successfully',
  updater: UpdaterFn = () => {},
  onSuccess?: (response, data) => void,
  onError?: (response, data) => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onMutate: async (data: TData) => {
      await queryClient.cancelQueries({ queryKey: queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      return previousData;
    },
    onSuccess: (response, data) => {
      console.log('onSuccess');
      if (onSuccess) {
        onSuccess(response, data);
        pushNotification.success(successMsg);
      }
    },

    onError: (error, data, context) => {
      queryClient.setQueryData(queryKey, context);
      console.log('onError');

      if (onError) {
        onError(error, data);
        pushNotification.error(errorMsg);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
    },
  });
}


export const useGenericMutation_old = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  queryKey?: QueryKeyT,
  onSuccess?: (response: AxiosResponse<S>, data: T | S) => void,
  onError?: (error: AxiosError<S>, data: T | S) => void
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<S>, AxiosError<S>, T | S>({
    mutationFn: func,

    onMutate: async data => {
      const key = queryKey || [url!, params];
      await queryClient.cancelQueries({ queryKey: key });
      const previousData = queryClient.getQueryData(key);

      if (updater) {
        queryClient.setQueryData<T>(key, oldData => {
          if (Array.isArray(oldData)) {
            return updater(oldData, data as S);
          } else {
            return updater([] as unknown as T, data as S);
          }
        });
      }

      return previousData;
    },

    onSuccess: (response, data) => {
      if (onSuccess) {
        onSuccess(response, data);
      }
    },

    onError: (error, data, context) => {
      console.log('============ Mutation error:', error);
      console.log('============ Mutation data:', data);
      const key = queryKey || [url!, params];
      queryClient.setQueryData(key, context);
      
      if (onError) {
        onError(error, data);
      }
    },

    onSettled: () => {
      const key = queryKey || [url!, params];
      queryClient.invalidateQueries({ queryKey: key });
    },
  });
};


interface QueryConfig {
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
  retry?: number;
  [key: string]: any;
}

export function useFetch<TData = unknown>(
  queryFn: () => Promise<TData>,
  queryKey: string[] | null,
  config: QueryConfig = {}
) {
  return useQuery_temp({
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 5000,
    refetchOnWindowFocus: true,
    retry: 1,
    ...config,
  });
}

export function useInfiniteFetch<TData = unknown>(
  queryFn: (pageParam: number) => Promise<TData>,
  queryKey: string[],
  getNextPageParam: (lastPage: TData) => number | null,
  initialPageParam: number,
  config: QueryConfig = {}
) {
  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    getNextPageParam: getNextPageParam,
    initialPageParam: initialPageParam,
    ...config,
  });
}

export function useCreate<TData = unknown>(
  createFn: MutationFn<TData>,
  queryKey: string[],
  errorMsg: string = 'Error while creating new entity',
  successMsg: string = 'Entity edited successfully',

) {
  return useGenericMutation<TData>(createFn, queryKey, errorMsg, successMsg);
}

export function useEdit<TData = unknown>(
  editFn: MutationFn<TData>,
  queryKey: string[],
  errorMsg: string = 'Error while editing entity',
  successMsg: string = 'Entity edited successfully',
  onSuccess?: (data: TData) => void,
  onError?: (error: Error) => void

) {
  return useGenericMutation<TData>(editFn, queryKey, errorMsg, successMsg, null ,onSuccess, onError);
}

export function useDelete<TData = unknown>(
  deleteFn: MutationFn<TData>,
  queryKey: string[],
  errorMsg: string = 'Error while deleting entity',
  successMsg: string = 'Entity deleted successfully'
) {
  return useGenericMutation<TData>(deleteFn, queryKey, errorMsg, successMsg);
}

export function usePaginated<TData = unknown>(
  getPaginatedFn: (page: number) => Promise<TData>,
  queryKey: string[],
  page: number,
  config: QueryConfig = {}
) {
  return useFetch(() => getPaginatedFn(page), queryKey, config);
}

export function useInfinite<TData = unknown>(
  getInfiniteUsersFn: (pageParam: number) => Promise<TData>,
  queryKey: string[],
  getNextPageParam: (lastPage: TData) => number | null,
  config: QueryConfig = {}
) {
  return useInfiniteFetch(getInfiniteUsersFn, queryKey, getNextPageParam, 1, config);
}

export function useSingleFetch<TData = unknown>(
  getSingleFn: (id?: string | number) => Promise<TData>,
  queryKey: string[] | null,
  id?: string | number,
  config: QueryConfig = {}
) {
  return useFetch(() => getSingleFn(id), queryKey, config);
}

export const useFetch_old = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT> | any,
  queryKey?: QueryKeyT
) => {
  const context = useQuery<T, Error, T, QueryKeyT>({
    queryKey: [url || '', params, queryKey],
    queryFn: fetcher,
    ...config,
  });

  return context;
};

export const fetcher = async <T>({
  queryKey,
  pageParam, // to use after for loading more
  signal, // to use after
}: QueryFunctionContext<QueryKeyT>): Promise<any> => {
  const [url, params] = queryKey;
  try {
    const response = await api.get(url, params);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

import { AxiosError, AxiosResponse } from 'axios';
type Updater<T, S> = (oldData: T | undefined, newData: S) => T;


export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  onSuccess?: (response: AxiosResponse<S>, data: T | S) => void,
  onError?: (error: AxiosError<S>, data: T | S) => void
) => {
  const apiPost: (data: T) => Promise<AxiosResponse<S>> = async (data) => {
    try {
      const response = await api.post(url, data);
      console.log('============= response', response);
      return response;
    } catch (error) {
      console.log('============ error', error);
      throw error; 
    }
  };

  return useGenericMutation_old<T, S>(
    apiPost,
    url,
    params,
    updater,
    undefined,
    onSuccess,
    onError
  );
};






interface APIErrorResponse {
  message: string;
  status_code: number;
  error: string;
  details: {
    sop_instance_uid?: string;
    original_study_info?: string;
    [key: string]: any;
  };
}

export function useGenericMutation_merged<TData = unknown, TResponse = any, TError = APIErrorResponse>(
  mutationFn: (data: TData) => Promise<TResponse>,
  queryKey: string[],
  errorMsg: string = 'Error while performing mutation',
  successMsg: string = 'Mutation performed successfully',
  updater?: (oldData: any, newData: TData) => any,
  onSuccess?: (response: TResponse, data: TData) => void,
  onError?: (error: TError, data: TData) => void,
) {
  const queryClient = useQueryClient();

  return useMutation<TResponse, TError, TData>({
    mutationFn,
    
    onMutate: async (data: TData) => {
      await queryClient.cancelQueries({ queryKey: queryKey });
      const previousData = queryClient.getQueryData(queryKey);

      // Apply optimistic update if updater is provided
      if (updater) {
        queryClient.setQueryData(queryKey, (oldData: any) => {
          return updater(oldData, data);
        });
      }

      return previousData;
    },

    onSuccess: (response, data) => {
      if (onSuccess) {
        onSuccess(response, data);
      }
      pushNotification.success(successMsg);
    },

    onError: (error, data, context) => {
      // Rollback to previous state
      queryClient.setQueryData(queryKey, context);

      if (onError) {
        onError(error, data);
      }

      // Use API error message if available, fallback to default error message
      const apiError = error as APIErrorResponse;
      const displayMessage = apiError?.message || errorMsg;
      pushNotification.error(displayMessage);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
    },
  });
}



export const usePost_old = <T, S>(
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  onSuccess?: (response: AxiosResponse<S>, data: T | S) => void,
  onError?: (error: any, data: T | S) => void
) => {

  const apiPost: (data: T) => Promise<AxiosResponse<S>> = (data) => api.post(url, data);

  return useGenericMutation_old<T, S>(apiPost, url, params, updater, undefined, onSuccess, onError);
};



export const usePut_old = <T, S>(
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  queryKey?: QueryKeyT
) => {
  const apiPut: (data: T) => Promise<AxiosResponse<S>> = data => api.put(url, data);
  return useGenericMutation_old<T, S>(apiPut, url, params, updater, queryKey);
};

export interface GetInfinitePagesInterface<T> {
  nextId?: number;
  previousId?: number;
  data: T;
  count: number;
}

export const useLoadMore = <T>(url: string | null, params?: object) => {
  const context = useInfiniteQuery<
    GetInfinitePagesInterface<T>,
    Error,
    GetInfinitePagesInterface<T>,
    QueryKeyT
  >({
    queryKey: [url || '', params],
    queryFn: fetcher,
    getNextPageParam: (lastPage, pages) => lastPage.nextId,
    getPreviousPageParam: (lastPage, pages) => lastPage.previousId,
    initialPageParam: null,
  });

  return context;
};



export function useQuery<TData = unknown>(
  queryFn: () => Promise<TData>,
  queryKey: string[] | null,
  config: QueryConfig = {}
) {
  return useQuery_temp({
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 5000,
    refetchOnWindowFocus: true,
    retry: 1,
    ...config,
  });
}