import React, { useEffect, useMemo, useState } from 'react';
import realtimeImg from '../../../../assets/realTime_.png';
import PatientModal from './components/modal-patient';
import { Patient } from '../../Sections/PatientsList/patientsList.section.custom';
import { postNewPatient } from '../../../helpers/backendHelper';
import { getPatients } from '../../../store/actions';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, redirect, useNavigate } from 'react-router';

interface RealTimeProps {
  title: string;
  description: string;
}
export interface errorPatient {
  input_name: string;
  type: string;
  message: string;
  status: boolean;
  patient: Patient;
}
function RealTime({ title, description }: RealTimeProps) {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [patientID, setPatientID] = useState(0);
  const [newPatient, setNewPatient] = useState<Patient>({
    patient_name: '',
    patient_last_name: '',
  });

  const navigate = useNavigate();

  // const realtime_url = process.env.REALTIME_URL;
  // const url = `${realtime_url}/realtime`
  // const dispatch = useDispatch();
  // const { profile } = useSelector(state => ({
  //   profile: state.Profile,
  // }));
  // const [error, setError] = useState<Map<string, errorPatient>>();

  // const showModal = () => {
  //   setOpen(true);
  // };
  // const selectedProfile = useMemo(() => profile, [profile]);

  // const addNewPatient = async ({ orgId, data }: { orgId: string; data: Patient }) => {
  //   data.sex = 'F';
  //   data.weight = 63;
  //   data.height = 170;
  //   console.log('data = ', data);
  //   return postNewPatient(data);
  // };

  // const addPatientMutation = useMutation<any, any, any, unknown>({
  //   mutationFn: ({ orgId, data }: { orgId: string; data: Patient }) =>
  //     addNewPatient({ orgId, data }),
  //   onSuccess: response => {
  //     if (response) {
  //       dispatch(
  //         getPatients({
  //           filter_options: { ...patientsState.filtered_conditions, page: '', items_per_page: '' },
  //         })
  //       );
  //       // closeNewPatientModal();
  //     }
  //   },
  //   onError: error => {
  //     const formatedError = new Map<string, errorPatient>(
  //       error.map(item => [
  //         item.loc[1],
  //         {
  //           input_name: item.loc[1],
  //           type: item.type,
  //           message: item.msg,
  //           status: true,
  //         },
  //       ])
  //     );
  //     setError(formatedError);
  //   },
  // });
  // const handleGoToRealtime = () => {
  //   console.log('test 444');
  //   addPatientMutation.mutate({
  //     orgId: selectedProfile?.currentOrganization?.id,
  //     data: newPatient,
  //   });
  // };
  // useEffect(() => {
  //   setNewPatient(prevPatient => ({
  //     ...prevPatient,
  //     patient_name: firstName,
  //     patient_last_name: lastName,
  //   }));
  // }, [firstName, lastName]);

  return (
    <div className="flex h-full items-center justify-center">
      {/* <PatientModal
        open={open}
        setOpen={setOpen}
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        handleGoToRealtime={handleGoToRealtime}
        patientID={patientID}
        setPatientID={setPatientID}
      /> */}
      <div className="flex cursor-pointer flex-col items-center justify-center ">
        <h5 className="text-base font-normal text-[#21426D]">{title}</h5>
        <div className="h-[120px] w-[120px]">
          <img
            src={realtimeImg}
            onClick={() => {
              navigate('/realtime-v2');
            }}
          />
        </div>
        <div className="flex items-center justify-center px-6 text-center text-xs">
          {description}
        </div>
      </div>
    </div>
  );
}

export default RealTime;
