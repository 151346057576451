import React, { useEffect, useRef, useState } from 'react';
import { useGlobalState } from '../../../state/RealtimeGlobalContextProvider';
import { getUser } from '../../../helpers/apiHelper';
import { FaPrint, FaShare } from 'react-icons/fa6';
import Card from './components/card';
import { PiInfo } from 'react-icons/pi';
import { TfiLayers } from 'react-icons/tfi';
import RatingBar from './components/rating-bar';
import VideoModal from './components/video-full-screen';
import MultiSelectDropdown from './components/mulit-select-dropdown';
import ProgressBar from './components/progress-bar';
import GenerateReportModal from './components/report';


import StepperComponent from './components/stepper-component';
import SelectView from './components/select-view';
import { 
  MediaType, 
  mediaTypeMap, 
  DISPLAY_DIMENSIONS,
} from './constants';
import { useVideoProcessing } from './hooks/useVideoProcessing';
import { useWebRTC } from './hooks/useWebRTC';
// import { PLANES_CONFIGS } from './config/planes.config';
import { buildPlanes } from './utils/planeBuilder';
import { Plane } from './types/planes.types';
import VideoDisplay from './components/video-display';
import { INITIAL_GLOBAL_STATE } from './constants';
import LastDetectedPlanes from './components/last-detected-planes';
import { PLANES_CONFIGS } from './config/planes.config';




const RealTimeInferencingUDP: React.FC = () => {
  const user = getUser();


  const {
    videoStream,
    videoRef,
    processingCanvasRef,
    processedStreamRef,
    isVideoLoading,
    createCroppedStream,
    startVideoStream,
    stopVideoStream,
    setIsVideoLoading
  } = useVideoProcessing();


  const {
    webrtcVideoRef,
    isInferencing,
    setIsInferencing,
    videoMaskStream,
    initializeConnection,
    closeConnection,
    setVideoMaskStream,
    createPeerConnection,
    pcRef,  
    dataChannelRef, 
    negotiate 
  } = useWebRTC({
    api: process.env.BACKEND_URL,
    videoTransform: 'rotate',
    clientId: user?.profile?.sub,
  });

  const { globalState, setGlobalState } = useGlobalState();
  const planes: Plane[] = buildPlanes(PLANES_CONFIGS, globalState);



  const [isModalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [bool, setBool] = useState(true);

  const [deviceMediaType, setDeviceMediaType] = useState(MediaType.DISPLAY_MEDIA);

  const [useDataChannel] = useState(true);
  const [selectedPlansModal, setSelectedPlansModal] = useState('');
  const [selectedPlansTag, setSelectedPlansTag] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep,setCurrentStep] = useState(1)



  useEffect(() => {
    if (!globalState.isVideoFrozen && globalState?.isStart && !bool) {
      // Start WebRTC connection
      console.log("this owrk :1",globalState.isVideoFrozen)
      pcRef.current = createPeerConnection();
      console.log("this owrk :2",pcRef)
      if (useDataChannel) {
        const dataChannel = pcRef.current.createDataChannel('chat');
        dataChannelRef.current = dataChannel;
      }
      console.log("this owrk :3",videoStream)
      if (videoStream) {
        const videoTrack = videoStream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        const cropRegion = {
          x: 0, // Start from left edge
          y: 0, // Start from top edge
          width: settings.width, // Use actual video width from track settings
          height: settings.height // Use actual video height from track settings
        };

        // Create cropped stream
        const croppedStream = createCroppedStream(videoStream, cropRegion);
        console.log("croppedStream",croppedStream)
        if (croppedStream) {
          processedStreamRef.current = croppedStream;
          console.log("helllllllll")
          croppedStream.getTracks().forEach(track => {
            const settings = track.getSettings();
            const { width, height } = settings;
            console.log("====================== Track dimensions - width:", width, "height:", height);

            pcRef.current?.addTrack(track, croppedStream);
          });
        }

        // const applyConstraints = async (track) => {
        //   const constraints = {
        //     width: DISPLAY_DIMENSIONS.width,
        //     height: DISPLAY_DIMENSIONS.height,
        //     frameRate: 30,
        //   };
      
        //   try {
        //     await track.applyConstraints(constraints);
        //     console.log('Applied constraints:', track.getConstraints());
        //     console.log('Updated video settings:', track.getSettings());
        //   } catch (error) {
        //     console.error('Error applying constraints:', error);
        //   }
        // };


        // Get the video track settings
        // const { width, height } = videoTrack.getSettings();
        // videoStream.getTracks().forEach(track => {
        //   if (track.kind === "video") {
        //     // applyConstraints(track);
        //     const settings = track.getSettings();
        //     const { width, height } = settings;
        //     console.log("width::",width,height)
        //     setGlobalState(prevState => ({
        //       ...prevState,
        //       VIDEO_DIMENSIONS:{
        //         width:width,
        //         height:height
        //       },
        //     }));
        //   } 
        //   pcRef.current.addTrack(track, videoStream);
        // });
      }
      setIsInferencing(true);
      negotiate();
    } else {
      console.log("this owrk :")
      if (processedStreamRef.current) {
        processedStreamRef.current.getTracks().forEach(track => track.stop());
        processedStreamRef.current = null;
      }
      // Clean up WebRTC connection
      if (dataChannelRef.current) {
        dataChannelRef.current.close();
      }

      if (pcRef.current) {
        pcRef.current.close();
        pcRef.current = null;
      }

      // Ensure the original video feed is still displayed
      if (videoStream && videoRef.current) {
        videoRef.current.srcObject = videoStream;
      }

      setIsInferencing(false);
    }

    // Cleanup function
    return () => {
      if (processedStreamRef.current) {
        processedStreamRef.current.getTracks().forEach(track => track.stop());
      }
      if (pcRef.current) {
        pcRef.current.close();
        pcRef.current = null;
      }
    };
  }, [globalState.isVideoFrozen, globalState?.isStart, videoStream, bool]);

  const API = process.env.BACKEND_URL.replace(/\/api\/v1$/, '');

  
  useEffect(() => {
    console.log("======================== startVideoStream");
    
    if (globalState?.isStart) {
      startVideoStream(globalState.deviceId, globalState.optionValueDevice);
    }

    return () => {
      stopVideoStream();
    };
}, [globalState?.isStart, bool]);


  const mediaTypeSwitcher = () => {
    mediaTypeMap.forEach((mediaType, option) => {
      if (globalState.optionValueDevice === option) {
        setDeviceMediaType(mediaType);
      }
    });
  };

  const handleVideoClick = () => {
    setIsChecked(!isChecked);
    setGlobalState(prevState => ({
      ...prevState,
      isVideoFrozen: !prevState.isVideoFrozen,
    }));
  };

  useEffect(() => {
    if (isInferencing) {
      setIsVideoLoading(true); // Start loading
      const timeout = setTimeout(() => setIsVideoLoading(false), 1200); // Simulate loading time
      return () => clearTimeout(timeout); // Cleanup timeout
    } else {
      setIsVideoLoading(false);
    }
  }, [isInferencing]);
  const handleChange = e => {
    handleVideoClick();

    // toggleVideoFreeze();
  };




  const predictionHistory =
    globalState?.planes[globalState?.lastDetectedPlane]?.predictionHistory.slice(0, -1).slice(-4) ||
    [];

  // Calculate placeholders for skeleton
  const skeletonCount = Math.max(4 - predictionHistory.length, 0);
  const [divHeight, setDivHeight] = useState('60%');

  const calculateHeight = () => {
    // Example: Subtracting some pixels or using available height
    const availableHeight = window.innerHeight;
    const calculatedHeight = availableHeight; // 60% of the viewport height
    setDivHeight(`${calculatedHeight}px`);
  };

  useEffect(() => {
    setGlobalState(prevState => ({
      ...prevState,
      doctorName: user?.profile?.name,
    }));
    calculateHeight(); // Initial calculation
    window.addEventListener('resize', calculateHeight); // Update on resize

    return () => {
      window.removeEventListener('resize', calculateHeight); // Cleanup
    };
  }, []);

  const countPlanesWithFrame = planes => {
    let count = 0;

    for (const planeKey in planes) {
      if (planes[planeKey]?.frame) {
        count++;
      }
    }

    return count;
  };

  const planesWithFrameCount = countPlanesWithFrame(globalState.planes);
  const isButtonDisabled = !Object.keys(globalState?.planes).some(
    tag => globalState?.planes[tag]?.selectedViewData
  );
  const resetExam = ()=>{
    setGlobalState(INITIAL_GLOBAL_STATE);
    setBool(true);
    // setVideoStream(null);
    setVideoMaskStream(null);
    setCurrentStep(1);
  }


  return (
    <div className={`relative mx-auto flex h-full flex-col bg-[#fcfcfc]`}>

      {/* Hidden canvas */}
      <canvas
        ref={processingCanvasRef}
        style={{ display: 'none' }}
      />
      <div className="relative flex h-full w-full justify-between">
        <GenerateReportModal
          globalState={globalState}
          setGlobalState={setGlobalState}
          planes={planes}
        />
        <div
          className={`${globalState.deviceId === '' || globalState?.selectedItem === 'Select your machine' ? '' : 'relative'} flex h-full w-full flex-1 items-center justify-center bg-[#fcfcfc]`}
        >
          <div
            className={` flex h-full ${globalState.openPanel ? 'w-[100%]' : 'w-[94%]'} items-center justify-center bg-[#fcfcfc] text-black`}
          >
            <div className="relative flex h-full w-full items-center justify-center">
              {/* Video Modal and Stepper */}
              <VideoModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                videoStream={videoStream}
                viseaRtcStream={videoMaskStream}
                displayDimensions={DISPLAY_DIMENSIONS}
                handleVideoClick={handleVideoClick}
                isInferencing={isInferencing}
                globalState={globalState}
                handleChange={handleChange}
                isChecked={isChecked}
              />
              <StepperComponent
                mediaTypeSwitcher={mediaTypeSwitcher}
                globalState={globalState}
                setGlobalState={setGlobalState}
                api={API}
                token={user?.access_token}
                videoRef={videoRef}
                bool={bool}
                setBool={setBool}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
              <SelectView
                selectedPlan={selectedPlansModal}
                setSelectedPlansModal={setSelectedPlansModal}
                tag={selectedPlansTag}
                globalState={globalState}
                setGlobalState={setGlobalState}
              />
              <>
                <div className="flex h-full w-full flex-col space-y-2 p-2">
                  <div className="mt-2 flex w-full justify-between">
                    {/* <div className="inline-flex w-fit cursor-pointer items-center justify-center space-x-3 rounded-lg border  border-[#2886c7] px-6 py-1 text-sm font-bold text-[#2886c7] hover:opacity-70">
                              <span>Report</span>
                              <div className="">
                                <TbReportAnalytics size={20} />
                              </div>
                            </div> */}
                    {/* <div className="w-full text-xl font-bold">Real-time Report</div> */}
                    <div className="flex w-full items-center justify-start text-2xl font-semibold text-[#1F384C]">
                      Realtime Examination
                     
                      <span className={`ml-1 text-sm px-2 rounded-full ${
                        globalState.isExamEnded
                          ? 'bg-gray-100 text-gray-600 shadow-sm shadow-gray-200'
                          : !globalState.isVideoFrozen 
                            ? 'bg-green-200 text-[#22C55E] shadow-sm shadow-green-200 animate-pulse'
                            : 'bg-red-100 text-[#FF4747] shadow-sm shadow-red-200'
                      }`}>
                        {globalState.isExamEnded 
                          ? 'Ended'
                          : !globalState.isVideoFrozen 
                            ? 'Active' 
                            : 'Paused'
                        }
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-center space-x-2">
                      <div className="opacity-45 inline-flex w-fit cursor-not-allowed items-center justify-center space-x-5 rounded-md border  border-[#2886c7] px-6 py-1 font-semibold text-[#2886c7]">
                        <span>Share</span>
                        <FaShare size={18} />
                      </div>
                      <div
                        className={`${isButtonDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:opacity-70'} inline-flex w-fit items-center justify-center 
                      space-x-6 rounded-md border  border-[#2886c7] px-6 py-1 font-semibold text-[#2886c7] 
                      `}
                        onClick={() => {
                          if (isButtonDisabled == false) {
                            setGlobalState(prevState => ({
                              ...prevState,
                              openGR: !globalState.openGR,
                            }));
                          }
                        }}
                      >
                        <span>PDF Preview</span>
                        <FaPrint size={18} />
                      </div>
                    </div>
                    <div className="flex w-full items-center justify-end">
                        <div className="flex items-center">
                          {!globalState.isExamEnded && (
                            <button
                              className={`rounded-md border px-6 py-1 mr-2 font-semibold hover:opacity-50 ${
                                !globalState.isVideoFrozen
                                  ? 'border-[#FF4747] text-[#FF4747]' 
                                  : 'border-[#22C55E] text-[#22C55E]'
                              }`}
                              onClick={() => {
                                setIsChecked(!isChecked);
                                setGlobalState(prevState => ({
                                  ...prevState,
                                  isVideoFrozen: !prevState.isVideoFrozen,
                                  isStart: !prevState.isStart
                                }));
                              }}
                            >
                              {!globalState.isVideoFrozen ? 'Pause' : 'Resume'}
                            </button>
                          )}
                        </div>
                      {globalState?.isExamEnded == false ? (
                        <button
                          className="rounded-md border border-[#FF4747] px-6 py-1 font-semibold text-[#FF4747] hover:opacity-50"
                          onClick={() => {
                            setGlobalState(prevState => ({
                              ...prevState,
                              isExamEnded: true,
                              isVideoFrozen: true,
                            }));
                          }}
                        >
                          End Session
                        </button>
                      ) : (
                        <>
                          
                            <button
                            className="rounded-md border border-[#2886c7] px-6 py-1 font-semibold text-[#2886c7] hover:opacity-50"
                            onClick={() => {
                              resetExam()
                            }}
                          >
                            New Exam
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  {globalState?.isExamEnded == false && (
                    <div className="flex w-full space-x-4">
                      {/* First Card - Video and Instructions */}
                      <Card className="w-full">
                        <div className="flex h-full w-full space-x-5">
                          <div className="w-[65%]">
                            <VideoDisplay
                              isVideoLoading={isVideoLoading}
                              setModalOpen={setModalOpen}
                              globalState={globalState}
                              handleVideoClick={handleVideoClick}
                              videoRef={videoRef}
                              webrtcVideoRef={webrtcVideoRef}
                              isInferencing={isInferencing}
                              setGlobalState={setGlobalState}
                            />

                          </div>
                          <div className="mt-9 flex w-[35%] space-x-2">
                            <div className="flex items-center justify-start">
                              <RatingBar
                                align={'V'}
                                globalState={globalState}
                              />
                            </div>
                            <div className="flex w-full flex-col items-center justify-center space-y-2 text-sm font-medium text-[#686c82]">
                              <div className="inline-flex w-full space-x-2">
                                <PiInfo
                                  size={22}
                                  className="text-[#2886c7]"
                                />
                                <span>Ensure proper gel application for clear imaging</span>
                              </div>
                              <div className="inline-flex w-full space-x-2">
                                <PiInfo
                                  size={26}
                                  className="text-[#2886c7]"
                                />
                                <span>Ensure steady probe movement to capture accurate scans.</span>
                              </div>
                              <div className="inline-flex w-full space-x-2">
                                <PiInfo
                                  size={22}
                                  className="text-[#2886c7]"
                                />
                                <span>Adjust depth and focus for optimal image clarity.</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                      <LastDetectedPlanes
                        globalState={globalState}
                        lastDetectedPlane={globalState?.lastDetectedPlane}
                        predictionHistory={predictionHistory}
                        skeletonCount={skeletonCount}
                      />
                    </div>
                  )}

                  <div
                    className={`flex ${globalState?.isExamEnded ? 'h-[90%] xl:h-[92%]' : 'h-[60%] xl:h-[80%]'} w-full`}
                  >
                    <Card className="w-full">
                      <div className="flex h-full w-full flex-col space-y-2">
                        <div className="flex w-full items-center justify-between space-x-4 text-xl font-bold">
                          <MultiSelectDropdown
                            items={planes}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            globalState={globalState}
                            setGlobalState={setGlobalState}
                          />
                          <ProgressBar
                            approvedPlans={planesWithFrameCount}
                            totalPlans={22}
                          />
                        </div>
                        <div className="custom-scrollbar overflow-y-auto pr-1">
                          <div
                            className={`grid grid-cols-6 place-items-center content-center gap-2 xl:grid-cols-8 grid-rows-4 xl:grid-rows-6`}
                          >
                            {planes
                              .slice() // Create a copy of the array to avoid mutating the original `planes`
                              .sort((a, b) => {
                                const aHasHistory =
                                  !!globalState?.planes?.[a?.tag]?.predictionHistory;
                                const bHasHistory =
                                  !!globalState?.planes?.[b?.tag]?.predictionHistory;
                                // Items with `predictionHistory` come first
                                return bHasHistory - aHasHistory;
                              })
                              .map((plane, index) => (
                                <div
                                  className={`${
                                    globalState?.planes?.[plane?.tag]?.predictionHistory 
                                      ? 'cursor-pointer'
                                      : 'cursor-not-allowed'
                                  } relative flex h-full items-center justify-center place-self-center rounded-2xl border-2 border-[#a6abc8]`}
                                  key={index}
                                  onClick={() => {
                                    if (
                                      globalState?.planes?.[plane?.tag]?.predictionHistory 
                                    ) {
                                      setSelectedPlansModal(plane?.label);
                                      setSelectedPlansTag(plane?.tag);
                                    }
                                  }}
                                >
                                  <img
                                    src={plane?.imgPlane}
                                    className={`${
                                      plane.imgPlane === globalState?.planes?.[plane?.tag]?.imgReport
                                        ? 'h-full w-full rounded-2xl'
                                        : 'mt-4 h-2/5 w-2/5 py-4'
                                    }`}
                                    alt={plane?.label}
                                  />
                                  <span
                                    className={`absolute text-base top-2 left-2  ${
                                      plane.imgPlane === globalState?.planes?.[plane?.tag]?.imgReport
                                        ? 'text-white'
                                        : 'text-gray-700'
                                    }`}
                                  >
                                    {plane?.label}
                                  </span>
                                  {globalState?.planes?.[plane?.tag]?.predictionHistory && (
                                    <>
                                      <TfiLayers
                                        className={`absolute top-2 right-2 ${
                                          plane.imgPlane ===
                                          globalState?.planes?.[plane?.tag]?.imgReport
                                            ? 'text-white'
                                            : 'text-gray-700'
                                        }`}
                                        size={24}
                                      />
                                      <span
                                        className={`absolute top-2.5 right-5 text-xs font-bold ${
                                            plane.imgPlane ===
                                          globalState?.planes?.[plane?.tag]?.imgReport
                                            ? 'text-white'
                                            : 'text-gray-700'
                                        }`}
                                      >
                                        {
                                          globalState?.planes?.[plane?.tag]?.predictionHistory
                                            ?.length
                                        }
                                      </span>
                                    </>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>


              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealTimeInferencingUDP;
