import React, { useEffect, useRef, useState } from 'react';
import { ImShrink2 } from 'react-icons/im';
import AlertView from '../alert-view';
import { LuLoader2 } from 'react-icons/lu';

const VideoModal = ({
  isOpen,
  onClose,
  videoStream,
  viseaRtcStream,
  globalState,
  displayDimensions,
  handleVideoClick,
  isInferencing,
  isChecked,
  handleChange,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const modalVideoRef = useRef(null);
  const modalWebrtcVideoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  useEffect(() => {
    if (modalVideoRef.current && videoStream) {
      modalVideoRef.current.srcObject = videoStream; // Set the stream to modal's video element
    }
    if (modalWebrtcVideoRef.current && viseaRtcStream) {
      modalWebrtcVideoRef.current.srcObject = viseaRtcStream; // Set the stream to modal's video element
    }
  }, [viseaRtcStream, videoStream, isOpen]); // Re-run this effect if the videoStream changes
  useEffect(() => {
    if (isInferencing) {
      setIsLoading(true); // Start loading
      const timeout = setTimeout(() => setIsLoading(false), 1200); // Simulate loading time
      return () => clearTimeout(timeout); // Cleanup timeout
    } else {
      setIsLoading(false);
    }
  }, [isInferencing, open]);

  if (!isOpen) {
    return null;
  } // Don't render the modal if it's not open
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
      onClick={onClose}
    >
      <div
        className="relative max-h-[80vh] max-w-[90vw] rounded-lg bg-white shadow-xl"
        onClick={onClose}
      >
        <div
          className="relative flex justify-center items-center"
          onClick={e => e.stopPropagation()}
        >
          <button
            className={`${
              globalState?.deviceId ? 'visible' : 'invisible'
            } rounded-[50%]} absolute z-50 flex h-full w-full cursor-pointer items-center justify-center  text-white ${!globalState.isVideoFrozen ? '' : 'bg-black/30 backdrop-blur-sm'}`}
            onClick={() => {
              handleVideoClick();
            }}
          ></button>
          {isLoading && (
            <div className="absolute inset-0 z-50 flex items-center justify-center">
              <div className="inline-flex items-center space-x-2 text-blue-600">
                <LuLoader2
                  className="animate-spin"
                  size={18}
                />
              </div>
            </div>
          )}
          
          <video
            ref={modalVideoRef}
            autoPlay
            playsInline
            width={Math.min(displayDimensions.width, window.innerWidth * 0.5)} 
            height={Math.min(displayDimensions.height, window.innerHeight * 0.5)}
            onClick={handleVideoClick}
            style={{
              filter: globalState.isVideoFrozen ? 'blur(10px)' : 'none',
              cursor: 'pointer',
              display: !isInferencing && isLoading == false ? 'block' : 'none',
              objectFit: 'contain',
            }}
          />

          {/* WebRTC video element - shows when inferencing */}
          <video
            ref={modalWebrtcVideoRef}
            autoPlay
            playsInline
            width={Math.min(displayDimensions.width, window.innerWidth * 0.5)} 
            height={Math.min(displayDimensions.height, window.innerHeight * 0.5)}
            onClick={handleVideoClick}
            style={{
              cursor: 'pointer',
              display: isInferencing && isLoading == false  ? 'block' : 'none',
              objectFit: 'contain',
            }}
          />
          {isLoading == false &&
          <div
            className="absolute bottom-3 right-3 z-50 cursor-pointer text-white"
            onClick={onClose}
          >
            <ImShrink2 size={18} />
          </div>
          }
          
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
