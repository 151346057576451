import React from 'react'

interface MeasurementModeHeaderProps {
    drawingMode: boolean;
    predictMOde: boolean;
    changeDrawingMode: () => void;
}

function MeasurementModeHeader({ drawingMode, predictMOde,changeDrawingMode }: MeasurementModeHeaderProps) {
    return (
        <div className={`flex items-center flex-col px-2 py-1 text-primary-active  ${predictMOde == true ? "my-3 h-24" : "my-3"}`}>
            <span className="text-lg font-bold text-[#1F384C] tracking-widest">
                Measurements:
            </span>
            <span className="text-xs text-[#A6ABC8] font-bold mb-4 ">"{predictMOde === true ? "Manual Adjustment" : "Best Prediction"}"</span>
            {predictMOde == true && <div className='flex items-center justify-between w-full  bg-blue-100 rounded-2xl h-10 text-xs p-1 space-x-3'>

                <span className={`${drawingMode === false ? "text-gray-600" : "text-white bg-blue-500 rounded-3xl"} h-full w-1/2 flex items-center justify-center cursor-pointer`} onClick={changeDrawingMode} >DeepEcho mode</span>
                <span className={`${drawingMode === true ? "text-gray-600" : "text-white bg-blue-500 rounded-3xl"} h-full w-1/2 flex items-center justify-center cursor-pointer`} onClick={changeDrawingMode}>Ohif mode</span>
            </div>}
        </div>
    )
}

export default MeasurementModeHeader
