import { ServicesManager } from '@ohif/core';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import MeasurementModeHeader from '../MeasurementModeHeader/MeasurementModeHeader';
import { CiFileOn } from 'react-icons/ci';

interface MeasurementOhifModeProps {
    data: any;
    measurementService: any;
    activeViewportIndex: number;
    onImageScrollbarChange: any;
    onClick: any;
    unlabeledMeasurements: any;
    drawingMode: boolean;
    mode?: boolean;
    changeDrawingMode: () => void;
}

function MeasurementOhifMode({ data, measurementService, activeViewportIndex, onImageScrollbarChange, onClick, unlabeledMeasurements, drawingMode, mode, changeDrawingMode }: MeasurementOhifModeProps) {
    const [selectedMeasurement, setSelectedMeasurement] = useState(null);
    const [selectedMeasurementIndex, setSelectedMeasurementIndex] = useState(null);

    useEffect(() => {
        const selectedData = data.find((data) => data?.label !== undefined && data?.isActive === true && !data?.label.includes('plane'));
        if (selectedData === undefined) return;

        const selectedUnlabeledMeasurement = unlabeledMeasurements.find((measurement) => measurement.uid === selectedData.uid);
        if (selectedUnlabeledMeasurement === undefined) return;

        setSelectedMeasurement(selectedUnlabeledMeasurement);
        setSelectedMeasurementIndex(unlabeledMeasurements.indexOf(selectedUnlabeledMeasurement));
    }, [data, unlabeledMeasurements]);

    function HandleClick(index: number) {
        const selectedMeasurement = unlabeledMeasurements[index];
        const imageIndex = selectedMeasurement?.frameNumber;
        measurementService.jumpToMeasurement(activeViewportIndex, selectedMeasurement?.uid);
        // onImageScrollbarChange(imageIndex, activeViewportIndex);
        // onClick({
        //     uid: selectedMeasurement?.uid,
        //     isActive: false,
        // });
        setSelectedMeasurement(selectedMeasurement);
        setSelectedMeasurementIndex(index);
    }

    return (
        <>
            <div className="mb-1 mt-4 flex flex-wrap justify-center gap-1 w-full">
                {unlabeledMeasurements.length === 0 ? (
                    <div className="text-center text-gray-500 flex flex-col items-center justify-center w-full mt-12">
                        <CiFileOn size={100} className='bg-blue-200 rounded-full p-2 text-white mb-2' />
                        <span className='text-[#1F384C] text-sm font-semibold text-center'>
                            No unlabeled measurement detected.
                        </span>
                        <span className='text-gray-400 text-xs'>
                            Please Draw a measurement on the image to start the measurement process.
                        </span>
                    </div>
                ) : (
                    unlabeledMeasurements.map((measurement, index) => (
                        <div
                            key={index}
                            className={classnames(
                                'group flex cursor-pointer transition duration-300 rounded',
                                {
                                    'overflow-hidden border-primary-light': selectedMeasurementIndex === index,
                                }
                            )}
                            role="button"
                        >
                            <div
                                className={classnames(
                                    'text-center w-6 py-1 text-base transition duration-300 rounded',
                                    {
                                        'bg-blue-600 hover:bg-blue-500 text-white': selectedMeasurementIndex === index,
                                        'bg-white text-blue-600 hover:bg-blue-100 border border-blue-300': selectedMeasurementIndex !== index,
                                    }
                                )}
                                onClick={() => HandleClick(index)}
                            >
                                {index + 1}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
}

export default MeasurementOhifMode;
