import React, { useEffect, useState } from 'react';
const { differenceInDays, addMonths } = require('date-fns');
const moment = require('moment');
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

// editMode={editMode}
//                   examData={examData}
//                   study={study}
//                   handleDOBChange={handleDOBChange}
//                   setExamData={setExamData}
//                   handleAgeChange={handleAgeChange}
//                   handleLMPChange={handleLMPChange}
//                   handleGAChange={handleGAChange}
//                   handleExamChange={handleExamChange}
//                   handleMSChange={handleMSChange}
//                   handleRHPChange={handleRHPChange}
interface GeneralInfoProps {
  editMode: boolean;
  examData: any;
  study: any;
  handleDOBChange: any;
  setExamData: any;
  handleAgeChange: any;
  handleLMPChange: any;
  handleGAChange: any;
  handleExamChange: any;
  handleMSChange: any;
  handleRHPChange: any;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  editMode,
  examData,
  study,
  handleDOBChange,
  setExamData,
  handleAgeChange,
  handleLMPChange,
  handleGAChange,
  handleExamChange,
  handleMSChange,
  handleRHPChange,
}) => {
  function calculateGestationalAge(lmpDate) {

    try {
      const lmp = new Date(lmpDate);
      let gestationalAge;
      gestationalAge = differenceInDays(Date.now(), lmp);
      // return format(edd, 'MMMM dd, yyyy');
      if (gestationalAge < 0) {
        return 'Error';
      } else {
        let rest = gestationalAge % 7;
        gestationalAge = gestationalAge / 7;
        return `${Math.floor(gestationalAge)}w${rest}d`;
      }
    } catch (error) {
      return 'Error';
    }
  }

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  // Function to calculate age
  function calculateAge(dateString) {
    const birthDate = new Date(dateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const timestamp = Date.now();
  const currentDate = new Date(timestamp);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate
    .getDate()
    .toString()
    .padStart(2, '0');
  const formattedDate = `${year}/${month}/${day}`;

  const [LMP, SetLMP] = useState(formattedDate);
  const [GA, SetGA] = useState('23');

  useEffect(() => {
    const edd = calculateGestationalAge(LMP);

    SetGA(edd);
  }, [LMP]);

  return (
    <div
      style={{ height: '25%' }}
      className="grid grid-cols-8 grid-rows-6 px-2  border-black border-b-2 text-sm"
    >
      <div className=" col-span-8 row-span-1">
        <h2 className="font-bold text-lg underline">General Information</h2>
      </div>

      <div className=" col-span-4 row-span-1 flex-initial justify-between">
        <span className="font-bold">DOB: </span>
        <span>{formatDate(study?.patient_birth_date)}</span>
      </div>
      <div className=" col-span-4 row-span-1">
        <span className="font-bold">Age: </span>
        <span>{calculateAge(formatDate(study?.patient_birth_date))}</span>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">LMP:</span>

        <>
          <DatePicker
            size="small"
            defaultValue={dayjs(formattedDate, dateFormat)}
            format={dateFormat}
            onChange={e => {
              SetLMP(e.$d);
            }}
          />
        </>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">GA from LMP:</span>

        <span>{GA}</span>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">Exam:</span>

        <span>Secont Trimester</span>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">Medical status:</span>

        <span>Followed Patient</span>
      </div>
      <div className=" col-span-8 row-span-1  ">
        <span className="font-bold">Reference hospital or Practioner</span>{' '}
        <span>MFM Clinic</span>
      </div>
    </div>
  );
};

export default GeneralInfo;
