export const examsQueryKeys = {
    all: ['exams'],
    details: () => [...examsQueryKeys.all, "detail"],
    detail: (id) => [...examsQueryKeys.details(), id],
    pagination: (page, filters = {}) => {
        // Create a consistent, serializable representation of filters
        const filterKey = Object.entries(filters)
            .filter(([, value]) => value !== undefined && value !== '')
            .map(([key, value]) => `${key}:${value}`)
            .sort()
            .join('|');

        return [...examsQueryKeys.all, "pagination", page.toString(), filterKey];
    },
    infinite: () => [...examsQueryKeys.all, "infinite"],
}