import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Home from './UI/Pages/HomePage/home.page';
import Account from './UI/Pages/AccountPage/account.page';
import Report from './UI/Pages/ReportPage/report.page';
import Analytics from './UI/Pages/AnalyticsPage/analytics.page';
import Reports from './UI/Pages/ReportsPage/reports.page';
import Settings from './UI/Pages/SettingsPage/settings.page';
import Support from './UI/Pages/SupportPage/support.page';
import UserManagement from './UI/Pages/UserManagement/user_management.page';
import RealTimeInferencing from './UI/Pages/RealtimeInferencingPage/realtime-inferencing.page';
import ProfilePage from './UI/Pages/AccountPage/account.page';
import NewRealtimePage from './UI/Pages/NewRealtimePage';
import PatientManagement from './UI/Pages/PatientManagement/patient_management.page';
import ExamManagement from './UI/Pages/ExamManagement/exam_management.page';
import RealTimeInferencingUDP from './UI/Pages/RealtimeInferencingPageUDP/realtime-inferencing.page.udp';
import RealTimeInferencingV3 from './UI/Pages/RealtimeInferencingPageV3/realtime-inferencing_v3.page';
import PatientProfile from './UI/Pages/PatientManagement/patient_profile';
import PixelTest from './UI/Pages/PixelTest/pixel.page';
import Training from './UI/Pages/Training/training';
import SamplesData from './UI/Pages/SampleDataPage/sample-datasets';

// export const ProtectedPages = [
//   { path: '/home', element: <Home /> },
//   { path: '/profile/:uid', element: <ProfilePage /> },
//   { path: '/report', element: <Report /> },
//   { path: '/analytics', element: <Analytics /> },
//   { path: '/realtime', element: <NewRealtimePage /> },
//   { path: '/realtime-v2', element: <RealTimeInferencingUDP /> },
//   { path: '/realtime-inferencing', element: <RealTimeInferencing /> },
//   { path: '/exams', element: <ExamManagement />},
//   { path: '/patients', element: <PatientManagement /> },
//   { path: '/patients/:id', element: <PatientProfile /> },
//   { path: '/reports', element: <Reports /> },
//   { path: '/settings', element: <Settings /> },
//   { path: '/support', element: <Support /> },
//   { path: '/users', element: <UserManagement /> },
//   { path: '*', component: <Navigate to="/" /> },
// ];
export const ProtectedPages = [

  { path: '/home', element: <Home />, allowedRoles: ['super_admin'] },
  { path: '/profile/:uid', element: <ProfilePage />, allowedRoles: ['super_admin']},
  { path: '/report', element: <Report />, allowedRoles: ['super_admin'] },
  { path: '/analytics', element: <Analytics />, allowedRoles: ['super_admin'] },
  { path: '/realtime', element: <NewRealtimePage />, allowedRoles: ['super_admin'] },
  { path: '/realtime-v2', element: <RealTimeInferencingUDP />, allowedRoles: ['super_admin'] },
  { path: '/realtime-v3', element: <RealTimeInferencingV3 />, allowedRoles: ['super_admin'] },
  { path: '/pixel', element: <PixelTest />, allowedRoles: ['super_admin'] },
  { path: '/training', element: <Training />, allowedRoles: ['super_admin'] },
  { path: '/sample-datasets', element: <SamplesData />, allowedRoles: ['super_admin'] },
  { path: '/realtime-inferencing', element: <RealTimeInferencing />, allowedRoles: ['super_adminin'] },
  { path: '/exams', element: <ExamManagement />, allowedRoles: ['super_admin'] },
  { path: '/patients', element: <PatientManagement />, allowedRoles: ['super_admin'] },
  { path: '/patients/:id', element: <PatientProfile />, allowedRoles: ['super_admin'] },
  { path: '/reports', element: <Reports />, allowedRoles: ['super_admin'] },
  //{ path: '/settings', element: <Settings />, allowedRoles: ['super_admin'] },
  { path: '/support', element: <Support />, allowedRoles: ['super_admin'] },
  { path: '/users', element: <UserManagement />, allowedRoles: ['super_admin',"user_manager"] }, // Only admins can access
  { path: '*', component: <Navigate to="/" /> }, // Default fallback
];
const Pages = () => {
  return (
    <Routes>
      <Route
        exact={true}
        path="/home"
        element={<Home />}
      />
      <Route
        exact={true}
        path="/account"
        element={<Account />}
      />
      <Route
        exact={true}
        path="/report"
        element={<Report />}
      />
      <Route
        exact={true}
        path="/analytics"
        element={<Analytics />}
      />
      <Route
        exact={true}
        path="/exams"
        element={<ExamManagement />}
      />


      <Route
        exact={true}
        path="/reports"
        element={<Reports />}
      />
      {/* <Route
        exact={true}
        path="/settings"
        element={<Settings />}
      /> */}
      <Route
        exact={true}
        path="/support"
        element={<Support />}
      />
      <Route
        exact={true}
        path="/account"
        element={<Account />}
      />
    </Routes>
  );
};

export default Pages;
