import { GlobalReportSearchParams } from '../../../UI/interfaces';
import { QueryKeyT, useFetch_old, usePut_old } from '../react-query';
import * as url from '../../urlHelper';

export const useGetRecentReports = () => useFetch_old(`${url.GET_ALL_REPORTS}`);
// export const useGetStateReport = (StudyUID: string) => useFetch(`${url.GET_STATE_REPORT}/${StudyUID}`)
// export const useGetStateReport = (StudyUID: string, queryKey?: QueryKeyT) =>
//     useFetch(`${url.GET_STATE_REPORT}/${StudyUID}`, undefined, undefined, queryKey);

export const useUpdateStateReport = (
  queryKey?: QueryKeyT,
  updater?: (oldData: any[], newData: any) => any[]
) => usePut_old(`${url.UPDATE_STATE_REPORT}`, undefined, updater, queryKey);

export const useGetGlobalReportByStudyId = (StudyUID: string, queryKey?: QueryKeyT) =>
  useFetch_old(`${url.GET_REPORT}/${StudyUID}`, undefined, { enabled: !!StudyUID }, queryKey);



export const useGetGlobalReport = (StudyUID: string, queryKey?: QueryKeyT) =>
  useFetch_old(`${url.GET_STATE_REPORT}/${StudyUID}`, undefined, { enabled: !!StudyUID }, queryKey);
