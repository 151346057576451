import { FaCameraRetro } from 'react-icons/fa6';
import { api } from '../../../../../../Deepecho/helpers/backendHelper';
import React, { useEffect, useState } from 'react';
import { useVideoProcessing } from '../../../RealtimeInferencingPageUDP/hooks/useVideoProcessing';
import CustomDropdown from './cusom-dropdown';
import { FaRegFrown } from 'react-icons/fa';
import { TbCaptureOff, TbVideoOff } from 'react-icons/tb';

interface CropBox {
  left: number;
  upper: number;
  right: number;
  lower: number;
}

interface CropRegion {
  width: number;
  height: number;
  x: number;
  y: number;
}

const convertBackendCropToFrontend = (cropBox: [number, number, number, number]): CropRegion => {
  const [left, upper, right, lower] = cropBox;
  return {
    width: right - left,
    height: lower - upper,
    x: left,
    y: upper,
  };
};

let CROP_REGION = convertBackendCropToFrontend([420, 120, 1430, 840]);

const MACHINE_TYPES = [
  'voluson_p8',
  'voluson_s10',
  'voluson_s8',
  'voluson_e6',
  'voluson_e10',
  'versana',
  'affiniti70',
  'mindray_dc_40',
];

const DASH_POSITIONS = ['left', 'bottom'];
const DEPTH_INFO_POSITIONS = ['right', 'left'];
function PixelComponent({setGlobalState}) {
  console.log('============= PixelTest', CROP_REGION);

  const [detectionResults, setDetectionResults] = useState<{
    pixel_spacing: number;
    zoom_and_depth: {
        zoom: number;
        depth: number;
    };
  } | null>(null);

  const [isDetecting, setIsDetecting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [machineType, setMachineType] = useState<string>(MACHINE_TYPES[0]);
  const [dashPosition, setDashPosition] = useState<string>(DASH_POSITIONS[0]);
  const [depthInfoPosition, setDepthInfoPosition] = useState<string>(DEPTH_INFO_POSITIONS[0]);

  const [sourceType, setSourceType] = useState<'USB' | 'Share'>('USB');
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string>('');
  const [isScreenSharing, setIsScreenSharing] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [originalDimensions, setOriginalDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const croppedVideoRef = React.useRef<HTMLVideoElement>(null);

  const { getVideoStream } = useVideoProcessing();

  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const captureFrameFromCroppedVideo = () => {
    if (croppedVideoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = CROP_REGION.width;
      canvas.height = CROP_REGION.height;

      const ctx = canvas.getContext('2d', {
        alpha: false, // Disable alpha channel
        willReadFrequently: true, // Optimize for pixel manipulation
      });

      if (ctx) {
        // Set image smoothing quality to high
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(croppedVideoRef.current, 0, 0);

        // Use higher quality JPEG instead of PNG
        const imageUrl = canvas.toDataURL('image/jpeg', 1.0); // 1.0 is maximum quality
        setCapturedImage(imageUrl);
      }
    }
  };

  const captureFrame = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      // Use full video dimensions
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      const ctx = canvas.getContext('2d', {
        alpha: false,
        willReadFrequently: true,
      });

      if (ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        // Draw full video frame
        ctx.drawImage(
          videoRef.current,
          0,
          0,
          videoRef.current.videoWidth,
          videoRef.current.videoHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );

        // Add dimension validation here
        if (canvas.width < 500 || canvas.height < 500) {
          console.warn('Captured image dimensions might be too small for accurate processing');
          // Optionally, you could also:
          // - Show a warning to the user
          // - Prevent the capture
          // - Add a notification
          return; // Optional: prevent small image capture
        }

        const imageUrl = canvas.toDataURL('image/jpeg', 1.0);
        setCapturedImage(imageUrl);
      }
    }
  };

  const detectPixelSpacing = async () => {
    if (!capturedImage) return;

    try {
      setIsDetecting(true);
      setError(null);
      setDetectionResults(null);

      const response = await fetch(capturedImage);
      const blob = await response.blob();

      const formData = new FormData();
      formData.append('image', blob, `capture_${Math.random().toString(36).substring(2, 15)}.jpg`);
      formData.append('machine_type', machineType);
      formData.append('machine_dash_position', dashPosition);
      formData.append('machine_depthinfo_position', depthInfoPosition);
      formData.append('crop', 'true');

      const result = await api.post('/pixel_spacing_detection', formData);
      console.log('============= result', result);

      if (result && typeof result.pixel_spacing === 'number') {
        setDetectionResults(result);
        setGlobalState(prevState => ({
            ...prevState,
            pixelSpacingConfig: result,
          }));
        setError(null);
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error detecting pixel spacing:', error);
      setDetectionResults(null);
      setError(error instanceof Error ? error.message : 'Failed to detect pixel spacing');
    } finally {
      setIsDetecting(false);
    }
  };

  // Get available video devices
  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
      })
      .catch(err => console.error('Error accessing media devices:', err));
  }, []);

  const handleDeviceChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const deviceId = event.target.value;
    setSelectedDevice(deviceId);
    setIsScreenSharing(false);

    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }

    if (deviceId) {
      try {
        const newStream = await getVideoStream(deviceId, sourceType);
        setStream(newStream);
        updateVideoStreams(newStream);
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    }
  };

  const handleStopStream = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
      setIsScreenSharing(false);
      setCapturedImage(null);
      setDetectionResults(null);
      setSourceType('USB');
      setSelectedDevice('');
      setMachineType(MACHINE_TYPES[0])
      setDashPosition(DASH_POSITIONS[0])
      setDepthInfoPosition(DEPTH_INFO_POSITIONS[0])
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      if (croppedVideoRef.current) {
        croppedVideoRef.current.srcObject = null;
      }
    }
  };

  const handleSourceTypeChange = async () => {
    
    console.log("sourceType",sourceType)
    // Stop existing stream
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }

    // If switching to screen share, initiate it
    if (sourceType === 'Share') {
      try {
        const newStream = await getVideoStream('', 'Share');
        setStream(newStream);
        setIsScreenSharing(true);
        updateVideoStreams(newStream);
      } catch (err) {
        console.error('Error sharing screen:', err);
        setIsScreenSharing(false);
      }
    }
  };

  useEffect(()=>{
    handleSourceTypeChange();
  },[sourceType])
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  const updateVideoStreams = (newStream: MediaStream) => {
    if (videoRef.current) {
      videoRef.current.srcObject = newStream;

      videoRef.current.onloadedmetadata = () => {
        const sourceWidth = videoRef.current!.videoWidth;
        const sourceHeight = videoRef.current!.videoHeight;
        console.log('Original Video Dimensions:', { width: sourceWidth, height: sourceHeight });
        setOriginalDimensions({ width: sourceWidth, height: sourceHeight });

        // Calculate center crop region
        CROP_REGION = {
          width: CROP_REGION.width,
          height: CROP_REGION.height,
          x: (sourceWidth - CROP_REGION.width) / 2, // Center horizontally
          y: (sourceHeight - CROP_REGION.height) / 2, // Center vertically
        };
      };
    }

    // Create cropped stream
    if (croppedVideoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = CROP_REGION.width;
      canvas.height = CROP_REGION.height;

      const ctx = canvas.getContext('2d');
      if (ctx && videoRef.current) {
        videoRef.current.onplay = () => {
          const drawFrame = () => {
            ctx.drawImage(
              videoRef.current!,
              CROP_REGION.x,
              CROP_REGION.y, // Source x,y
              CROP_REGION.width,
              CROP_REGION.height, // Source width,height
              0,
              0, // Destination x,y
              CROP_REGION.width,
              CROP_REGION.height // Destination width,height
            );
            requestAnimationFrame(drawFrame);
          };
          requestAnimationFrame(drawFrame);
        };
      }

      const croppedStream = canvas.captureStream();
      croppedVideoRef.current.srcObject = croppedStream;
    }
  };
  return (
    <div className="h-full p-4 text-gray-900">
      <div className="mb-4 flex flex-wrap items-center gap-4">
        <CustomDropdown
          options={['USB', 'Share']}
          value={sourceType}
          onChange={value =>{
            setSourceType(value as 'USB' | 'Share');
          } }
          label="Source Type"
          className='w-fit'
        />

        <CustomDropdown
          options={devices.map(device => device.label || `Camera ${device.deviceId}`)}
          value={selectedDevice}
          onChange={value => {
            const device = devices.find(d => d.label === value);
            if (device) handleDeviceChange({ target: { value: device.deviceId } } as any);
          }}
          label="Select Camera"
          disabled={sourceType === 'Share'}
          className='w-fit'
        />

        {stream && (
          <button
            onClick={handleStopStream}
            className="rounded-lg bg-red-500 px-4 py-2 text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
          >
            Stop Stream
          </button>
        )}
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="relative rounded-lg bg-white p-4 shadow">
          <h3 className="mb-2 text-lg font-medium">
            Original Stream{' '}
            {originalDimensions && `(${originalDimensions.width}x${originalDimensions.height})`}
          </h3>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className="w-full rounded-lg shadow"
          />
          {!stream &&
                <div
          className="absolute top-0 -left-4 flex text-gray-500 flex-col h-full w-full items-center justify-center space-x-2"
          
        >
          <TbVideoOff size={54} />
          <span>No stream available</span>
        </div>
            }
        </div>

        <div className="relative rounded-lg bg-white p-4 shadow">
          <h3 className="mb-2 text-lg font-medium">Captured Frame</h3>
          {capturedImage ? (
            <img
              src={capturedImage}
              alt="Captured frame"
              className="mb-4 w-full rounded-lg"
            />
          ) : (
            <div  className="absolute top-0 -left-4 flex text-gray-500 flex-col h-full w-full items-center justify-center space-x-2">
               <TbCaptureOff size={54} />
              <p className="mt-2">No frame captured yet.</p>
            </div>
          )}

          {isDetecting ? (
            <div className="text-center">
              <p className="mb-2">Processing image...</p>
              <div className="mx-auto h-6 w-6 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
            </div>
          ) : error ? (
            <div className="rounded-lg bg-red-100 p-4 text-red-600">
              <h3 className="mb-2 font-semibold">Error:</h3>
              <p>{error}</p>
            </div>
          ) : (
            detectionResults && (
              <div>
                <h4 className="mb-2 font-medium">Detection Results:</h4>
                <div className="space-y-2 text-sm text-gray-700">
                  <div>
                    <strong>Pixel Spacing:</strong> {detectionResults.pixel_spacing.toFixed(4)}
                  </div>
                  <div>
                    <strong>Zoom:</strong> {detectionResults?.zoom_and_depth?.zoom || 'N/A'}
                  </div>
                  <div>
                    <strong>Depth:</strong> {detectionResults?.zoom_and_depth?.depth || 'N/A'}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className="mt-4 inline-flex w-full">
        <div className='flex flex-wrap items-center gap-4 w-1/2'>
        <CustomDropdown
          options={MACHINE_TYPES}
          value={machineType}
          onChange={value => setMachineType(value)}
          label="Machine Type"
          className='w-fit'
        />

        <CustomDropdown
          options={DASH_POSITIONS}
          value={dashPosition}
          onChange={value => setDashPosition(value)}
          label="Dash Position"
          className='w-fit'
        />

        <CustomDropdown
          options={DEPTH_INFO_POSITIONS}
          value={depthInfoPosition}
          onChange={value => setDepthInfoPosition(value)}
          label="Depth/Zoom Info Position"
          className='w-fit'
        />
        </div>
   
        <div className="flex flex-wrap gap-4 w-1/2 justify-end">
        <button
          onClick={captureFrame}
          disabled={!stream}
          className={`rounded-lg px-4 py-2 shadow-sm ${
            stream
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'cursor-not-allowed bg-gray-300 text-gray-500'
          }`}
        >
          Capture Frame
        </button>

        <button
          onClick={detectPixelSpacing}
          disabled={!capturedImage || isDetecting}
          className={`flex items-center gap-2 rounded-lg px-4 py-2 ${
            capturedImage && !isDetecting
              ? 'bg-green-500 text-white hover:bg-green-600'
              : 'cursor-not-allowed bg-gray-300 text-gray-500'
          }`}
        >
          {isDetecting ? (
            <>
              <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
              Detecting...
            </>
          ) : (
            'Detect Pixel Spacing'
          )}
        </button>
      </div>
      </div>

      
    </div>
  );
}
export default PixelComponent;
