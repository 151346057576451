// import React, { useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useAuth } from 'react-oidc-context';

// const ProtectedRoutes = ({ children }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const auth = useAuth();

//   useEffect(() => {
//     if (!auth.isLoading && !auth.isAuthenticated) {
//       // If not authenticated, redirect to login
//       auth.signinRedirect({ redirect_uri: window.location.href });
//     } else if (auth.isAuthenticated && process.env.DEPLOYMENT_TARGET === 'Morocco') {
//       navigate('/realtime-inferencing');
//     }
//   }, [auth.isLoading, auth.isAuthenticated, navigate]);

//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (!document.hidden && !auth.isAuthenticated) {
//         // If the tab becomes visible and the user is not authenticated, redirect to login
//         auth.signinRedirect({ redirect_uri: window.location.href });
//       }
//     };

//     document.addEventListener('visibilitychange', handleVisibilityChange);

//     return () => {
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
//   }, [auth]);

//   if (auth.isLoading) {
//     return (
//       <div className="fixed inset-0 flex items-center justify-center bg-white">
//         <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-blue-500"></div>
//       </div>
//     );
//   }

//   if (!auth.isAuthenticated) {
//     return null; // Don't render anything while redirecting
//   }

//   return children;
// };

// export default ProtectedRoutes;
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { getProfile } from './helpers/api/users/users';

const ProtectedRoutes = ({ children, allowedRoles = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const { data, isLoading: isProfileLoading } = getProfile();

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      // Redirect to login, preserving the current path
      const redirectUri = `${window.location.origin}${location.pathname}${location.search}`;
      auth.signinRedirect({ redirect_uri: redirectUri });
    }
  }, [auth.isLoading, auth.isAuthenticated, location]);

  useEffect(() => {
    // Handle role-based redirection once profile data is loaded
    if (auth.isAuthenticated && !isProfileLoading) {
      const userRole = data?.data?.roles?.[0];
  
      // Only perform redirection if the user is not already on the correct path
      if (userRole === 'user_manager') {
        // Ensure we don't navigate to /users if we are already there
        if (location.pathname !== '/users') {
          navigate('/users');
        }
      } else if (allowedRoles.length && !allowedRoles.includes(userRole) && !isProfileLoading) {
        // Redirect to home if the role is not allowed
        if (location.pathname !== '/') {
          navigate('/');
        }
      }
    }
  }, [
    auth.isAuthenticated,
    isProfileLoading,
    data,
    allowedRoles,
    location.pathname,
    navigate,
  ]);



  // useEffect(() => {
  //   if (!auth.isLoading && !auth.isAuthenticated) {
  //     // If not authenticated, redirect to login
  //     auth.signinRedirect({ redirect_uri: window.location.href });
  //   } else if (auth.isAuthenticated && process.env.DEPLOYMENT_TARGET === 'Morocco') {
  //     navigate('/realtime-inferencing');
  //   }
  // }, [auth.isLoading, auth.isAuthenticated, navigate]);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden && !auth.isAuthenticated) {
  //       // If the tab becomes visible and the user is not authenticated, redirect to login
  //       auth.signinRedirect({ redirect_uri: window.location.href });
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, [auth]);
  

  if (auth.isLoading || isProfileLoading) {
    // Render a loading screen to block unauthorized access during data fetch
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    return null; // Block rendering if the user is not authenticated
  }

  return children; // Render protected content after authentication and loading are complete
};

export default ProtectedRoutes;
