import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon, Thumbnail, ThumbnailNoImage, Tooltip } from '../';
import { StringNumber } from '../../types';
import { MdFilterListOff } from 'react-icons/md';
import axios from 'axios';
import { Button } from 'antd';
import { SiCodemagic } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import {
  generateSR,
  updatePredictionsState,
} from '../../../../../platform/viewer/src/Deepecho/store/actions';
import LoaderPage from '../../../../../platform/viewer/src/Deepecho/UI/Components/Loader/loader.page';
import { api } from 'platform/core/src/__mocks__/dicomweb-client';
import { api as api2 } from '../../../../viewer/src/Deepecho/helpers/backendHelper';
import { usePredict, useSRStatus } from '../../../../viewer/src/Deepecho/helpers/api/studies/studies';


const ThumbnailTracked = ({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  seriesNumber,
  serieInstanceUID,
  activeDisplaySetInstanceUIDs,
  sOPInstanceUID,
  _getModalityTooltip,
  seriesDate,
  studyInstanceUID,
  canReject,
  setUpdateOnSr,
  onReject,
  onThumbnailClick,
  onThumbnailDoubleClick,
  numInstances,
  modality,
  countIcon,
  dragData,
  onClick,
  onDoubleClick,
  onClickUntrack,
  viewportIdentificator,
  isTracked,
  isActive,
  thumbnailsNoImage,
  mesurementService,
  displaySetService,
  uiNotificationService,
}) => {


  const trackedIcon = isTracked ? 'circled-checkmark' : 'dotted-circle';
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [filterThumbnails, setFilterThumbnails] = useState<any[]>([]);
  const url = process.env.BACKEND_URL;
  const viewportIdentificatorLabel = viewportIdentificator.join(', ');
  const [currentSeriePredictionState, setCurrentSeriePredictionState] = useState({});
  const SRs = useSelector(state => state.SR.SRs);
  const dispatch = useDispatch();
  const predict_payload = {
    studyUID: studyInstanceUID, // temporary added to match the backend
    study_uid: studyInstanceUID,
    series_instance_uid: serieInstanceUID,
    sop_instance_uid: sOPInstanceUID,
  };


  const { mutate: predict, isLoading: isGenerating } = usePredict();

  
  const { data: srStatus } = useSRStatus(
    predict_payload.series_instance_uid,
    predict_payload.sop_instance_uid
  );
  const predictionState = srStatus?.status || '';

  const renderViewportLabels = () => {
    const MAX_LABELS_PER_COL = 3;
    const shouldShowStack = viewportIdentificator.length > MAX_LABELS_PER_COL;
    if (shouldShowStack) {
      return (
        <div>
          <div>
            {viewportIdentificator.slice(0, MAX_LABELS_PER_COL).map(label => (
              <div key={label}>{label}</div>
            ))}
          </div>
          <Tooltip
            position="right"
            content={
              <div className="max-w-40 text-left">
                Series is displayed <br /> in viewport {viewportIdentificatorLabel}
              </div>
            }
          >
            <Icon
              name="tool-more-menu"
              className="py-2 text-black"
            />
          </Tooltip>
        </div>
      );
    }

    return viewportIdentificator.map(label => <div key={label}>{label}</div>);
  };
  useEffect(() => {
    setCurrentSeriePredictionState({
      [`${predict_payload?.SeriesInstanceUID}${predict_payload?.SOPInstanceUID}`]: 'STATE_FETCHING',
    });
    console.log('serieInstanceUID :a', serieInstanceUID);
    if (serieInstanceUID && sOPInstanceUID) {
      // this should not be like this migrate to tanstack !important
      const fetchSerie = async () => {
        try {
          const response = await api2.get(
            `${url}/serie/${studyInstanceUID}/${serieInstanceUID}/${sOPInstanceUID}`
          );
          console.log('just for test : ', response);
          setCurrentSeriePredictionState({
            [`${predict_payload?.series_instance_uid}${predict_payload?.sop_instance_uid}`]:
              response?.prediction_state,
          });
        } catch (error) {
          console.log(error);
          setCurrentSeriePredictionState({
            [`${predict_payload?.series_instance_uid}${predict_payload?.sop_instance_uid}`]: '',
          });
        }
      };

      fetchSerie();
    }
  }, [studyInstanceUID]);
  useEffect(() => {
    const filtered = thumbnailsNoImage.filter(
      item =>
        item.SeriesInstanceUID === serieInstanceUID &&
        item.modality === 'SR' &&
        item.SOPInstanceUID === sOPInstanceUID
    );
    setFilterThumbnails(prev => {
      return [...filtered];
    });
  }, [thumbnailsNoImage]);
  useEffect(() => {
    const key = `${predict_payload.series_instance_uid}${predict_payload.sop_instance_uid}`;
    if (SRs[key]) {
      setCurrentSeriePredictionState({
        ...currentSeriePredictionState,
        [key]: SRs[key],
      });
      setUpdateOnSr({
        ...currentSeriePredictionState,
        [key]: SRs[key],
      });
    }
  }, [SRs]);
  const generateSRHandler = () => {
    console.log("============== generateSRHandler ============== ");
    
    dispatch(updatePredictionsState({ ...predict_payload, PredtionState: 'INPROGRESS' }));
    setCurrentSeriePredictionState({
      [`${predict_payload?.series_instance_uid}${predict_payload?.sop_instance_uid}`]: 'INPROGRESS',
    });
    try {
      const data = dispatch(generateSR(predict_payload));
    } catch (error) {
      setCurrentSeriePredictionState({
        [`${predict_payload?.series_instance_uid}${predict_payload?.sop_instance_uid}`]: '',
      });
      // setPredictLoadingSR(false);
    }
  };

  const predictSeries = () => {
    predict(predict_payload, {
      onSuccess: () => {
        // The status will automatically update through the useSRStatus query
        console.log('SR generation initiated');
      },
      onError: (error) => {
        console.error('Failed to generate SR:', error);
      }
    });
  };

  return (
    <div className="mb-8 flex flex-col items-center justify-center px-2">
      {/* <div>
      {currentSeriePredictionState[
              `${predict_payload?.SeriesInstanceUID}${predict_payload.SOPInstanceUID}`
            ]}
    </div> */}
      {/* {currentSeriePredictionState[
              `${predict_payload?.SeriesInstanceUID}${predict_payload.SOPInstanceUID}`
            ] !== 'PREDICTED' &&
              currentSeriePredictionState[
              `${predict_payload?.SeriesInstanceUID}${predict_payload.SOPInstanceUID}`
              ] !== 'INPROGRESS' &&
              currentSeriePredictionState[
              `${predict_payload?.SeriesInstanceUID}${predict_payload.SOPInstanceUID}`
              ] !== 'STATE_FETCHING' &&
      (
        <div className="flex h-full items-end justify-center">
                <Button
                  // style={{ cursor: `pointer`, background: "linear-gradient(92.47deg, #2278bc 2.16%, #4299e1 84.38%, #3e8fcc 98.33%)" }}
                  style={{ cursor: `pointer`, background: "#2886C7" }}
                  className="border-blue-100 text-sm text-white flex items-center justify-center space-x-2 border w-full mx-4"
                  onClick={generateSRHandler}
                >
                  <SiCodemagic />
                  <span>Auto AI Results</span>
                </Button>
              </div>
      )}
      {currentSeriePredictionState[
          `${predict_payload?.SeriesInstanceUID}${predict_payload.SOPInstanceUID}`
        ] === 'STATE_FETCHING' && (
            <>
              <LoaderPage className="h-20 w-20 animate-spin rounded-full border-t-4 border-b-4 border-blue-500" />
            </>
          )} */}

      {/* {currentSeriePredictionState[
          `${predict_payload?.SeriesInstanceUID}${predict_payload.SOPInstanceUID}`
        ] === 'INPROGRESS' && (
            <>
              <p className="py-4 text-center text-primary-active">AI AUTO RESULTS GENERATION ...</p>
              <LoaderPage className="h-20 w-20 animate-spin rounded-full border-t-4 border-b-4 border-blue-500" />
            </>
          )}
          {currentSeriePredictionState[
          `${predict_payload?.SeriesInstanceUID}${predict_payload.SOPInstanceUID}`
        ] === 'PREDICTED' && (
            <>
              <p className="py-4 text-center text-primary-active">Done !</p>
            </>
          )} */}
      <div
        className={classnames(
          `flex flex-1 cursor-pointer ${activeDisplaySetInstanceUIDs.includes(displaySetInstanceUID) ? 'bg-[#6060600c]' : ''}  flex-row rounded-md px-3 py-2 outline-none`,
          className
        )}
        id={`thumbnail-${displaySetInstanceUID}`}
      >
        <Thumbnail
          displaySetInstanceUID={displaySetInstanceUID}
          imageSrc={imageSrc}
          imageAltText={imageAltText}
          dragData={dragData}
          description={description}
          seriesNumber={seriesNumber}
          numInstances={numInstances}
          countIcon={countIcon}
          isActive={isActive}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          currentSeriePredictionState={currentSeriePredictionState}
          predict_payload={predict_payload}
          // generateSRHandler={generateSRHandler}
          generateSRHandler={predictSeries}
          isGenerating={isGenerating}

        />
        <div className="flex flex-col items-center justify-center space-y-2">
          <div
            className={classnames(
              'relative flex cursor-pointer flex-col items-center',
              isTracked && 'rounded-sm'
            )}
          >
            {/* <Tooltip
            position="left"
            content={
              <div className="flex flex-1 flex-row">
                <div className="flex-2 flex items-center justify-center pr-4">
                  <Icon
                    name="info-link"
                    className="text-primary-active"
                  />
                </div>
                <div className="flex flex-1 flex-col">
                  <span>
                    Series is
                    <span className="text-white">{isTracked ? ' tracked' : ' untracked'}</span>
                  </span>
                  {!!viewportIdentificator.length && (
                    <span>
                      in viewport
                      <span className="ml-1 text-black">{viewportIdentificatorLabel}</span>
                    </span>
                  )}
                </div>
              </div>
            }
          >
            <Icon
              name={trackedIcon}
              className="text-primary-light mb-2 w-4"
            />
          </Tooltip> */}
            <Icon
              name={trackedIcon}
              className="mb-2 w-4 fill-[#fff] text-[#21426d]"
            />
            <div
              className="text-center text-xl leading-tight text-white"
              data-cy={'thumbnail-viewport-labels'}
            >
              {renderViewportLabels()}
            </div>
          </div>
          {isTracked && (
            <div onClick={onClickUntrack}>
              <Icon
                name="cancel"
                className="w-4 fill-[#fff] text-[#a6abc8]"
              />
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          setDropdownOpen(!isDropdownOpen);
        }}
        className="inline-flex cursor-pointer items-center justify-center space-x-2 text-xs transition delay-150 duration-300 ease-in-out hover:translate-y-1 hover:scale-110"
      >
        <span>Show SR</span>
        {isDropdownOpen ? (
          <Icon
            name="ui-arrow-up"
            className="w-4 fill-[#fff] text-[#a6abc8]"
          />
        ) : (
          <Icon
            name="ui-arrow-down"
            className="w-4 fill-[#fff] text-[#a6abc8]"
          />
        )}
      </div>
      {isDropdownOpen && (
        <div className="mt-2 w-full  rounded-md border-[1px] border-[#e3e2e252] p-2 shadow-md">
          <div className="text-sm text-[#21426d]">List of Structured Reports</div>
          <div className="flex flex-wrap items-center justify-center space-x-2 py-3">
            {filterThumbnails?.length > 0 ? (
              <>
                {filterThumbnails.map((thumbnail, i) => (
                  // <ThumbnailNoImage
                  //   key={thumbnail.displaySetInstanceUID}
                  //   {...thumbnail}

                  // />
                  <ThumbnailNoImage
                    isActive={activeDisplaySetInstanceUIDs.includes(
                      thumbnail?.displaySetInstanceUID
                    )}
                    key={thumbnail?.displaySetInstanceUID}
                    displaySetInstanceUID={thumbnail?.displaySetInstanceUID}
                    seriesNumber={thumbnail?.seriesNumber}
                    serieInstanceUID={thumbnail?.SeriesInstanceUID}
                    dragData={thumbnail?.dragData}
                    modality={thumbnail?.modality}
                    modalityTooltip={_getModalityTooltip(thumbnail?.modality)}
                    seriesDate={thumbnail?.seriesDate}
                    description={thumbnail?.description}
                    canReject={thumbnail?.canReject}
                    onReject={onReject}
                    onClick={() => {
                      if (displaySetService.getActivesSRSerie() == false) {
                        onThumbnailClick(thumbnail?.displaySetInstanceUID);
                      } else {
                        uiNotificationService.show({
                          title: `Report Mode`,
                          message: `You cant select multiple series.`,
                          type: 'info',
                          duration: 3000,
                        });
                      }
                    }}
                    onDoubleClick={() => {
                      if (displaySetService.getActivesSRSerie() == false) {
                        onThumbnailDoubleClick(thumbnail?.displaySetInstanceUID);
                      } else {
                        uiNotificationService.show({
                          title: `Report Mode`,
                          message: `You cant select multiple series.`,
                          type: 'info',
                          duration: 3000,
                        });
                      }
                    }}
                    viewportIdentificator={thumbnail?.viewportIdentificator}
                    index={i + 1}
                  />
                ))}
              </>
            ) : (
              <div className="inline-flex items-center justify-center space-x-3">
                <MdFilterListOff />
                <div>No SR</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ThumbnailTracked.propTypes = {
  /**
   * Data the thumbnail should expose to a receiving drop target. Use a matching
   * `dragData.type` to identify which targets can receive this draggable item.
   * If this is not set, drag-n-drop will be disabled for this thumbnail.
   *
   * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
   */
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  displaySetInstanceUID: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAltText: PropTypes.string,
  description: PropTypes.string.isRequired,
  seriesNumber: StringNumber.isRequired,
  numInstances: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onClickUntrack: PropTypes.func.isRequired,
  viewportIdentificator: PropTypes.array,
  isTracked: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
};

export default ThumbnailTracked;
