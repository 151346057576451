import React, { useEffect, useState } from 'react';
import {
  AppstoreAddOutlined,
  BookOutlined,
  CopyOutlined,
  DesktopOutlined,
  FundOutlined,
  HomeOutlined,
  LeftCircleOutlined,
  LogoutOutlined,
  PieChartOutlined,
  RightCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { FiUser } from 'react-icons/fi';

import './style/index.css';
import { Link, useLocation } from 'react-router-dom';
import Biglogo from '../../../../assets/bigScreenLogo.png';
import smallLogo from '../../../../assets/smallScreenLogo.png';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../store/actions';
import { FaHome } from 'react-icons/fa';
import { useAuth } from 'react-oidc-context';
import { LuUser2 } from 'react-icons/lu';
import { SiFiles } from 'react-icons/si';
import { MdSpaceDashboard } from 'react-icons/md';
import { HiClipboardDocumentList } from 'react-icons/hi2';
import { VscSettings } from 'react-icons/vsc';
import { FaUserCog } from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import { GiTimeTrap } from 'react-icons/gi';
import { getProfile } from '../../../helpers/api/users/users';

const MenuItems = [
  {
    icon: (
      <FaHome
        className="inline-block"
        size={window.innerWidth < 1201 ? 15 : 23}
      />
    ),
    to: '/home',
    text: 'Dashboard',
    allowedRoles: ['super_admin', 'viewer'],
  },
  {
    icon: (
      <LuUser2
        className="inline-block"
        size={window.innerWidth < 1201 ? 15 : 23}
      />
    ),
    to: '/patients',
    text: 'Patients',
    allowedRoles: ['super_admin', 'viewer'],
  },
  // {
  //   icon: (
  //     <SiFiles
  //       className="inline-block"
  //       size={window.innerWidth < 1201 ? 15 : 23}
  //     />
  //   ),
  //   to: '/reports',
  //   text: 'Reports',
  // },
  // {
  //   icon: (
  //     <MdSpaceDashboard
  //       className="inline-block"
  //       size={window.innerWidth < 1201 ? 15 : 23}
  //     />
  //   ),
  //   to: '/analytics',
  //   text: 'Analytics',
  // },
  {
    icon: (
      <HiClipboardDocumentList
        className="inline-block"
        size={window.innerWidth < 1201 ? 15 : 23}
      />
    ),
    to: '/exams',
    text: 'Exams',
    allowedRoles: ['super_admin', 'viewer'],
  },
  // {
  //   icon: (
  //     <GiTimeTrap
  //       className="inline-block"
  //       size={window.innerWidth < 1201 ? 15 : 23}
  //     />
  //   ),
  //   to: '/realtime-v2',
  //   text: 'Realtime-v2',
  //   allowedRoles: ['super_admin', 'viewer'],
  // },
  // {
  //   icon: (
  //     <GiTimeTrap
  //       className="inline-block"
  //       size={window.innerWidth < 1201 ? 15 : 23}
  //     />
  //   ),
  //   to: '/realtime-v3',
  //   text: 'Realtime-v3',
  //   allowedRoles: ['super_admin', 'viewer'],
  // },
];

const ManagementItems = [
  {
    icon: (
      <FaUserCog
        className="inline-block"
        size={window.innerWidth < 1201 ? 15 : 23}
      />
    ),
    to: '/users',
    text: 'Users',
    allowedRoles: ['super_admin', 'user_manager'],
  },
];
const OtherItems = [
  // {
  //   icon: (
  //     <BiSupport
  //       className="inline-block"
  //       size={window.innerWidth < 1201 ? 15 : 23}
  //     />
  //   ),
  //   to: '/support',
  //   text: 'Help & Support',
  // },
];


const Sidebar = () => {
  const auth = useAuth();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [isViewerOpened, setIsViewerOpened] = useState(false);
  const { data, isLoading: isProfileLoading } = getProfile();
  const userRole = data?.data?.roles?.[0];
  const filterMenuItems = (items) => items.filter((item) => item.allowedRoles.includes(userRole));
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleLogout = () => {
    auth.signoutRedirect();
    // auth.signoutSilent();
    // window.location.reload()
    // navigate("/")
  };

  useEffect(() => {
    if (location.pathname === '/exams/viewer') {
      // setIsViewerOpened(true);
      setActive(true);
    } else if (location.pathname === '/exams/viewer' && active === false) {
      setActive(!active);
    } else if (location.pathname === '/realtime-inferencing') {
      setActive(true);
    } else {
      setActive(active);
    }
  }, [location.pathname]);

  const handleOpen = () => {
    setActive(!active);
  };
  return (
    <aside
      // className={`z-10 h-full bg-[#F7FAFE] shadow-2xl backdrop-blur-lg duration-100 ease-in-out max-md:fixed  max-md:bottom-0 max-md:h-[80px] max-md:w-full ${!active ? 'md:w-[85px] lg:w-52' : 'md:w-[40px] lg:w-20'}`}
      className={` z-10 h-full bg-[#F7FAFE] shadow-2xl backdrop-blur-lg duration-100 ease-in-out max-md:fixed  max-md:bottom-0 max-md:h-[80px] max-md:w-full ${!active ? 'sm:w-52 md:w-52 lg:w-52 xl:w-52' : 'sm:w-14 md:w-14 lg:w-14 xl:w-14'} `}
    >
      <nav className={`flex h-full max-md:items-center max-md:justify-evenly md:flex-col`}>
        <div
          // style={{ backgroundColor: isViewerOpened && `rgb(23 37 84)` }}
          className={`relative w-full scroll-pl-6 items-center justify-center p-2 ${active ? 'md:flex md:flex-col-reverse ' : 'h-[66px] md:flex md:space-x-3'}`}
        >
          {!active ? (
            <img
              src={Biglogo}
              alt="42"
              className={`overflow-hidden object-cover transition-all`}
            />
          ) : (
            <img
              src={smallLogo}
              alt="42"
              className={`mt-4 overflow-hidden object-cover transition-all`}
            />
          )}
          <div>
            <label className="hamburger sm:h-6 sm:w-6">
              <input
                type="checkbox"
                checked={!active}
                className="sm:h-4 sm:w-4"
              />
              <svg
                viewBox="0 0 32 32"
                onClick={() => {
                  if (location.pathname !== '/realtime-inferencing') {
                    setActive(!active);
                  }
                }}
                // onClick={() => { if(!location.pathname.startsWith('/exams/viewer/')){setActive(false)}}}
                className="sm:h-6 sm:w-6"
              >
                <path
                  className="line line-top-bottom"
                  d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                />
                <path
                  className="line"
                  d="M7 16 27 16"
                />
              </svg>
            </label>
          </div>
        </div>
        <div
          className={`flex h-full flex-col justify-between py-2 `}
          // style={{ backgroundColor: isViewerOpened && `rgb(23 37 84)` }}
        >
          <div className={`flex flex-row md:flex-col ${!active && "space-y-5"}`}>
            <div>
              {/* {!active && (
                <span
                  className={`md:w-35 w-15 group flex cursor-pointer items-center rounded-md sm:mx-0 sm:text-xs md:mx-1 md:text-xs lg:mx-1 lg:text-sm xl:mx-1 xl:text-base ${active ? 'px-2.5' : 'px-2'} py-2  font-medium text-gray-500 transition-colors  `}
                >
                  Menu
                </span>
              )} */}

              {filterMenuItems(MenuItems).map(({ icon, to, text }, index) => (
                <Link
                  to={process.env.DEPLOYMENT_TARGET === 'Morocco' ? '#' : to}
                  key={index}
                  className="flex items-center justify-center "
                >
                  <div
                    className={` ${location.pathname === to ? ' bg-[#DEEAFA] text-[#2d84c7]' : 'text-gray-500'} md:w-35 w-15 group relative flex cursor-pointer items-center space-x-3 rounded-md px-3 py-2 font-medium text-white transition-colors md:mx-3 `}
                    title={text}
                  >
                    {icon}
                    {!active && (
                      <span className={`hidden overflow-hidden  transition-all md:w-32 lg:block`}>
                        {text}
                      </span>
                    )}
                  </div>
                </Link>
              ))}
            </div>
            <div>
              {/* {!active && (
                <div className="flex">
                  <span
                    className={`w-full cursor-pointer items-center rounded-md sm:mx-0 sm:text-xs md:mx-1 md:text-xs lg:mx-1 lg:text-sm xl:mx-1 xl:text-base ${active ? 'px-2.5' : 'px-2'} py-2  font-medium text-gray-500 transition-colors`}
                  >
                    Mngt
                  </span>{' '}
                </div>
              )} */}

              {filterMenuItems(ManagementItems).map(({ icon, to, text }, index) => (
                <Link
                  to={process.env.DEPLOYMENT_TARGET === 'Morocco' ? '#' : to}
                  key={index}
                  className="flex items-center justify-center "
                >
                  <div
                    className={` ${location.pathname === to ? ' bg-[#DEEAFA] text-[#2d84c7]' : 'text-gray-500'} md:w-35 w-15 group relative flex cursor-pointer items-center space-x-3 rounded-md px-3 py-2 font-medium text-white transition-colors md:mx-3 `}
                  >
                    {icon}
                    {!active && (
                      <span className={`hidden overflow-hidden  transition-all md:w-32 lg:block `}>
                        {text}
                      </span>
                    )}
                  </div>
                </Link>
              ))}
            </div>
            {/* <div>
              <span className="md:w-35 w-15 group flex cursor-pointer items-center rounded-md px-2 py-2 font-medium text-gray-500 transition-colors sm:mx-0 sm:text-xs md:mx-1 md:text-xs lg:mx-1 lg:text-sm xl:mx-1 xl:text-base ">
                Others
              </span>

              {OtherItems.map(({ icon, to, text }, index) => (
                <Link
                  to={process.env.DEPLOYMENT_TARGET === 'Morocco' ? '#' : to}
                  key={index}
                  className="flex items-center justify-center"
                >
                  <div
                    className={` ${location.pathname === to ? ' bg-[#DEEAFA] text-[#2d84c7]' : 'text-gray-500'} md:w-35 w-15 group relative flex cursor-pointer items-center space-x-3 rounded-md px-3 py-2 font-medium text-white transition-colors md:mx-3`}
                  >
                    {icon}
                    {!active && (
                      <span className={`hidden overflow-hidden  transition-all md:w-32 lg:block`}>
                        {text}
                      </span>
                    )}
                  </div>
                </Link>
              ))}
            </div> */}
          </div>

          <div>
            <Link
              to=""
              onClick={handleLogout}
              className="flex items-center justify-center"
            >
              <div
                className={` text-gray-500" md:w-35 w-15 group relative flex cursor-pointer items-center space-x-3 rounded-md px-3 py-2 font-medium text-gray-500 transition-colors md:mx-3 `}
              >
                <FiLogOut size={window.innerWidth < 1201 ? 15 : 23} />
                {!active && (
                  <span className={`hidden overflow-hidden  transition-all md:w-32 lg:block `}>
                    Logout
                  </span>
                )}
              </div>
            </Link>
            {/* <div
              className="flex h-full w-full justify-end"
              style={{ fontSize: '8px', margin: 'auto' }}
            >
              <span className="mr-3 text-gray-600  sm:text-[7px]">
                v1.7.0
              </span>
            </div> */}
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
