import React, { useState } from 'react';
import { api } from '../../../helpers/backendHelper';

const Training: React.FC = () => {
  const [datasets, setDatasets] = useState<any>(null);

  const fetchDatasets = async () => {
    try {
      const response = await api.get('/sample-datasets');
      setDatasets(response);
      console.log('Datasets:', response);
    } catch (error) {
      console.error('Error fetching datasets:', error);
    }
  };

  const handleDownload = async (datasetId: string) => {
    try {
      const response = await api.get(`/sample-datasets/${datasetId}/download`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${datasetId}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading dataset:', error);
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'auto', padding: '20px' }}>
      <h1>Sample Datasets</h1>
      <button 
        onClick={fetchDatasets}
        style={{
          padding: '10px 20px',
          margin: '20px 0',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        Get Sample Info
      </button>
      {datasets && (
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '2px solid #dee2e6' }}>Dataset Name</th>
                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '2px solid #dee2e6' }}>Description</th>
                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '2px solid #dee2e6' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(datasets).map(([id, dataset]: [string, any]) => (
                <tr key={id} style={{ borderBottom: '1px solid #dee2e6' }}>
                  <td style={{ padding: '12px' }}>{dataset.name}</td>
                  <td style={{ padding: '12px' }}>{dataset.description}</td>
                  <td style={{ padding: '12px' }}>
                    <button
                      onClick={() => handleDownload(id)}
                      style={{
                        padding: '6px 12px',
                        backgroundColor: '#28a745',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                      }}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Training;