import React, { useEffect, useState } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import './latestExams.section.css';
import { Link } from 'react-router-dom';
import { useRecentStudies } from '../../../helpers/api/studies/studies';
import { APIClient } from '../../../helpers/apiHelper';
import { Spin } from 'antd';



interface Patient {
  id: number;
  patient_id: number; 
  patient_name: string;
  patient_last_name: string;
}

interface Study {
  study_instance_uid: string;
  image_url: string;
  study_date: string;
  patient: Patient;
}

interface StudyResponse {
  data: {
    result: {
      data: Study[];
      total_count: number;
    }
  }
}

const LatestExams: React.FC = () => {
  const { data } = useRecentStudies();
  const [images, setImages] = useState<Record<string, string>>({});
  const [loadingImages, setLoadingImages] = useState<Record<string, boolean>>({});
  const api = new APIClient();

  useEffect(() => {
    const studies = (data as StudyResponse)?.data?.result?.data;
    if (studies?.length) {
      studies.forEach(async (study) => {
        setLoadingImages(prev => ({ ...prev, [study.study_instance_uid]: true }));
        try {
          // The response is already a base64 data URL, we can use it directly
          const imageUrl = await api.fetchImageAsBase64DataUrl(`${process.env.BACKEND_URL}${study.image_url}`);
          setImages(prev => ({
            ...prev,
            [study.study_instance_uid]: imageUrl,
          }));
        } catch (error) {
          console.error('Error fetching image:', error);
        } finally {
          setLoadingImages(prev => ({ ...prev, [study.study_instance_uid]: false }));
        }
      });
    }
  
  }, [data]);

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric' 
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };



  return (
    <div className="inline-block h-full w-full overflow-auto">
      {!data?.data?.result?.data?.length ? (
        <div className="flex h-full w-full items-center justify-center">
          <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
            No exams available
          </p>
        </div>
      ) : (
        data.data.result.data.map(study => (
          <Link
            to={`/exams/viewer?StudyInstanceUIDs=${study.study_instance_uid}`}
            key={study.study_instance_uid}
          >
            <div className="inline-block w-1/6 p-2 text-center">
              {loadingImages[study.study_instance_uid] ? (
                <div className="flex items-center justify-center lg:h-[110px] lg:w-[171.2px]">
                  <Spin className="mr-2" />
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src={images[study.study_instance_uid]}
                    alt="Exam Thumbnail"
                    draggable={false}
                    className="rounded-lg lg:h-[110px] lg:w-[171.2px]"

                  />
                </div>
              )}
              <p className="text-[8px] font-normal text-[#5A5A5A]">
                {formatDate(study.study_date)}
              </p>
              <p className="text-xs font-normal text-black">
                {`${study.patient.patient_name} ${study.patient.patient_last_name}`}
              </p>
            </div>
          </Link>
        ))
      )}
    </div>
  );
};

export default LatestExams;
