import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';

interface CustomDropdownProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
  className?: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  value,
  onChange,
  label,
  disabled,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleClick = () => {
    if (!disabled) {
      setIsOpen(prev => !prev)
    }
  };
  const checkDropdownPosition = () => {
    if (!buttonRef.current || !dropdownRef.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const dropdownRect = dropdownRef.current.getBoundingClientRect();

    const spaceBelow = window.innerHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;

    if (spaceBelow >= dropdownRect.height) {
      setDropdownPosition('bottom');
    } else if (spaceAbove >= dropdownRect.height) {
      setDropdownPosition('top');
    } else {
      setDropdownPosition('bottom');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      checkDropdownPosition(); 
    }
  }, [isOpen]); 

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      {/* {label && <label className="mb-1 block text-gray-700">{label}</label>} */}
      <button
        ref={buttonRef}
        className={`w-full rounded-lg border bg-white px-4 py-2 text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
          disabled ? 'cursor-not-allowed opacity-50' : ''
        }`}
        onClick={handleClick}
        disabled={disabled}
      >
        {options.find(opt => opt === value) || 'Select an option'}
        <FaChevronDown className="ml-2 inline-block" />
      </button>
      {isOpen &&
        !disabled && (
          <div
            ref={dropdownRef}
            className={`absolute z-10 w-full rounded-lg border border-gray-300 bg-white shadow-lg ${
              dropdownPosition === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'
            }`}
            style={{
              maxHeight: '300px',
              overflowY: 'auto',
            }}
          >
            {options.map(option => (
              <div
                key={option}
                onClick={() => {
                  if (!disabled) {
                    onChange(option);
                    setIsOpen(false);
                  }
                }}
                className="cursor-pointer px-4 py-2 hover:bg-gray-100"
              >
                {option}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default CustomDropdown;
