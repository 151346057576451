import React, { useEffect, useState } from 'react';
import checkImage from './assets/check.jpg';
import unCheckImage from './assets/uncheck.jpg';
import { Switch, Tooltip } from 'antd';
import NotFound from './assets/notfound.png';
import FramesDisplayer from './framesDisplayer';
import { MdOutlineImageNotSupported } from "react-icons/md";
const ReportPlane: React.FC = props => {
  const {
    setExamData,
    plan,
    examData,
    editMode,
    reportDetails,
    selectedFrame,
    setSelectedFrameID,
    setSelectedFrame,
    setSelectedFramePlan,
    onChangeSwitch_cri1,
    resetCriteria2,
    onChangeSwitch_cri2,
    onChangeSwitch_cri3,
    onChangeSwitch_cri4,
    onChangeSwitch_cri6,
    onChangeSwitch_cri5,
    selectedFrameID,
    qualityCriterias,
    image,
    frame
  } = props;
  return (
    console.log("plane", plan, 'reportDetails**', reportDetails, reportDetails?.report?.report_content?.[plan]),
    <>
      <div className="px-2">
        <div className="grid grid-cols-2 mb-3">
          <span className="col-span-1 text-sm text-left pl-2 ">
            <span className="font-bold">Detected plane</span>:
            {plan}
          </span>
          <span className="col-span-1 text-sm text-right pr-2">
            <span className="font-bold">Quality score</span>:
            {!reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_VAL__SCORE
              ? 'N/A'
              : (
                Number(
                  reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_VAL__SCORE
                ) * 100
              ).toFixed(2) + '%'}
          </span>
        </div>
        {reportDetails?.report?.report_content?.[plan] && Object.keys(reportDetails?.report?.report_content?.[plan]).length !== 0 ? (
          <div className="px-2">
            <div className='mb-2  border-[3px]'>
              <div style={{ height: '250px' }}>
                {/* <FramesDisplayer
                  resetCriteria2={resetCriteria2}
                  report={reportDetails}
                  qualityCriterias={qualityCriterias}
                  selectedFrame={selectedFrame}
                  plan={plan}
                  onChangeSwitch_cri1={onChangeSwitch_cri1}
                  selectedFrameID={selectedFrameID}
                  setSelectedFrameID={setSelectedFrameID}
                  setSelectedFrame={setSelectedFrame}
                  setSelectedFramePlan={setSelectedFramePlan}
                /> */}
                <img
                  src={image ? ("data:image/png;base64," + image) : NotFound}
                  style={{
                    height: '250px',
                    width: '420px',
                    border: '1px solid gray',
                    margin: '0 auto',
                  }}
                  className="mx-auto my-auto "
                />
              </div>
            </div>
            <p className="text-left font-bold">Quality criteria detected:</p>
            <div className="text-xs grid grid-cols-2 grid-rows-3">
              {qualityCriterias?.includes('QUAL_FLAG__cephal_thalamus') && (
                <div
                  className="text-left col-span-1 col-row-2 px-1 flex  border-b-2"
                >
                  Plan showing thalami {' '}
                  {editMode ? (
                    <Switch
                      className={`ml-auto my-auto bg-red-500`}
                      defaultChecked={qualityCriterias.QUAL_VAL__ZOOM ? true : false}
                      size="small"
                      onChange={checked =>
                        onChangeSwitch_cri1(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_FLAG__cephal_thalamus === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias?.includes('STD_PLANE__cephalic') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex border-b-2">
                  <span>Plan showing cavum septum pellucidi</span>
                  <Tooltip
                    className="my-auto"
                    color="blue"
                    title="Reset the original value"
                  >
                    <span
                      onClick={() => resetCriteria2(setExamData, examData)}
                      className="text-xs cursor-pointer"
                    >
                      🔄
                    </span>
                  </Tooltip>
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria2
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri2(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.STD_PLANE__cephalic === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('STD_PLANE__cephalic') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  Cerebellum not visible{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_VAL__SCORE === true // QUAL_VAL__SCORE
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('QUAL_FLAG__abdo_stomach_bubble') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  abdo_stomach_bubble visible{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_FLAG__abdo_stomach_bubble === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('QUAL_FLAG__abdo_portal_sinus') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  abdo_portal_sinus visible{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_FLAG__abdo_portal_sinus === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('STD_PLANE__abdominal') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  fetal kidney not visible{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.STD_PLANE__abdominal === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('STD_PLANE__af') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  placanta not visible{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.STD_PLANE__af === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('STD_PLANE__af') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  umbilical not visible{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.STD_PLANE__af === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('QUAL_VAL__FEMURAL_ANGLE') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  less than 45 to horizontal{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_VAL__FEMURAL_ANGLE === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}

              {qualityCriterias.includes('STD_PLANE__femur') && (
                <div className="text-left col-span-1 col-row-2 px-1 flex  border-b-2">
                  fetal spine not showing{' '}
                  {editMode ? (
                    <Switch
                      className=" ml-auto my-auto bg-red-500"
                      defaultChecked={
                        examData.section2.firstColFirstRow.criterias.criteria3
                          .status === 'success'
                          ? true
                          : false
                      }
                      size="small"
                      id=""
                      onChange={checked =>
                        onChangeSwitch_cri3(checked, setExamData, examData)
                      }
                    />
                  ) : (
                    <img
                      src={
                        reportDetails?.report?.report_content?.[plan]?.[frame]?.STD_PLANE__femur === true
                          ? checkImage
                          : unCheckImage
                      }
                      className="h-3 ml-auto my-auto"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='border-gray-200 bg-gray-100 mr-auto mb-2  border-[3px] px-2 ml-2'>
            <div className="flex items-center justify-center h-[250px] w-full ">
              <div className="text-center text-gray-500 flex items-center justify-center flex-col">
              <div className="text-6xl mb-2">
                <MdOutlineImageNotSupported size={80} />
              </div>
              <div className="text-lg">No selected plane</div>
            </div>
              {/* <div className="text-center text-gray-500">
                <div className="text-6xl mb-2">⚠️</div>
                <div className="text-lg">No selected plane</div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportPlane;
