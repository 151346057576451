import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react'
import DateRangePicker from '../../Components/date-range-picker';
import { Form, Input, Button, Spin } from 'antd';
import { CellContext, ColumnDef, PaginationState, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useArchiveExams, usePaginatedExams, useUnarchiveExams, useArchiveExam, useUnarchiveExam } from '../../../helpers/api/studies/studies';
import { CopyOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as rdrLocales from 'react-date-range/dist/locale';
import Table from '../../Components/Table/Table';
import { update } from 'lodash';
import NewExam from '../../Components/NewExam/NewExam';


const style = {
    fontFamily: 'Outfit, sans-serif',
};
function ExamManagement() {

    const [pendingOperations, setPendingOperations] = useState(new Set());
    const [refetchingIds, setRefetchingIds] = useState<string[]>([]);
    const [isBulkOperationPending, setIsBulkOperationPending] = useState(false);


    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [patientFilters, setPatientFilters] = useState({});
    const [examFilters, setExamFilters] = useState({});
    const [search, setSearch] = useState('');
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        reset: false,
    });
    const [dateFilter, setDateFilter] = useState<any[]>([]);
    const [copied, setCopied] = useState(false);
    const [tabs, setTabs] = useState([
        {
            name: 'Active',
            icon: 'ri-checkbox-blank-circle-line',
            active: true,
        },
        {
            name: 'Archived',
            icon: 'ri-archive-line',
            active: false,
        },
    ]);
    const buildFilters = (filters, searchMode) => {
        const updatedFilters = {};

        Object.keys(filters).forEach(key => {
            const newKey = `${key}__ilike`;
            const newValue = `%${filters[key]}%`;
            updatedFilters[newKey] = newValue;
        });

        if (searchMode === 'OR') {
            return { condition__or: updatedFilters };
        }
        return updatedFilters;
    };
    const queryConfig = {
        filters: {
            patient_filters: buildFilters(patientFilters, 'OR'),
            exam_filters: {
                is_archived: !(tabs.find(tab => tab.active)?.name === 'Active'),
                ...examFilters
            },
        },
    };
    const archiveExams = useArchiveExams();
    const unarchiveExams = useUnarchiveExams();
    const archiveExam = useArchiveExam();
    const unarchiveExam = useUnarchiveExam();
    
    const { data, isError, isLoading, refetch } = usePaginatedExams(
        pagination.pageIndex + 1,
        pagination.pageSize,
        queryConfig
    );


    const handleRefetch = async (ids: string[]) => {
        setRefetchingIds(prev => [...prev, ...ids]);
        await refetch();
        setRefetchingIds(prev => prev.filter(id => !ids.includes(id)));
    };




    const handleOperation = async (studyInstanceUid: string, operation: 'archive' | 'unarchive') => {
        // Don't check exam state for unarchive operations since it won't be in the current view
        if (pendingOperations.has(studyInstanceUid) || 
            (operation === 'archive' && data?.result?.data.find(exam => 
                exam.study_instance_uid === studyInstanceUid)?.is_archived)) {
            return;
        }
    
        setPendingOperations(prev => new Set([...Array.from(prev), studyInstanceUid]));
        
        try {
            const mutateFunction = operation === 'archive' ? archiveExam : unarchiveExam;
            await mutateFunction.mutateAsync([studyInstanceUid], {
                onSuccess: async () => {
                    await handleRefetch([studyInstanceUid]);
                    table.resetRowSelection();
                }
            });
        } catch (error) {
            console.error(`Failed to ${operation} exam:`, error);
        } finally {
            setPendingOperations(prev => {
                const next = new Set(prev);
                next.delete(studyInstanceUid);
                return next;
            });
        }
    };

    const handleBulkAction = async (examIds: string[]) => {
        const newIds = examIds.filter(id => !Array.from(pendingOperations).includes(id));
        if (newIds.length === 0) return;

        setIsBulkOperationPending(true);
        setPendingOperations(prev => new Set([...Array.from(prev), ...newIds]));

        try {
            if (tabs.find(tab => tab.active)?.name === 'Active') {
                await archiveExams.mutateAsync(newIds, {
                    onSuccess: async () => {
                        await handleRefetch(newIds);
                        table.resetRowSelection();

                    },
                    onSettled: () => {
                        setIsBulkOperationPending(false);
                        setPendingOperations(prev => 
                            new Set(Array.from(prev).filter((id: string) => !newIds.includes(id)))
                        );
                    }
                });
            } else {
                await unarchiveExams.mutateAsync(newIds, {
                    onSuccess: async () => {
                        await handleRefetch(newIds);
                        table.resetRowSelection();

                    },
                    onSettled: () => {
                        setIsBulkOperationPending(false);
                        setPendingOperations(prev =>
                            new Set(Array.from(prev).filter((id: string) => !newIds.includes(id)))
                        );
                    }
                });
            }
        } catch (error) {
            console.error('Failed to process exams:', error);
        }
    };



    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'select',
                header: ({ table }) => (
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="checkBoxAll"
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                        />
                    </div>
                ),
                cell: ({ row }) => (
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            checked={row.getIsSelected()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
            },
            {
                // accessorKey: 'patient.patient_name + patient.last_name',
                accessorKey: 'patient',
                header: () => <span>Patient Name</span>,
                cell: cell => {
                    console.log('cell', cell.getValue())
                    return <>{cell.getValue()?.patient_name} {cell.getValue()?.patient_last_name}</>
                },
                footer: props => props.column.id,
            },
            {
                accessorKey: 'study_date',

                header: () => <span>Study Date</span>,
                cell: cell => formatDate(cell.getValue()),
                footer: props => props.column.id,
            },
            {
                accessorKey: 'study_instance_uid',
                cell: (cell: CellContext<any, string>) => {
                    console.log('cell', cell.getValue())

                    // return <span className='bg-red-500'>{cell.getValue()}</span>;
                    return <>{cell.getValue() && cell.getValue()?.length > 5 ? `${cell.getValue().substring(0, 10)}...` : cell.getValue()}
                        <button onClick={() => copyToClipboard(cell.getValue())}>
                            <CopyOutlined className='text-blue-500' />
                        </button></>
                },
                header: () => <span>Study Instance UID</span>,
                footer: props => props.column.id,
            },
            {
                accessorKey: 'description',
                cell: (cell: CellContext<any, string>) => {
                    return <>{(cell.getValue() !== 'null' && cell.getValue()?.length > 0) ? cell.getValue() : "no description"}</>
                },
                header: () => <span>Description</span>,
                footer: props => props.column.id,
            },
            {
                header: 'Actions',
                size: 5,
                cell: cellProps => 
                    {
                        const currentId = cellProps.row.original.study_instance_uid;
                        const isAnyOperationPending = pendingOperations.size > 0 || refetchingIds.length > 0;
                        const isCurrentOperationPending = pendingOperations.has(currentId) || 
                            refetchingIds.includes(currentId);
    
                        return (
                            <ul className="flex">
                            {!cellProps.row.original.is_archived && (
                              <Link to={`/exams/viewer?StudyInstanceUIDs=${currentId}`}>
                                <li className="mr-2 cursor-pointer text-blue-800">
                                  <i className="ri-eye-fill fs-16" />
                                </li>
                              </Link>
                            )}
                            <li
                              onClick={() => !isAnyOperationPending && 
                                handleOperation(
                                  currentId, 
                                  cellProps.row.original.is_archived ? 'unarchive' : 'archive'
                                )}
                              className={`mr-2 ${
                                isAnyOperationPending || isCurrentOperationPending
                                  ? 'cursor-not-allowed text-gray-400' 
                                  : `cursor-pointer ${cellProps.row.original.is_archived ? 'text-green-500' : 'text-red-500'}`
                              }`}
                            >
                              {isCurrentOperationPending ? (
                                <i className="ri-loader-4-line animate-spin fs-16" />
                              ) : (
                                <i className={`ri-inbox-${cellProps.row.original.is_archived ? 'unarchive' : 'archive'}-line fs-16`} />
                              )}
                            </li>
                          </ul>
                        );
                      },
            },
        ],
        [pendingOperations, refetchingIds]
    );





    const table = useReactTable({
        data: data?.result?.data || [],
        columns,
        rowCount: data?.result?.total_count || 0,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true, //we're doing manual "server-side" pagination
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        debugTable: true,
    });
    
    const selectedRecords = table.getSelectedRowModel().rows.map(row => row.original);
    console.log('selectedRecords', selectedRecords);
    const updateExamDateFilters = (dates) => {
        const formatDate = (dateString) => {
            if (!dateString) return '';
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }

        if (dates?.reset === false && (dates?.startDate || dates?.endDate)) {
            const formattedStartDate = formatDate(dates.startDate);
            let formattedEndDate = formatDate(dates.endDate);

            if (formattedStartDate === formattedEndDate && dates.endDate) {
                const endDate = new Date(dates.endDate);
                endDate.setDate(endDate.getDate() + 1);
                formattedEndDate = formatDate(endDate);

                setExamFilters(prev => ({
                    ...prev,
                    study_date__gte: formattedStartDate,
                    study_date__lt: formattedEndDate,
                }));
            } else {
                setExamFilters(prev => ({
                    ...prev,
                    study_date__gte: formattedStartDate,
                    study_date__lte: formattedEndDate,
                }));
            }

            setTimeout(refetch, 100);
        }
    };



    function formatDate(birthDate: any) {
        const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
        return new Date(birthDate).toLocaleDateString('en-US', options);
    }
    const copyToClipboard = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => setCopied(true))
            .catch((error) => console.error('Failed to copy:', error));
    };
    const handleClearFilters = () => {
        setPatientFilters({});
        setDateRange({
            startDate: new Date(),
            endDate: new Date(),
            reset: true,
        });
        const { study_date__gte, study_date__lte, study_date__gt, study_date__lt, ...remainingExamFilters } = examFilters as unknown as { study_date__gte?: string, study_date__lte?: string, study_date__lt?: string, study_date__gt?: string };
        setExamFilters(remainingExamFilters);
        setTimeout(refetch, 100);
    };


    useEffect(() => {
        const handleClickOutside = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const resetExamByDate = (dates) => {
        if (dates?.reset == true) {
            const { study_date__gte, study_date__lte, study_date__gt, study_date__lt, ...remainingExamFilters } = examFilters as unknown as { study_date__gte?: string, study_date__lte?: string, study_date__lt?: string, study_date__gt?: string };
            setExamFilters(remainingExamFilters);
            setTimeout(refetch, 100);
        }
    };

    const toggleDropdown = () => {
        setFiltersVisible(prevState => !prevState);
    };
    const handleFilterChange = e => {
        const { name, value } = e.target;
        setSearch(value);

        setPatientFilters(prev => {
            const updatedFilters = { ...prev };

            if (value === '') {
                delete updatedFilters['patient_name'];
                delete updatedFilters['patient_last_name'];
            } else {
                updatedFilters['patient_name'] = value;
                updatedFilters['patient_last_name'] = value;
            }

            return updatedFilters;
        });
        // this should be replaced by queryKeys
        setTimeout(refetch, 100);
    };
    const setActiveTab = (name: string) => {
        setTabs(prev =>
            prev.map(item =>
                item.name === name ? { ...item, active: true } : { ...item, active: false }
            )
        );
        setTimeout(refetch, 100);
    };


    return (
        <div
            className="flex h-full flex-col px-4"
            style={style}
        >
            <div className="relative my-4 h-full rounded-md border border-gray-200 bg-white">
                <div className="p-2">
                    <div>
                        <div className="flex h-8 w-full flex-row-reverse">

                            <NewExam
                                page={'examPage'}
                                refetchExams={refetch}
                            />

                            <div
                                className="relative"
                                ref={dropdownRef}
                            >
                                <button
                                    onClick={toggleDropdown}
                                    className={classNames(
                                        'border-sm flex h-8 items-center justify-between rounded border font-normal transition duration-150 ease-in-out focus:outline-none focus:ring-2',
                                        {
                                            'border-blue-600 bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-300':
                                                filtersVisible,
                                            'border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white focus:ring-blue-200':
                                                !filtersVisible,
                                        }
                                    )}
                                >
                                    <i className="ri-equalizer-line flex h-full w-8 items-center justify-center py-2 text-center"></i>
                                    <span className="py-2 pr-2">Filters</span>
                                </button>
                            </div>
                        </div>

                        <div className="container mx-auto">
                            <div
                                className={classNames(
                                    'mt-4 flex flex-col gap-2 justify-center transition-all duration-300 md:flex-row',
                                    {
                                        'h-0 overflow-hidden': !filtersVisible,
                                        'h-auto': filtersVisible,
                                    }
                                )}
                            >
                                <DateRangePicker
                                    locale={rdrLocales.fr}
                                    range={dateRange}
                                    currentFixed={dateFilter}
                                    type="range"
                                    color="#f85a32"
                                    onChange={dates => {
                                        setDateRange(dates);
                                        updateExamDateFilters(dates);
                                        console.log('datees', dates)
                                    }}
                                    onReset={dates => {
                                        //resetPatientsByDate(dates);
                                        resetExamByDate(dates);
                                    }}
                                    isDisabled={false}
                                    isLoading={false}
                                    inputRanges={[]}
                                    showDateDisplay={true}
                                    showMonthAndYearPickers={true}
                                // maxDate={new Date()}
                                />
                                <Form.Item className="w-full md:w-1/4">
                                    <Input
                                        name="search" // Set the name to identify the input
                                        placeholder="Search By Patient Name"
                                        value={search || ''} // Bind the state to the input value
                                        onChange={handleFilterChange} // Handle input change
                                    />
                                </Form.Item>
                                <Button
                                    type="primary"
                                    className="w-full bg-blue-500 text-white md:w-auto"
                                    onClick={handleClearFilters}
                                >
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 flex justify-between border-b border-gray-200">
                        <ul
                            id="type-tab"
                            className="-mb-px flex flex-wrap text-center text-sm font-normal text-gray-500"
                        >
                            {tabs.map((tab, index) => (
                                <li
                                    className="mr-3"
                                    key={index}
                                >
                                    <span
                                        onClick={() => {
                                            setActiveTab(tab.name);
                                        }}
                                        className={`group inline-flex cursor-pointer items-center justify-center gap-1 p-2 transition duration-150 ease-in-out ${tab.active
                                            ? 'border-b-2 border-blue-600 font-semibold text-blue-600'
                                            : 'border-b-2 border-transparent text-gray-700 hover:text-gray-600'
                                            }`}
                                        aria-current={tab.active ? 'page' : undefined}
                                    >
                                        <i className={`${tab.icon} flex h-4 w-4 items-center text-lg`}></i>
                                        {tab.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                        {selectedRecords.length > 0 && (
                            <button
                                onClick={() => handleBulkAction(selectedRecords.map(record => record.study_instance_uid))}
                                disabled={isBulkOperationPending || (pendingOperations.size > 0 || refetchingIds.length > 0)}
                                className={`flex items-center gap-1 px-1  text-sm rounded ${
                                    isBulkOperationPending || (pendingOperations.size > 0 || refetchingIds.length > 0)
                                        ? 'bg-gray-400 cursor-not-allowed'
                                        : 'bg-blue-600 hover:bg-blue-700'
                                } text-white transition-colors`}
                            >
                                {isBulkOperationPending || (pendingOperations.size > 0 || refetchingIds.length > 0) ? (
                                    <span className="flex items-center gap-1">
                                        <i className="ri-loader-4-line animate-spin"></i>
                                        Processing
                                    </span>
                                ) : (
                                    <span className="flex items-center gap-1">
                                        <i className={tabs.find(tab => tab.active)?.name === 'Active' 
                                            ? 'ri-archive-line' 
                                            : 'ri-inbox-unarchive-line'}
                                        />
                                        {tabs.find(tab => tab.active)?.name === 'Active' 
                                            ? `Archive Selected (${selectedRecords.length})` 
                                            : `Unarchive Selected (${selectedRecords.length})`}
                                    </span>
                                )}
                            </button>
                        )}
                    </div>
                </div>
                <Table
                    table={table}
                    totalCount={data?.result?.total_count}
                    isLoading={isLoading}
                    pageSize={pagination.pageSize}
                    columnsLength={columns.length}
                />
            </div>
        </div>
    )
}

export default ExamManagement
