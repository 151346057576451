import React, { useEffect, useState } from 'react';
import Actions from '../../Sections/Actions/actions.section';
import RecentPatients from '../../Sections/RecentPatients/recentPatients.section';
import LatestExams from '../../Sections/LatestExams/latestExams.section';
import './home.page.css';
import { useDispatch, useSelector } from 'react-redux';
import { getPatients, getStudies, resetState, resetStudyState } from '../../../store/actions';
import _ from 'lodash';
import { State } from '../../interfaces';
import { usePaginatedPatients,useRecentPatients } from '../../../helpers/api/patients/patients';
import { PaginationState } from '@tanstack/react-table';
const Home: React.FC = () => {
  const [noData, setNoData] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { profile } = useSelector((state: State) => ({
    profile: state.Profile,
  }));

  const { studyState } = useSelector((state: any) => ({
    studyState: state.Study,
  }));


  const recentPatients = useRecentPatients(
    30,
    false,
  );

  useEffect(() => {
    dispatch(getStudies({ filter_options: { page: 1, items_per_page: 12 } }));
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);
  return (
    <div className="mx-auto flex h-full flex-col space-x-4 overflow-scroll py-1 pl-3 md:flex-row ">
      <div className="flex h-full w-full flex-col space-y-3 md:w-[70%] ">
        <div className="h-[30%] w-full rounded-lg border">
          <h5 className="h-[40px] p-2 text-[1rem] font-medium text-[#1F384C]">Latest Exams</h5>
          <div className="h-[calc(100%-40px)] w-full">
            <LatestExams datax={studyState?.studies} />
          </div>
        </div>
        <div className="flex h-[40%] w-full flex-col gap-3 md:flex-row [&>div]:rounded-lg">
          <div className="h-full min-h-[200px] w-full rounded-lg border p-2">
            <Actions />
          </div>
          {/* <div className='h-full md:w-1/3 w-full border rounded-lg min-h-[200px]'>
            <h5 className="text-[1rem] font-medium text-[#1F384C] p-2 h-[40px]">Realtime Mode</h5>
            <div className='h-[calc(100%-40px)] w-full'>
              <QuickExam />
            </div>
          </div> */}
        </div>
        <div className="h-[30%] w-full  rounded-lg border">
          <h5 className="h-[40px] p-2 text-[1rem] font-medium text-[#1F384C]">Recent Reports</h5>
          <div className="h-[calc(100%-40px)] w-full">
            {noData ? (
              <>
                <p className="no-data-message">There are no reports at the moment</p>
              </>
            ) : (
              <div className="flex h-full w-full items-center justify-center">
                <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
                  No Report available
                </p>
              </div>
              // <RecentReports />
            )}
          </div>
        </div>
      </div>
      <div className="h-full w-full rounded-lg border p-2 md:w-[30%]">
        <h5 className="h-[40px] p-2 text-[1rem] font-medium text-[#1F384C]">Recent Patients</h5>
        <div className="h-[calc(100%-40px)] ">
          <RecentPatients PatientsList={recentPatients?.data?.items || []} />
        </div>
      </div>
    </div>
  );
};

export default Home;
