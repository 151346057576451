export function getFormattedDate() {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = now.getFullYear();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year}-${hours}:${minutes}:${seconds}`;
  }

export function extractFrameNumber(data) {
    // Get all keys that start with 'imageId:wadors'
    if (!data) {
      return null;
    }
    const keys = Object.keys(data).filter(key => key.startsWith("imageId:wadors"));

    if (keys.length === 0) {
      return null; // or handle the absence of such keys appropriately
    }

    // Pick the last key
    const key = keys[keys.length - 1];

    // Extract the URL from the key
    const url = key.substring("imageId:wadors:".length);

    // Use a regular expression to find the 'frames/{number}' segment
    const match = url.match(/frames\/(\d+)/);
    if (match && match[1]) {

      return parseInt(match[1]); // return the frame number as an integer
    } else {
      return null; // or handle the absence of 'frames' segment appropriately
    }
  }
   /* Abdouminal ***************************************************************************************************/
   export function changeAcValue (e,setReportContent,reportContent,chosenPlane,frameNumber){
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "MEAS__AC": e.target.value,
          },
        },
      },
    });
  };
  export function changeAGE_AC (e,setReportContent,reportContent,chosenPlane,frameNumber){
    // const age_ac = JSON.stringify(Math.floor(Math.random() * 100 * Number(e.target.value)));
    //setGA_AC(age_ac);
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "gestational_ages": {
              ...reportContent.report_content[chosenPlane][frameNumber]['gestational_ages'],
              ac: e.target.value,
            },
          },
        },
      },
    });
  };

  export function changeAbdoFetalKidneysCriteriaStatus (value, setReportContent, reportContent, chosenPlane, frameNumber){
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "QUAL_FLAG__abdo_fetal_kidneys": value,
          },
        },
      },
    });
  };

  export function changeAbdoPortalSinusCriteriaStatus (value,setReportContent,reportContent,chosenPlane,frameNumber){
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "QUAL_FLAG__abdo_portal_sinus": value,
          },
        },
      },
    });
  };

  export function changeAbdoStomackBubbleCriteriaStatus(value,setReportContent,reportContent,chosenPlane,frameNumber){
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "QUAL_FLAG__abdo_stomach_bubble": value,
          },
        },
      },
    });
  };

  export function changeStdPlaneAbdominalStatus (value,setReportContent,reportContent,chosenPlane,frameNumber) {
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "STD_PLANE__abdominal": value,
          },
        },
      },
    });
  };
  /* Abdouminal***************************************************************************************************************************/
/* Femur ********************************************************************************************************* */


export function changeStdPlaneFemurStatus (value,reportContent,chosenPlane,frameNumber,setReportContent) {
  const newReportContent = { ...reportContent };
  if (!newReportContent.report_content[chosenPlane]) {
    newReportContent.report_content[chosenPlane] = {};
  }
  // console.log("first newReportContent :: ",newReportContent)

  newReportContent.report_content[chosenPlane][frameNumber] = {
    ...(newReportContent.report_content[chosenPlane][frameNumber] || {}),
    "STD_PLANE__femur": value,
  };

  // console.log("last newReportContent :: ",newReportContent)
  setReportContent(newReportContent);
};


/* Femur ********************************************************************************************************* */
 /* Cephalic ******************************************************************************************************/

 export function changeHcValue (e, setReportContent, reportContent, chosenPlane, frameNumber){
  setReportContent({
    ...reportContent,
    report_content: {
      ...reportContent?.report_content,
      [chosenPlane]: {
        ...reportContent?.report_content[chosenPlane],
        [frameNumber]: {
          ...reportContent?.report_content[chosenPlane][frameNumber],
          "MEAS__HC": e.target.value,
        },
      },
    },
  });
};
export function changeAGE_BPD (e,setReportContent,reportContent,chosenPlane,frameNumber){
  setReportContent({
    ...reportContent,
    report_content: {
      ...reportContent.report_content,
      [chosenPlane]: {
        ...reportContent.report_content[chosenPlane],
        [frameNumber]: {
          ...reportContent.report_content[chosenPlane][frameNumber],
          "gestational_ages": {
            ...reportContent.report_content[chosenPlane][frameNumber]['gestational_ages'],
            // "BPD": JSON.stringify(Number(e.target.value)),
            "BPD": e.target.value,
          },
        },
      },
    },
  });
};

export function  changeStdPlaneCephalicStatus (value,setReportContent,reportContent,chosenPlane,frameNumber){
  setReportContent({
    ...reportContent,
    report_content: {
      ...reportContent.report_content,
      [chosenPlane]: {
        ...reportContent.report_content[chosenPlane],
        [frameNumber]: {
          ...reportContent.report_content[chosenPlane][frameNumber],
          "STD_PLANE__cephalic": value,
        },
      },
    },
  });
};

export function  changeAGE_HC (e,setReportContent,reportContent,chosenPlane,frameNumber){
  setReportContent({
    ...reportContent,
    report_content: {
      ...reportContent.report_content,
      [chosenPlane]: {
        ...reportContent.report_content[chosenPlane],
        [frameNumber]: {
          ...reportContent.report_content[chosenPlane][frameNumber],
          "gestational_ages": {
            ...reportContent.report_content[chosenPlane][frameNumber]['gestational_ages'],
            "hc": e.target.value,
            // "hc": JSON.stringify(Number(e.target.value)),
          },
        },
      },
    },
  });
};

/*******************************************************************************************************/
  /* AfPocket************************************************************************************************** */
  export function changeStdPlaneAfPocketStatus (value,setReportContent,reportContent,chosenPlane,frameNumber){
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "STD_PLANE__afpocket": value,
          },
        },
      },
    });
  };

  export function changeSdpValue (e,setReportContent,reportContent,chosenPlane,frameNumber){
    setReportContent({
      ...reportContent,
      report_content: {
        ...reportContent.report_content,
        [chosenPlane]: {
          ...reportContent.report_content[chosenPlane],
          [frameNumber]: {
            ...reportContent.report_content[chosenPlane][frameNumber],
            "MEAS__SDP": e.target.value,
          },
        },
      },
    });
  };
  /* AfPocket ****************************************************************************************************** */
 /*******************************************************************************************************/

 export function changeEFW(e, setEFW, setReportContent, reportContent, chosenPlane, frameNumber){
  console.log("whyyyy :: ", e)
  setEFW(JSON.stringify(Number(e.target.value)))
  setReportContent({
    ...reportContent,
    report_content: {
      ...reportContent.report_content,
      [chosenPlane]: {
        ...reportContent.report_content[chosenPlane],
        [frameNumber]: {
          ...reportContent.report_content[chosenPlane][frameNumber],
          'efw': JSON.stringify(Number(e.target.value)),
        }
      }
    }
  })
}
export function criteriaStatus(criteria, reportContent, chosenPlane, frameNumber){
  console.log("criteria ::", criteria)
  console.log("reportContent ::", reportContent?.report_content[chosenPlane])
  if (reportContent?.report_content[chosenPlane]) {
    return reportContent?.report_content[chosenPlane][frameNumber][criteria]
  } else {
    return false;
  }
}
