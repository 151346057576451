import { QueryKeyT, useFetch_old, usePost_old } from "../react-query";



export const useAddSRDicom = (
    updater?: (oldData: any[], newData: any) => any[],
    onSuccess?: (response: unknown, data: any) => void,
    onError?: (error: Error, data: any) => void) => usePost_old('instance', undefined, updater, onSuccess, onError);

export const useGetSrReport = (
    instance_uid: string,
    queryKey?: QueryKeyT
) => {
    console.log('useGetSrReport', instance_uid);
    // console.log('useGetSrReport', instance_uid);
    // if (instance_uid === undefined) {
    //     return {  };
    // }
    // if ( instance_uid?.length === 0) {
    //     return {  };
    // }
    return useFetch_old(`/instance_report/${instance_uid}`, undefined, { enabled: !!instance_uid }, queryKey);
};
