import React from 'react';

const RatingBar = ({ align, globalState }) => {
  // Get the score from globalState
  // const score = globalState?.planes[globalState?.selectedFilter?.toLowerCase()]?.score == -1 ? 0 : globalState?.planes[globalState?.selectedFilter?.toLowerCase()]?.score;
  const score = globalState?.ratingScore == -1 ? 0 : globalState?.ratingScore;
  // Calculate number of filled segments based on the score
  const totalSegments = 20;
  const filledSegments = score > 0 ? Math.max(1, Math.round((score / 100) * totalSegments)) : 0;

  // Determine color based on the score
  const getColor = score => {
    if (score < 2) {
      return 'bg-red-600';
    } 
    if (score < 25) {
      return 'bg-red-500';
    } // Red for scores < 25
    if (score < 50) {
      return 'bg-orange-500';
    } // Orange for scores < 50
    if (score < 75) {
      return 'bg-yellow-500';
    } // Yellow for scores < 75
    return 'bg-green-500'; // Green for scores < 100
  };

  const colorClass = getColor(score);


  return (
    <>
      {align === 'H' ? (
        <div className="flex flex-col items-center space-y-2">
          {/* Rating segments */}
          <div className="xl:space-x-0.7 lg:space-x-0.6 flex sm:space-x-0.5 md:space-x-0.5">
            {/* Filled segments */}
            {Array.from({ length: filledSegments }).map((_, idx) => (
              <div
                key={idx}
                className={`sm:h-6 sm:w-1 md:h-7 md:w-1.5 lg:h-7 lg:w-1.5 xl:h-8 xl:w-2 ${colorClass} rounded-full`}
              ></div>
            ))}

            {/* Empty segments */}
            {Array.from({ length: totalSegments - filledSegments }).map((_, idx) => (
              <div
                key={idx}
                className="rounded-full bg-[#e3eefb] sm:h-6 sm:w-1 md:h-7 md:w-1.5 lg:h-7 lg:w-1.5 xl:h-8 xl:w-2"
              ></div>
            ))}
          </div>

          {/* Labels below the graph */}
          <div className="flex w-full justify-between">
            <span className="font-normal text-[#A6ABC8] sm:pl-1 sm:text-xs md:text-sm lg:text-sm xl:text-lg">
              Bad
            </span>
            <span className="font-normal text-[#A6ABC8] sm:text-xs md:text-sm lg:text-sm xl:text-lg">
              Good
            </span>
          </div>
        </div>
      ) : (
        <div className="sm:space-y-0.9 flex h-full flex-col items-center justify-center space-y-1 md:space-y-1 lg:space-y-1">
          {/* Label on the top */}
          <span className="font-normal text-[#A6ABC8] sm:text-xs md:text-sm lg:text-sm xl:text-base">
            Good
          </span>

          {/* Rating segments */}
          <div className="md:space-y-0.6 lg:space-y-0.7 sm:space-y-0.8 flex flex-col-reverse space-y-reverse sm:space-y-0.5">
            {/* Filled segments */}
            {Array.from({ length: filledSegments }).map((_, idx) => (
              <div
                key={idx}
                className={`xs:h-1 sm:h-1 lg:h-1 xl:h-1.5 ${colorClass} rounded-full ${idx == 0 ? 'mt-0.5' : ''}`}
              ></div>
            ))}

            {/* Empty segments */}
            {Array.from({ length: totalSegments - filledSegments }).map((_, idx) => (
              <div
                key={idx}
                // className={`${idx==0?"mt-0.5":""} xl:w-6 xl:h-1.6 lg:w-6 lg:h-1.5 md:w-6 md:h-1.5 sm:w-6 sm:h-1.4 bg-[#e3eefb] rounded-full `}
                className={`${idx == 0 ? 'mt-0.5' : ''} rounded-full bg-[#e3eefb] sm:h-1 sm:w-6 md:h-1 md:w-6 lg:h-1 lg:w-6 xl:h-1.5 xl:w-6 `}
              ></div>
            ))}
          </div>

          {/* Label at the bottom */}
          <span className="font-normal text-[#A6ABC8] sm:text-xs md:text-sm lg:text-sm xl:text-base">
            Bad
          </span>
        </div>
      )}
    </>
  );
};

export default RatingBar;
