import { Switch } from 'antd';
import { tr } from 'date-fns/locale';
import React from 'react';

interface MeasurementBlockProps {
    label?: string;
    value?: string | boolean;
    icon?: React.ReactNode;
    head?: string;
    quality?: boolean;
}

const MeasurementBlock: React.FC<MeasurementBlockProps> = ({ label, value, icon, head, quality }) => {
    const isValidValue = (val: string | undefined| boolean) => {
        if (typeof val !== 'string')
            return true;
        const numberVal = Number(val?.split(' ')[0]);
        return !isNaN(numberVal) && numberVal > 0;
    };

    return (
        <>
            {head && <span className='font-semibold text-xs text-gray-500'>{head}</span>}
            <div
                className={`w-full space-x-2 flex items-center ${icon ? "" : "justify-between"} bg-blue-200 text-gray-700 h-8 rounded-lg ${head ? "" : "mt-2"} px-1 ${value !== undefined && isValidValue(value) ? "" : "animate-pulse"}`}
            >
                {icon && <div className='flex items-center space-x-1'>{icon}</div>}
                {label && <span className='font-semibold text-xs'>{label}</span>}
                {value && !quality && <span className='font-semibold text-xs'>{isValidValue(value) ? value : "--"}</span>}
                {quality === true && <Switch autoFocus={true} className='bg-gray-500' checked={value === undefined ? false : Boolean(value)} size='small' onChange={() => { console.log('bibib') }} />}
            </div>
        </>
    );
};

export default MeasurementBlock;
