import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Button, Form, Input, Modal, Tooltip } from 'antd';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { usePasswordReset, useSingleUser } from '../../../helpers/api/users/users';
import Settings from './Settings';

const style = {
  fontFamily: 'Outfit, sans-serif',
};

const ProfilePage = () => {
  const [active, setActive] = useState<string>('Profile');
  const params = useParams();
  const profile = useSingleUser(params.uid);
  const location = useLocation();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState([{ name: 'Profile', icon: 'ri-user-line', active: true }]);

  useEffect(() => {
    if (profile.data?.data?.is_me) {
      setTabs(prevTabs => {
        const updatedTabs = [...prevTabs];
        if (!updatedTabs.some(tab => tab.name === 'Settings')) {
          updatedTabs.push({ name: 'Settings', icon: 'ri-settings-3-line', active: false });
        }
        return updatedTabs;
      });

      const tabFromUrl = location.hash.replace('#', '');
      if (tabFromUrl === 'Settings') {
        setActiveTab('Settings');
      }
    }
  }, [profile.data?.data]);

  const setActiveTab = (name: string) => {
    setActive(name);
    setTabs(prev =>
      prev.map(tab => ({
        ...tab,
        active: tab.name === name,
      }))
    );

    if (name === 'Settings') {
      navigate(`#${name}`, { replace: true });
    } else {
      navigate('', { replace: true });
    }
  };

  if (profile.isLoading) {
    return <></>;
  }

  if (profile.isError) {
    return <>not found</>;
  }

  return (
    <div
      className="flex justify-center overflow-y-scroll"
      style={style}
    >
      <div className="w-3/4 rounded-lg border border-2 border-gray-200 p-6">
        <div className="mb-2 flex flex-col justify-center">
          <div className="mb-4 flex items-center justify-between border-b-2 border-gray-200 pb-6">
            <div className="flex items-center justify-start gap-2">
              <div className="group relative overflow-hidden rounded-full">
                <Avatar
                  size={80}
                  src={
                    <img
                      src={profile.data?.data?.avatar}
                      alt="avatar"
                    />
                  }
                />
                <div className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[rgba(0,0,0,0.5)] opacity-0 transition duration-150 ease-in-out group-hover:opacity-100">
                  <i className="ri-image-edit-line text-xl text-white"></i>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-2xl font-semibold text-gray-700">
                  {profile.data?.data?.name}
                </span>
                <span className="text-lg font-medium text-gray-500">
                  {profile.data?.data?.is_superuser && <Badge count={'ADMIN'} />}
                </span>
              </div>
            </div>
            <div className="flex cursor-pointer gap-1 text-lg">
              {!profile.data?.data?.is_active && <Badge count={'INACTIVE'} />}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <ul
              id="type-tab"
              className="-mb-px flex flex-wrap pl-8 text-center text-lg font-medium text-gray-500"
            >
              {tabs.map((tab, index) => (
                <li
                  className="mr-3"
                  key={index}
                >
                  <span
                    onClick={() => setActiveTab(tab.name)}
                    className={`group inline-flex cursor-pointer items-center justify-center border-b-2 p-2 ${
                      tab.active
                        ? 'border-blue-600 text-blue-600'
                        : 'border-transparent text-gray-700 hover:text-gray-600'
                    } transition duration-150 ease-in-out`}
                  >
                    <i className={`${tab.icon} mr-1 flex h-4 w-4 items-center text-lg`}></i>
                    {tab.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          {active === 'Profile' ? (
            <div className="mt-4 flex flex-col gap-4">
              <div className="mb-4 flex flex-col gap-2">
                <blockquote className="text-lg font-semibold italic text-gray-900">
                  <i className="ri-double-quotes-r text-[3rem] text-gray-400"></i>
                  <p>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt
                    volutpat velit vel dapibus. Curabitur feugiat nibh velit, ut dapibus dui
                    suscipit ut. In pretium ligula non quam rutrum, nec viverra ipsum varius. Nunc a
                    dui odio. Vivamus nec luctus ante, nec sodales lacus. Aenean scelerisque sit
                    amet dolor et pretium."
                  </p>
                </blockquote>
              </div>
              <div className="w-full rounded-lg border border-2 border-gray-200">
                <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
                  <div>
                    <span className="text-lg font-bold text-gray-700">Contact Information</span>
                  </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4 lg:grid-cols-3">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-phone-line mr-2" />
                      Contact Number
                    </span>
                    <span className="text-base font-medium text-gray-500">06 97 16 05 30</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-mail-line mr-2" />
                      Email
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      {profile.data?.data?.email}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-map-pin-line mr-2" />
                      Address
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      123 Avenue Mohammed V, Quartier des Habous, Casablanca 20000, Morocco
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full rounded-lg border border-2 border-gray-200">
                <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
                  <div>
                    <span className="text-lg font-bold text-gray-700">Schedule & Availability</span>
                  </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4 lg:grid-cols-3">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-calendar-line mr-2" />
                      Office Hours
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      Mon-Fri: 9:00 AM - 5:00 PM
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-time-line mr-2" />
                      Availability
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      Available for consultations by appointment
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-building-line mr-2" />
                      Location
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      -Office: 5th Floor, Building B
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      -Clinic: 2nd Floor, Block A
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {active === 'Settings' ? <Settings /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
