import { apiClient } from '../../apiHelper';
import { useCreate, useEdit, usePaginated, useSingleFetch, useFetch_old, useFetch, usePost } from '../react-query';
import { examsQueryKeys } from './studies-query-keys';
import * as url from '../../urlHelper';



interface Filters {
  [key: string]: string | object;
}

interface PaginatedResponse {
  data: any; 
}

interface ExamResponse {
  data: any; 
}

export function usePaginatedExams(
  page: number,
  itemsPerPage: number,
  filters: Filters = {},
  config = {}
) {
  const getPaginatedExamsFn = async (p: number = page, f: Filters = filters): Promise<PaginatedResponse> => {
    const processedFilters = Object.entries(f).reduce<Record<string, string>>((acc, [key, value]) => {
      acc[key] = typeof value === 'string' ? value : JSON.stringify(value);
      return acc;
    }, {});

    const filtersQuery = new URLSearchParams(processedFilters).toString();
    const url = `studies?page=${p}&items_per_page=${itemsPerPage}${filtersQuery ? `&${filtersQuery}` : ''}`;

    const response = await apiClient.get<PaginatedResponse>(url);
    return response.data;
  };

  return usePaginated(getPaginatedExamsFn, examsQueryKeys.pagination(page), page, config);
}

export function useArchiveExams() {
  const archiveExamsFn = async (exam_ids: string[] | number[]): Promise<ExamResponse> => {
    const response = await apiClient.post<ExamResponse>(`/studies/archive`, exam_ids);
    return response;
  };

  return useEdit(
    archiveExamsFn,
    examsQueryKeys.all,
    'Error while archiving exam',
    'Exam archived successfully'
  );
}


export function useArchiveExam() {
  const archiveExamFn = async (exam_id: string | number): Promise<ExamResponse> => {
    const response = await apiClient.post<ExamResponse>(`/studies/archive/${exam_id}`);
    return response;
  };

  return useEdit(
    archiveExamFn,
    ['exams', 'pagination'],
    'Error while archiving exam',
    'Exam archived successfully'
  );
}

export function useUnarchiveExams() {
  const unarchiveExamsFn = async (exam_ids: string[] | number[]): Promise<ExamResponse> => {
    const response = await apiClient.post<ExamResponse>(`/studies/unarchive`, exam_ids);
    return response;
  };

  return useEdit(
    unarchiveExamsFn,
    examsQueryKeys.all,
    'Error while unarchiving exam',
    'Exam unarchived successfully'
  );
}

export function useUnarchiveExam() {
  const unarchiveExamFn = async (exam_id: string | number): Promise<ExamResponse> => {
    const response = await apiClient.post<ExamResponse>(`/studies/unarchive/${exam_id}`);
    return response;
  };

  return useEdit(
    unarchiveExamFn,
    ['exams', 'pagination'],
    'Error while unarchiving exam',
    'Exam unarchived successfully'
  );
}



export function useRecentStudies() {
  return useFetch(
    async () => {
      const response = await apiClient.get('/recent-studies');
      return response;
    },
    ['studies', 'recent'],
    {
      refetchInterval: 60000 // Refetch every minute
    }
  );
}




export const useUploadStudy = (
  updater: (oldData: any[], newData: any) => any[],
  onSuccess?: (response: any, data: any) => void,
  onError?: (error: any, data: any) => void
) => {
  return usePost<any[], any>(
    url.STUDY_ADD, 
    undefined,
    updater,  
    onSuccess,
    onError
  );
};


interface PredictParams {
  study_uid: string;
  series_instance_uid: string;
  sop_instance_uid: string;
}

export function usePredict() {
  const predictFn = async (params: PredictParams) => {
    const response = await apiClient.post(
      `/model_api/predict?studyUID=${params.study_uid}&study_uid=${params.study_uid}&series_instance_uid=${params.series_instance_uid}&sop_instance_uid=${params.sop_instance_uid}`,
      {}
    );
    return response.data;
  };

  return useEdit(
    predictFn,
    ['studies', 'predict'],
    'Error predicting study',
    'Prediction started successfully'
  );
}

export function useSRStatus(seriesInstanceUID: string, sopInstanceUID: string) {
  return useFetch(
    async () => {
      const response = await apiClient.get(
        `/studies/sr-status/${seriesInstanceUID}/${sopInstanceUID}`
      );
      return response.data;
    },
    ['studies', 'sr-status', seriesInstanceUID, sopInstanceUID],
    {
      refetchInterval: (data) => {
        // Refetch every 2 seconds if status is 'INPROGRESS'
        return data?.status === 'INPROGRESS' ? 2000 : false;
      }
    }
  );
}














interface measurementsKeys {
  studyId: string;
  serieId: string;
  instanceId: string;
}

export const getStudyMeasurements = (search: measurementsKeys) =>
  useFetch_old(
    `${url.GET_STUDIES}/${search.studyId}/series/${search.serieId}/instances/${search.instanceId}`
  );