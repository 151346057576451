import React, { useEffect,useState } from 'react'
import cephalLogo from '../../../../assets/svgs/brain-line.png';
import abdominalLogo from '../../../../assets/svgs/stomach.png';
import femurLogo from '../../../../assets/svgs/bone.png';
import { Button } from 'antd';
import ProgressPlanes from '../../../SidePanel/component/progressPlanes';

interface ReportPlaneStateProps {
    showModal: () => void;
    studyReport?: any;
}

function ReportPlaneState({ showModal,studyReport }: ReportPlaneStateProps) {
    const [disabled, setDisabled] = useState(true);
    const [nonEmptyKeys, setNonEmptyKeys] = useState([]);
    const [AbdominalPercent, setAbdominalPercent] = useState(0);
    const [CephalPercent, setCephalPercent] = useState(0);
    const [FemurPercent, setFemurPercent] = useState(0);
    const [AfPocketPercent, setAfPocketPercent] = useState(0);
    const checkReportContent = () => {
        if (!studyReport?.report_content) {
            console.log("==================== studyReport undefined");
            setNonEmptyKeys([]);
            return;
        }
        const keysWithData = Object.keys(studyReport.report_content).filter(key => Object.keys(studyReport.report_content[key]).length > 0);
        setNonEmptyKeys(keysWithData);
      
        setDisabled(keysWithData.length === 0);
        for (const key of keysWithData) {
            const keys = Object.keys(studyReport?.report_content[key]);
            const planeData = studyReport?.report_content?.[key]?.[keys[0]];
            if(key == 'abdominal') {
                setAbdominalPercent(Number(planeData?.QUAL_VAL__ZOOM?.toFixed(2) > 50) + Number(!planeData?.QUAL_VAL__VISIBILITY?.absent?.fetal_kidneys_segment_polygon) + Number(planeData?.QUAL_VAL__VISIBILITY?.visible?.portal_sinus_segment) + Number(planeData?.QUAL_VAL__VISIBILITY?.visible?.stomach_bubble_segment))
            } else if(key == 'cephalic') {
                setCephalPercent(Number(planeData?.QUAL_VAL__ZOOM?.toFixed(2) > 50) + Number(!planeData?.QUAL_VAL__VISIBILITY?.absent?.cerebellum_segment_polygon) + Number(planeData?.QUAL_VAL__VISIBILITY?.visible?.cavum_septum_pellucidum_segment_polygon) + Number(planeData?.QUAL_VAL__VISIBILITY?.visible?.thalamus_segment))

            } else if (key == 'afpocket') {
                setAfPocketPercent(4)
            }
            else if (key == 'femur') {
                setFemurPercent(Number(planeData?.QUAL_VAL__ZOOM?.toFixed(2) > 50) + Number(planeData?.QUAL_VAL__ANGLE < 45) + Number(planeData?.QUAL_VAL__VISIBILITY?.visible?.femur_extremities_box_segment))
            }

        }
      };
    useEffect(() => {
        checkReportContent();
    }, [studyReport])
    return (
        <div>
            <div className="text-sm text-[#a6abc8]">Report Planes State</div>
            <div className="grid w-full grid-cols-2 gap-2">
                <ProgressPlanes
                    plane={'Cephal'}
                    isComplete={CephalPercent === 4}
                    percent={CephalPercent * 25}
                    logo={cephalLogo}
                />
                <ProgressPlanes
                    plane={'Femur'}
                    isComplete={FemurPercent === 3}
                    percent={FemurPercent * (100 / 3)}
                    logo={femurLogo}
                />
                <ProgressPlanes
                    plane={'Abdominal'}
                    isComplete={AbdominalPercent === 4}
                    percent={AbdominalPercent * 25}
                    logo={abdominalLogo}
                />
                <ProgressPlanes
                    plane={'AF-Pocket'}
                    isComplete={AfPocketPercent === 4}
                    percent={AfPocketPercent * 25}
                    logo={cephalLogo}
                />
            </div>
            <Button className='text-primary-active bg-blue-200 text-xs font-semibold w-full border border-blue-200' onClick={showModal} disabled={disabled} >
                View report
            </Button>
        </div>
    )
}

export default ReportPlaneState
