import React from 'react';
import { getPaginationItems } from './table_utils';
import { flexRender } from '@tanstack/react-table';
import { Skeleton } from 'antd';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';

const Table = ({ table, totalCount, isLoading, pageSize, columnsLength }) => {
  return (
    <>
      <table
        id="role-table"
        className="mb-2 w-full"
      >
        <thead className="h-10 border-t border-dashed border-gray-200 bg-gray-100 p-2 text-left text-gray-500">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    className="p-2"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? (
            Array.from({ length: pageSize }).map((_, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {Array.from({ length: columnsLength }).map((_, colIndex) => {
                    return (
                      <td key={colIndex}>
                        <div className="p-2">
                          <Skeleton
                            active
                            title={false}
                            paragraph={{ rows: 1, width: '100%', height: "100%" }}
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <>
              {totalCount === 0 ? (
                <tr>
                  <td
                    colSpan={table.getHeaderGroups()[0]?.headers.length}
                    className="p-4 text-center"
                  >
                    <div className="flex flex-col items-center justify-center text-gray-500">
                      <i className="ri-file-line mb-2 text-4xl"></i>
                      <span>No data available</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {table.getRowModel().rows.map(row => {
                    return (
                      <tr
                        id={`table-row-${row.id}`}
                        key={row.id}
                        className="h-10 border-t border-b border-gray-200 hover:bg-blue-100"
                      >
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td
                              key={cell.id}
                              className="p-2"
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
      <div className="flex w-full absolute bottom-2 items-center justify-between px-2">
        <div className="text-gray-600">
          Showing{' '}
          <span className="text-gray-700">{table.getRowModel().rows.length.toLocaleString()}</span>{' '}
          of <span className="text-gray-700">{totalCount}</span> Rows
        </div>
        <div
          id="pagination"
          className="flex items-center gap-4 px-3"
        >
          <button
            className={`cursor-pointer ${
              !table.getCanPreviousPage() ? 'text-gray-500' : 'text-blue-600'
            }`}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <FiArrowLeftCircle size={24}/>
          </button>

          {getPaginationItems(table.getState().pagination.pageIndex + 1, table.getPageCount()).map(
            (item, key) => (
              <React.Fragment key={key}>
                {item === '...' ? (
                  <button
                    className={`rounded py-1 px-1`}
                    disabled
                  >
                    ...
                  </button>
                ) : (
                  <button
                    className={`rounded border  px-2 transition duration-150 ease-in-out ${
                      table.getState().pagination.pageIndex === item - 1
                        ? 'border-blue-600 bg-blue-600 text-white'
                        : 'text-blue-900  hover:bg-gray-100'
                    }`}
                    onClick={() => table.setPageIndex(item - 1)}
                    disabled={table.getState().pagination.pageIndex === item - 1}
                  >
                    {item}
                  </button>
                )}
              </React.Fragment>
            )
          )}
          <button
            className={`cursor-pointer ${
              !table.getCanNextPage() ? 'text-gray-500' : 'text-blue-600'
            }`}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <FiArrowRightCircle size={24}/>

          </button>
        </div>
      </div>
    </>
  );
};

export default Table;
