type QueryKey = string | number;




interface Filters {
  [key: string]: string | number | undefined;
}

interface PatientsQueryKeys {
  all: QueryKey[];
  recent: () => QueryKey[];
  details: () => QueryKey[];
  detail: (id: string | number) => QueryKey[];
  pagination: (page: number, filters?: Filters) => QueryKey[];
  infinite: () => QueryKey[];
  permissions: (patient_id: string | number, user_uuid: string) => QueryKey[];
}

export const patientsQueryKeys: PatientsQueryKeys = {
  all: ['patients'],
  recent: () => [...patientsQueryKeys.all, 'recent'],
  details: () => [...patientsQueryKeys.all, 'detail'],
  detail: (id) => [...patientsQueryKeys.details(), id],
  pagination: (page, filters = {}) => {
    const filterKey = Object.entries(filters)
      .filter(([, value]) => value !== undefined && value !== '')
      .map(([key, value]) => `${key}:${value}`)
      .sort()
      .join('|');
    return [...patientsQueryKeys.all, 'pagination', page.toString(), filterKey];
  },
  infinite: () => [...patientsQueryKeys.all, 'infinite'],
  permissions: (patient_id, user_uuid) => [
    ...patientsQueryKeys.all,
    'permissions',
    patient_id,
    user_uuid,
  ],
};