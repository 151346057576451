import React, { useEffect, useState } from 'react';
import Slider from '../Slider/Slider';
import cephalic from '../../assets/cephalic.png';
import abdominal from '../../assets/abdominal.png';
import afpocket from '../../assets/afpocket.png';
import femur from '../../assets/femur.png';
import FramePagination from '../FramePagination/FramePagination';
import { getSOPInstanceAttributes } from '../../../../../../../extensions/cornerstone/src/utils/measurementServiceMappings/utils';
import PlaneInformation from '../PlaneInformation/PlaneInformation';
import { SlScreenDesktop } from 'react-icons/sl';
import { PiDesktopThin } from 'react-icons/pi';
import classNames from 'classnames';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface PaginationStateType {
    pagination: {
        current: number;
        last: number;
    };
}
interface MeasurementDeepEchoModeProps {
    frameByPlane: any;
    data: any;
    measurementService: any;
    activeViewportIndex: number;
    onImageScrollbarChange: any;
    onClick: any;
    drawingMode: boolean;
    mode?: boolean;
    changeDrawingMode: () => void;
    SetFrame?: (num: number) => void;
    setPlane?: (plane: string) => void;
    isLoading?: boolean;
    isError?: boolean;
    setSerieId?: (id: string) => void;
    activeSOPInstanceUID?: string;
    displaySet: any;

}

function MeasurementDeepEchoMode({ frameByPlane, data, measurementService, activeViewportIndex, onImageScrollbarChange, onClick, drawingMode, mode, changeDrawingMode, setPlane, SetFrame, isLoading, isError, setSerieId, activeSOPInstanceUID, displaySet }: MeasurementDeepEchoModeProps) {
    const [paginationState, setPaginationState] = useState<PaginationStateType>({
        pagination: { current: 1, last: 1 },
    });
    const [planes, setPlanes] = useState<string[]>([]);
    const [keysByPlane, setKeysByPlane] = useState<{ [key: string]: string[] }>({});
    const [keysSelectedPlane, setKeysSelectedPlane] = useState<string[]>([]);
    const [startPlane, setStartPlane] = useState(0);
    const [selectedKey, setSelectedKey] = useState('');
    const [FrameId, setFrameId] = useState<string | undefined>();
    const [clicked, setClicked] = useState(false);
    const [page, setPage] = useState<number | null>(null);
    const [PlaneInfo, setPlaneInfo] = useState<any | null>(null);
    const [selectedPlane, setSelectedPlane] = useState<string | null>(null);
    const [selectedFrame, setSelectedFrame] = useState<number | null>(null);
    const [seriesIds, setSeriesIds] = useState<string[]>([]);
    const [selectedSerieId, setSelectedSerieId] = useState<string>(activeSOPInstanceUID || seriesIds[0]);
    const [loadingSeries, setLoadingSeries] = useState<boolean>(false);
    const imageMapping = {
        cephalic,
        abdominal,
        afpocket,
        femur,
    };

    // useEffect(() => {
    //     if (activeSOPInstanceUID) {
    //         setSelectedSerieId(activeSOPInstanceUID);
    //     }
    // }, [activeSOPInstanceUID]);

    useEffect(() => {
        const activeSerieId = displaySet?.instance?.SOPInstanceUID;
        const seriesKeys = Object.keys(frameByPlane);
        if (seriesKeys.includes(activeSerieId)) {
            setSelectedSerieId(activeSerieId);
        }
    }, [displaySet]);
    useEffect(() => {
        if (mode) {
            const seriesKeys = Object.keys(frameByPlane);
            const activeSerieId = displaySet?.instance?.SOPInstanceUID;
            if (seriesKeys.includes(activeSerieId)) {
                setSelectedSerieId(activeSerieId);
            }
        }
    }, [mode]);
    useEffect(() => {
        const seriesKeys = Object.keys(frameByPlane);
        // if (!seriesKeys.includes(selectedSerieId)) {
        //     setSelectedSerieId(seriesKeys[0]);
        //     return;
        // }

        setSeriesIds(seriesKeys);

        if (frameByPlane == undefined || seriesKeys.length == 0) return;

        const planeKeys = Object.keys(frameByPlane[selectedSerieId]).filter(
            (plane) => Object.keys(frameByPlane[selectedSerieId][plane])?.length > 0 && ['cephalic', 'abdominal', 'afpocket', 'femur'].includes(plane)
        );

        const keysByPlan = planeKeys.reduce((acc, plane) => {
            const keys = Object.keys(frameByPlane[selectedSerieId][plane]);
            if (keys?.length > 0) {
                acc[plane] = keys;
            }
            return acc;
        }, {});

        setKeysByPlane(keysByPlan);
        setPlanes(planeKeys);
        if (planeKeys?.length > 0) {
            const measurements = measurementService.getMeasurements();
            const lastMeasurement = measurements.find(
                (measurement) => measurement?.selected === true
            );
            const imageId = getSOPInstanceAttributes(
                lastMeasurement?.metadata?.referencedImageId
            )?.frameNumber;
            setFrameId(imageId);
            // must use find instead of filter
            let selectedData = data?.filter(
                (data) => data?.label !== undefined && data?.isActive === true && data?.label.includes('plane')
            )[0];
            console.log('**selectedData**', selectedData);
            if (selectedData === undefined) {
                selectedData = data?.filter(
                    (data) => data?.label !== undefined && data?.label.includes('plane')
                )[0];
            }
            // if (selectedData === undefined)
            //     return;
            const selectedPlane = selectedData?.label.split('_')[1];
            setSelectedPlane(selectedPlane);
            setPlane(selectedPlane);
            const selectedPlaneKeys = keysByPlan[selectedPlane] || [];
            if (selectedPlaneKeys?.length === 0) {
                return;
            }
            console.log('**frameByPlane**', frameByPlane, selectedSerieId, planeKeys, keysByPlan);
            const lastChunk = selectedPlaneKeys[selectedPlaneKeys?.length - 1];
            const lastKey = lastChunk[lastChunk?.length - 1];
            setSelectedKey(lastKey);
            setStartPlane(planeKeys.indexOf(selectedPlane));
            setKeysSelectedPlane(selectedPlaneKeys);

            const currentPage = selectedPlaneKeys.indexOf(String(imageId)) + 1;
            setPlaneInfo(frameByPlane[selectedSerieId][selectedPlane][selectedPlaneKeys[currentPage - 1]]);

            setSelectedFrame(selectedPlaneKeys[currentPage - 1]);
            SetFrame(Number(selectedPlaneKeys[currentPage - 1]));
            setPaginationState({
                pagination: {
                    current: currentPage,
                    last: selectedPlaneKeys?.length,
                },
            });
            setPage(currentPage);

        }
    }, [frameByPlane, data, measurementService, mode, selectedSerieId, displaySet]);

    const changeStartPlane = (index: number) => {
        setStartPlane(index);
        const selectedPlane = planes[index];
        setSelectedPlane(selectedPlane);
        setPlane(selectedPlane);
        const selectedPlaneKeys = keysByPlane[selectedPlane] || [];
        setKeysSelectedPlane(selectedPlaneKeys);
        setPaginationState({
            pagination: {
                current: 1,
                last: selectedPlaneKeys?.length,
            },
        });
        setPlaneInfo(frameByPlane[selectedSerieId][selectedPlane][selectedPlaneKeys[0]]);
        setSelectedFrame(Number(selectedPlaneKeys[0]));
        setSelectedSerieId(selectedSerieId);
        setSerieId(selectedSerieId);
        SetFrame(Number(selectedPlaneKeys[0]));
        const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, selectedPlaneKeys[0], selectedSerieId);
        onClick({ uid: measurementId, isActive: false });
        onImageScrollbarChange(Number(selectedPlaneKeys[0]) - 1, activeViewportIndex);
    };

    const handleGotoPage = (page: number) => {
        const imageIndex = keysSelectedPlane[page - 1] ? Number(keysSelectedPlane[page - 1]) - 1 : 0;
        console.log('frameByPlane', frameByPlane, selectedSerieId, planes, startPlane, keysSelectedPlane, page);
        setPlaneInfo(frameByPlane[selectedSerieId][planes[startPlane]][keysSelectedPlane[page - 1]])
        setSelectedFrame(Number(keysSelectedPlane[page - 1]));
        SetFrame(Number(keysSelectedPlane[page - 1]));

        setPaginationState((prev) => ({
            pagination: {
                current: page,
                last: keysSelectedPlane?.length,
            },
        }));
        const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(planes[startPlane], keysSelectedPlane[page - 1], selectedSerieId)
        setSelectedSerieId(selectedSerieId);
        setSerieId(selectedSerieId);
        onClick({ uid: measurementId, isActive: false });
        setPage(page);
        onImageScrollbarChange(imageIndex, activeViewportIndex);
    };


    const handleChangeSerieId = (index: string) => {
        setLoadingSeries(true);
        setSelectedSerieId(index);
        setSerieId(index);

        const selectedSeriesId = index;
        const seriesFrames = frameByPlane[selectedSeriesId];

        const planeKeys = Object.keys(seriesFrames).filter(
            (plane) => Object.keys(seriesFrames[plane])?.length > 0 && ['cephalic', 'abdominal', 'afpocket', 'femur'].includes(plane)
        );

        setPlanes(planeKeys);

        const updatedKeysByPlane = planeKeys.reduce((acc, plane) => {
            const keys = Object.keys(seriesFrames[plane]);
            if (keys?.length > 0) {
                acc[plane] = keys;
            }
            return acc;
        }, {});

        setKeysByPlane(updatedKeysByPlane);

        // Set default plane and frame for the new series
        const defaultPlane = planeKeys[startPlane] || planeKeys[0];
        setSelectedPlane(defaultPlane);
        setPlane(defaultPlane);

        const selectedPlaneKeys = updatedKeysByPlane[defaultPlane] || [];
        if (selectedPlaneKeys.length > 0) {
            const defaultFrame = Number(selectedPlaneKeys[0]);
            const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(defaultPlane, selectedPlaneKeys[0], selectedSeriesId);
            onClick({ uid: measurementId, isActive: false });
            onImageScrollbarChange(defaultFrame - 1, activeViewportIndex);
            measurementService.jumpToMeasurement(activeViewportIndex, measurementId);
            setSelectedFrame(defaultFrame);
            SetFrame(defaultFrame);

            const frameInfo = seriesFrames[defaultPlane][selectedPlaneKeys[0]];
            setPlaneInfo(frameInfo);


            setPaginationState({
                pagination: {
                    current: 1,
                    last: selectedPlaneKeys.length,
                },
            });
        }
        setLoadingSeries(false);
    };
    function handleDeleteMeasurement() {
        const selectedPlaneKeys = keysByPlane[selectedPlane] || [];
        const currentIndex = selectedPlaneKeys.indexOf(String(selectedFrame));
        const previousIndex = currentIndex - 1;
        const previousFrame = selectedPlaneKeys[previousIndex];

        const updateToNextFrame = (nextFrameIndex) => {
            const nextFrame = selectedPlaneKeys[nextFrameIndex];
            setSelectedFrame(Number(nextFrame));
            setSelectedKey(nextFrame);
            setPlaneInfo(frameByPlane[selectedSerieId][selectedPlane][nextFrame]);
            setPaginationState({
                pagination: {
                    current: nextFrameIndex + 1,
                    last: selectedPlaneKeys.length,
                },
            });

            const nextMeasurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, nextFrame, selectedSerieId);
            const currentMeasurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, selectedFrame, selectedSerieId);

            measurementService.remove(currentMeasurementId);
            measurementService.jumpToMeasurement(activeViewportIndex, nextMeasurementId);
        };

        const handleSinglePlaneDeletion = () => {
            const currentSerieId = selectedSerieId;
            const previousSerieId = seriesIds[seriesIds.indexOf(currentSerieId) - 1];
            console.log('currentSerieId', currentSerieId, "previousSerieId",previousSerieId);
            if (previousSerieId?.length > 0) {
                setSelectedSerieId(previousSerieId);

                const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, selectedFrame, currentSerieId);
                measurementService.remove(measurementId);
                measurementService.removeSerieFromReport(currentSerieId);
                return;
            } 
            if(previousSerieId == undefined){ 
                setSelectedSerieId(seriesIds[seriesIds.indexOf(currentSerieId) + 1]);
                const nextSerieId = seriesIds[seriesIds.indexOf(currentSerieId) + 1];
                const nextPlane = planes[0];
                const nextFrame = keysByPlane[nextPlane][0];
                if (nextSerieId !== undefined) {
                    setSelectedPlane(nextPlane);
                    setSelectedFrame(Number(nextFrame));
                    setPlaneInfo(frameByPlane[nextSerieId][nextPlane][nextFrame]);
                    setPaginationState({
                        pagination: {
                            current: 1,
                            last: keysByPlane[nextPlane].length,
                        },
                    });
                }

                const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, selectedFrame, currentSerieId);
                measurementService.remove(measurementId);
                measurementService.removeSerieFromReport(currentSerieId);
                return;
            }
            
            
            const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, selectedFrame, currentSerieId);

            measurementService.remove(measurementId);
            measurementService.removeSerieFromReport(currentSerieId);
        };

        if (previousFrame === undefined) {
            if (selectedPlaneKeys.length > 1) {
                updateToNextFrame(currentIndex + 1);
                return;
            }

            if (planes.length === 1) {
                handleSinglePlaneDeletion();
                return;
            }
        }

        const currentMeasurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, selectedFrame, selectedSerieId);
        const previousMeasurementId = measurementService.getMeasurementIdByLabelandFrameNumber(selectedPlane, previousFrame, selectedSerieId);

        measurementService.remove(currentMeasurementId);
        measurementService.jumpToMeasurement(activeViewportIndex, previousMeasurementId);
    }


    if (isLoading) {
        return <div className="text-center text-gray-500">Loading data...</div>;
    }

    if (isError) {
        return <div className="text-center text-red-500">error</div>;
    }

    return (
        <div className="w-full">
            {loadingSeries && planes.length <= 0 && selectedKey != null ? (
                <div className="text-center text-gray-500 flex flex-col items-center justify-center w-full mt-12">
                    <PiDesktopThin size={90} className='bg-blue-200 rounded-full p-2 text-white mb-2' />
                    <span className='text-[#1F384C] text-sm font-semibold text-center'>
                        No plan detected.
                    </span>
                    <span className='text-gray-400 text-xs'>
                        No plan detected. Please create or select a plan to proceed.
                    </span>
                </div>
            ) : (
                <>
                    <div>
                        {seriesIds.length > 1 &&
                            <div className='flex space-x-2 justify-center mb-3'>
                                {(
                                    <>
                                        {seriesIds.map((seriesId, index) => (
                                            <div
                                                key={index}
                                                className={classNames(
                                                    'group flex cursor-pointer transition duration-300 rounded ',
                                                    {
                                                        'overflow-hidden border-primary-light': selectedSerieId === seriesId,
                                                    }
                                                )}
                                                role="button"
                                            >
                                                <div
                                                    className={classNames(
                                                        'text-center w-6 py-1 text-base transition duration-300 rounded',
                                                        {
                                                            'bg-blue-600 hover:bg-blue-500 text-white': selectedSerieId === seriesId,
                                                            'bg-white text-blue-600 hover:bg-blue-100 border border-blue-300': selectedSerieId !== seriesId,
                                                        }
                                                    )}
                                                    onClick={() => handleChangeSerieId(seriesId)}
                                                >
                                                    {index + 1}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>}
                        <Slider slides={planes} visibleItemsNumber={1} start={startPlane} setStart={changeStartPlane}>
                            {(slide) => (
                                <div className="flex items-center flex-col">
                                    <img src={imageMapping[slide]} alt={slide} className="w-24 h-24 object-contain" draggable="true" />
                                    <span className="text-[#1F384C] font-bold text-lg mt-2">
                                        {slide.charAt(0).toUpperCase() + slide.slice(1)}
                                    </span>
                                </div>
                            )}
                        </Slider>
                    </div>
                    <div className="mt-2">
                        <FramePagination
                            current={paginationState.pagination.current}
                            last={paginationState.pagination.last}
                            gotoPage={handleGotoPage}
                            keysSelectedPlane={keysSelectedPlane}
                        />
                    </div>
                    {drawingMode &&  <div className=' w-full flex items-center justify-center'>
                        <button className='text-xs bg-white rounded-lg font-semibold border border-red-500 text-red-500 hover:border-red-500 hover:text-white hover:bg-red-500 flex items-center justify-between px-2 py-1 space-x-3 ' onClick={handleDeleteMeasurement}>
                            <span> Delete Plan</span>
                            <DeleteOutlined />
                        </button>
                    </div>}
                    <div>
                        {['abdominal', 'afpocket', 'femur', 'cephalic'].includes(selectedPlane) && (
                            <PlaneInformation
                                data={data}
                                planeInfo={PlaneInfo}
                                selectedPlane={selectedPlane as 'abdominal' | 'cephalic' | 'femur' | 'afpocket'}
                                selectedFrame={selectedFrame}
                                drawingMode={drawingMode}
                            />
                        )}
                    </div>
                    
                </>
            )}
        </div>
    );
}

export default MeasurementDeepEchoMode;
