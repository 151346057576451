import React, { useEffect } from 'react'
import MeasurementDeepEchoMode from '../MeasurementDeepEchoMode/MeasurementDeepEchoMode'
import MeasurementOhifMode from '../MeasurementOhifMode/MeasurementOhifMode'
import { useState } from 'react'
import { Button, Switch } from 'antd';
import { Switcher } from '../switcher/switcher';
import MeasurementModeHeader from '../MeasurementModeHeader/MeasurementModeHeader';
import MeasurementModeFooter from '../MeasurementModeFooter/MeasurementModeFooter';
import { useDrawingMode } from '../../../../contextProviders/DrawingModeProvider';
import ReportPlaneState from '../ReportPlaneState/ReportPlaneState';
// import { useDrawingMode } from 'platform/ui/src/contextProviders';
import { useQueryClient } from '@tanstack/react-query';
import { annotation } from '@cornerstonejs/tools';

interface DrawingModeProps {
    data: any;
    frameByPlane: any;
    measurementService: any;
    displaySetService: any;
    activeViewportIndex: number;
    onImageScrollbarChange: any;
    onClick: any;
    unlabeledMeasurements: any;
    displaySet: any;
    servicesManager: any;
    handleCancleButtonClick: any;
    createReport?: () => void;
    studyReport?: any;
    toolbarService?: any;
    showModal?: () => void;
    activeDisplaySetInstanceUID?: string;
    activeSOPInstanceUID?: string;
}

function DrawingMode({ data, frameByPlane, measurementService, activeViewportIndex, onImageScrollbarChange, onClick, unlabeledMeasurements, displaySet, servicesManager, handleCancleButtonClick, createReport, studyReport, toolbarService, showModal, displaySetService, activeDisplaySetInstanceUID, activeSOPInstanceUID }: DrawingModeProps) {
    const [drawingMode, setDrawingMode] = useState<boolean>(true)
    const [mode, setMode] = useState<boolean>(measurementService.getDeepEchoReportMode());
    const { getState, setDrawingState } = useDrawingMode()
    const [frame, setFrame] = useState<number | null>(null);
    const [Plane, setPlane] = useState<string | null>(null);
    const [SerieId, setSerieId] = useState<string | null>('');
    const queryClient = useQueryClient();

    useEffect(() => {
        if (mode == false && getState()?.drawingState == true)
            setDrawingState(false)
        return () => {
            setDrawingState(true);
            displaySetService.setActivesSRSerie(false)
        }
    }, [])

    useEffect(() => {
        const allAnnotations = annotation.state.getAllAnnotations()
        if (mode == false) {
            for (let i = 0; i < allAnnotations.length; i++) {
                //measurementService.coloringMeasurements()
                annotation.locking.setAnnotationLocked(allAnnotations[i], true)
            }
        } else {
            annotation.locking.unlockAllAnnotations()
            //measurementService.coloringMeasurements()

        }
    }, [mode])

    useEffect(() => {
        return () => {
            queryClient.removeQueries({ queryKey: ['report', 'globalReportData'] });
            queryClient.removeQueries({ queryKey: ['report', 'serieReport'] });
            queryClient.cancelQueries({ queryKey: ['report', 'serieReport'] });
        };
    }, [queryClient]);
    function changeDrawingMode() {
        setDrawingMode(!drawingMode)
    }
    function changeMode() {
        setMode(!mode)
        measurementService.setDeepEchoReportMode(!mode)
        const drawing = getState()?.drawingState
        setDrawingState(!drawing)
        toolbarService.recordInteraction({
            groupId: 'WindowLevel',
            itemId: 'WindowLevel',
            interactionType: 'tool',
            commands: [
                {
                    commandName: 'setToolActive',
                    commandOptions: {
                        toolName: 'WindowLevel',
                    },
                    context: 'CORNERSTONE',
                },
            ],
        });
    }
    return (
        <div className='w-full h-full'>
            <div className="text-[#A6ABC8] flex items-center space-x-2 w-full justify-between mb-3 h-5">
                <span className='text-lg font-semibold'>Drawing mode</span>
                {/* <Switch defaultChecked size='small' onChange={() => { handleCancleButtonClick(displaySet, servicesManager) }} /> */}
                <Switch defaultValue={mode} size='small' className='bg-gray-500' onChange={() => { changeMode() }} />
            </div>
            <div className='w-full h-[calc(100%-32px)] flex justify-between flex-col'>
                <div className='h-[95%]'>
                    <ReportPlaneState showModal={showModal} studyReport={studyReport} />
                    <MeasurementModeHeader drawingMode={drawingMode} predictMOde={mode} changeDrawingMode={changeDrawingMode} />
                    {mode == true ?
                        <Switcher condition={drawingMode}>
                            <Switcher.Case when={true}>
                                <MeasurementDeepEchoMode data={data} frameByPlane={frameByPlane} measurementService={measurementService} activeViewportIndex={activeViewportIndex} onImageScrollbarChange={onImageScrollbarChange} onClick={onClick} mode={drawingMode} drawingMode={true} changeDrawingMode={changeDrawingMode} SetFrame={setFrame} setPlane={setPlane} setSerieId={setSerieId} activeSOPInstanceUID={activeSOPInstanceUID}  displaySet={displaySet}/>
                            </Switcher.Case>
                            <Switcher.Default>
                                <MeasurementOhifMode data={data} unlabeledMeasurements={unlabeledMeasurements} measurementService={measurementService} activeViewportIndex={activeViewportIndex} onImageScrollbarChange={onImageScrollbarChange} mode={drawingMode} drawingMode={true} onClick={onClick} changeDrawingMode={changeDrawingMode} />
                            </Switcher.Default>
                        </Switcher>
                        :
                        <MeasurementDeepEchoMode data={data} frameByPlane={frameByPlane} measurementService={measurementService} activeViewportIndex={activeViewportIndex} onImageScrollbarChange={onImageScrollbarChange} onClick={onClick} drawingMode={false} changeDrawingMode={changeDrawingMode} SetFrame={setFrame} setPlane={setPlane} setSerieId={setSerieId} activeSOPInstanceUID={activeSOPInstanceUID}  displaySet={displaySet}/>
                    }
                </div>
                <div className='h-[5%] flex items-end'>
                    <MeasurementModeFooter drawingMode={mode} createReport={createReport} frameByPlane={frameByPlane} studyReport={studyReport} showModal={showModal} selectedFrame={frame} planeName={Plane} displaySet={displaySet} SerieId={SerieId} measurementService={measurementService} displaySetService={displaySetService} activeDisplaySetInstanceUID={activeDisplaySetInstanceUID} data={data} />
                </div>
            </div>
        </div>
    )
}

export default DrawingMode
