import React from 'react';
import { LuLoader2 } from 'react-icons/lu';
import { ImEnlarge2 } from 'react-icons/im';
import ButtonWithCursorMovement from '../invisible-button-click/button-with-cursor-movement';
import { VIDEO_DIMENSIONS } from '../../constants';

interface VideoDisplayProps {
  isVideoLoading: boolean;
  setModalOpen: (isOpen: boolean) => void;
  globalState: any;
  handleVideoClick: () => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  webrtcVideoRef: React.RefObject<HTMLVideoElement>;
  isInferencing: boolean;
  setGlobalState: (state: any) => void;
}


const VideoDisplay: React.FC<VideoDisplayProps> = ({
  isVideoLoading,
  setModalOpen,
  globalState,
  handleVideoClick,
  videoRef,
  webrtcVideoRef,
  isInferencing,
  setGlobalState,
}) => {
  // const handleClick = () => {
  //   setGlobalState(prevState => ({
  //     ...prevState,
  //     isVideoFrozen: !prevState.isVideoFrozen,
  //     isStart: !prevState.isStart,
  //     shouldStartInference: !prevState.isVideoFrozen 
  //   }));
  // };
  console.log("webrtcVideoRef :",webrtcVideoRef)
  console.log("isFrozen :",globalState?.isVideoFrozen)
  console.log("isInferencing :",isInferencing)


  
  return (
    <div className="relative flex h-full w-full items-center justify-center">
      {/* Loading overlay */}
      {isVideoLoading && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/50">
          <div className="inline-flex items-center space-x-2 text-white">
            <LuLoader2 className="animate-spin" size={18} />
          </div>
        </div>
      )}

      {/* Video container */}
      <div 
        className={`relative ${isVideoLoading ? 'invisible' : 'visible'}`}
        // style={{
        //   width: VIDEO_DIMENSIONS.width*0.15,
        //   height: VIDEO_DIMENSIONS.height*0.15,
        //   aspectRatio: `${VIDEO_DIMENSIONS.width} / ${VIDEO_DIMENSIONS.height}`,
        // }}
      >
        {/* Fullscreen button */}
        <div
          className="absolute top-3 right-2 z-20 cursor-pointer text-white"
          onClick={() => setModalOpen(true)}
        >
          <ImEnlarge2 size={18} />
        </div>

        {/* Overlay button */}
        <ButtonWithCursorMovement
          begin={globalState.isVideoFrozen}
          setBegin={handleVideoClick}
          deviceId={globalState.deviceId}
        />

        {/* Main video */}
        <video
          ref={videoRef}
          autoPlay
          playsInline
          width={VIDEO_DIMENSIONS.width}
          height={VIDEO_DIMENSIONS.height}
          onClick={handleVideoClick}
          className={`${!isInferencing ? 'block' : 'hidden'}`}
          style={{
            filter: globalState.isVideoFrozen ? 'blur(5px)' : 'none',
            cursor: 'pointer',
          }}
        />

        {/* WebRTC video */}
        <video
          ref={webrtcVideoRef}
          autoPlay
          playsInline
          width={VIDEO_DIMENSIONS.width}
          height={VIDEO_DIMENSIONS.height}
          onClick={handleVideoClick}
          className={`${isInferencing ? 'block' : 'hidden'}`}
          style={{
            cursor: 'pointer',
          }}
        />
      </div>
    </div>
  );
};

export default VideoDisplay;