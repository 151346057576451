import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTrash, FaInfoCircle } from "react-icons/fa";
import { getUser } from "platform/viewer/src/Deepecho/helpers/apiHelper";
import { APIClient, apiClient } from '../../../../../helpers/apiHelper';

const EditableTable = ({api,token}) => {
  const [rows, setRows] = useState([]);
  const [editIdx, setEditIdx] = useState(-1);
  const [isChanged, setIsChanged] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [newRow, setNewRow] = useState({
    label: "",
    acronyme: "",
    machine_width: "",
    machine_height: "", 
    output_width: "",
    output_height: "",
    crop_x: "",
    crop_y: "",
    crop_width: "",
    crop_height: "",
  });

  const exampleMachine = {
    label: "GE Voluson E6",
    acronyme: "GEVE6", 
    machine_width: "1000",
    machine_height: "600",
    output_width: "1000",
    output_height: "600",
    crop_x: "350",
    crop_y: "50",
    crop_width: "1160",
    crop_height: "800"
  };

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const totalPages = Math.ceil(rows.length / rowsPerPage);
  const apiClient = new APIClient();
  const apiUrl = api;

  const fetchUltrasoundMachines = async () => {
    try {
      const machines = await apiClient.getUltrasoundMachines();
      setRows(machines.data);
    } catch (error) {
      console.error('Failed to fetch ultrasound machines:', error);
    }
  };

  useEffect(() => {
    fetchUltrasoundMachines()
  }, [isChanged]);

  const handleChange = (e, index, key) => {
    const { value } = e.target;
    const updatedRows = [...rows];
    updatedRows[(currentPage - 1) * rowsPerPage + index] = {
      ...updatedRows[(currentPage - 1) * rowsPerPage + index],
      [key]: value,
    };
    setRows(updatedRows);
  };

  const handleNewRowChange = (e, key) => {
    setNewRow({
      ...newRow,
      [key]: e.target.value,
    });
  };

  const loadExample = () => {
    setNewRow(exampleMachine);
    setShowExample(false);
  };

  const addNewMachine = async (machineData) => {
    try {
      const addedMachine = await apiClient.addUltrasoundMachine(machineData);
      setRows([...rows, addedMachine]);
      setNewRow({
        label: "",
        acronyme: "",
        machine_width: "",
        machine_height: "",
        output_width: "",
        output_height: "",
        crop_x: "",
        crop_y: "",
        crop_width: "",
        crop_height: "",
      });
      setIsChanged(!isChanged)
    } catch (error) {
      console.error('Failed to add ultrasound machine:', error);
    }
  };

  const handleAddRow = () => {
    addNewMachine(newRow)
  };

  const updateMachine = async (machineData,index) => {
    try {
      const updatedMachine = await apiClient.updateUltrasoundMachine(machineData);
      const updatedRows = [...rows];
      updatedRows[index] = updatedMachine;
      setRows(updatedRows);
      setEditIdx(-1);
      setIsChanged(!isChanged)
    } catch (error) {
      console.error('Failed to update ultrasound machine:', error);
    }
  };

  const handleSave = (index) => {
    const updatedRow = rows[(currentPage - 1) * rowsPerPage + index];
    updateMachine(updatedRow, index);
  };

  const handleEdit = (index) => {
    setEditIdx(index);
  };

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const currentRows = rows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8 bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-l font-bold text-gray-800">Add New Machine</h2>
          <button 
            onClick={() => setShowExample(!showExample)}
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <FaInfoCircle className="mr-2" />
            Show Example
          </button>
        </div>

        {showExample && (
          <div className="mb-4 p-4 bg-blue-50 rounded-lg">
            <h3 className="font-semibold mb-2">Example Configuration:</h3>
            <p className="text-sm text-gray-600 mb-2">GE Voluson E6 Configuration</p>
            <button 
              onClick={loadExample}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Load Example
            </button>
          </div>
        )}

        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
          <input
            value={newRow.label}
            onChange={(e) => handleNewRowChange(e, "label")}
            placeholder="Label"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            value={newRow.acronyme}
            onChange={(e) => handleNewRowChange(e, "acronyme")}
            placeholder="Acronym"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.machine_width}
            onChange={(e) => handleNewRowChange(e, "machine_width")}
            placeholder="Machine Width"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.machine_height}
            onChange={(e) => handleNewRowChange(e, "machine_height")}
            placeholder="Machine Height"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.output_width}
            onChange={(e) => handleNewRowChange(e, "output_width")}
            placeholder="Output Width"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.output_height}
            onChange={(e) => handleNewRowChange(e, "output_height")}
            placeholder="Output Height"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.crop_x}
            onChange={(e) => handleNewRowChange(e, "crop_x")}
            placeholder="Crop X"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.crop_y}
            onChange={(e) => handleNewRowChange(e, "crop_y")}
            placeholder="Crop Y"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.crop_width}
            onChange={(e) => handleNewRowChange(e, "crop_width")}
            placeholder="Crop Width"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            value={newRow.crop_height}
            onChange={(e) => handleNewRowChange(e, "crop_height")}
            placeholder="Crop Height"
            className="border rounded p-2 focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button 
          onClick={handleAddRow} 
          className="mt-4 bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-lg transition duration-200"
        >
          Add Machine
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          
        <div className="max-h-[300px] overflow-y-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Label</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acronym</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Machine Width</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Machine Height</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Output Width</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Output Height</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Crop X</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Crop Y</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Crop Width</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Crop Height</th>
                <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentRows.map((row, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  {editIdx === index ? (
                    <>
                      <td className="px-6 py-4">
                        <input
                          value={row?.label}
                          onChange={(e) => handleChange(e, index, "label")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          value={row?.acronyme}
                          onChange={(e) => handleChange(e, index, "acronyme")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.machine_width}
                          onChange={(e) => handleChange(e, index, "machine_width")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.machine_height}
                          onChange={(e) => handleChange(e, index, "machine_height")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.output_width}
                          onChange={(e) => handleChange(e, index, "output_width")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.output_height}
                          onChange={(e) => handleChange(e, index, "output_height")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.crop_x}
                          onChange={(e) => handleChange(e, index, "crop_x")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.crop_y}
                          onChange={(e) => handleChange(e, index, "crop_y")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.crop_width}
                          onChange={(e) => handleChange(e, index, "crop_width")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="number"
                          value={row?.crop_height}
                          onChange={(e) => handleChange(e, index, "crop_height")}
                          className="border rounded p-1 w-full"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <button 
                          onClick={() => handleSave(index)} 
                          className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded"
                        >
                          Save
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="px-6 py-4">{row?.label}</td>
                      <td className="px-6 py-4">{row?.acronyme}</td>
                      <td className="px-6 py-4">{row?.machine_width}</td>
                      <td className="px-6 py-4">{row?.machine_height}</td>
                      <td className="px-6 py-4">{row?.output_width}</td>
                      <td className="px-6 py-4">{row?.output_height}</td>
                      <td className="px-6 py-4">{row?.crop_x}</td>
                      <td className="px-6 py-4">{row?.crop_y}</td>
                      <td className="px-6 py-4">{row?.crop_width}</td>
                      <td className="px-6 py-4">{row?.crop_height}</td>
                      <td className="px-6 py-4">
                        <button 
                          onClick={() => handleEdit(index)}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <FaEdit size={18} />
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

        </div>



        </div>

        <div className="flex justify-between items-center px-6 py-4 bg-gray-50">
          <button 
            onClick={prevPage} 
            disabled={currentPage === 1} 
            className={`px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 text-white'}`}
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {totalPages}
          </span>
          <button 
            onClick={nextPage} 
            disabled={currentPage === totalPages} 
            className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 text-white'}`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditableTable;