import React from 'react';
import Card from '../card';

interface LastDetectedPlanesProps {
  globalState: any; 
  lastDetectedPlane: string;
  predictionHistory: any[]; 
  skeletonCount: number;
}

const LastDetectedPlanes: React.FC<LastDetectedPlanesProps> = ({
  globalState,
  lastDetectedPlane,
  predictionHistory,
  skeletonCount
}) => {
  const isDataAvailable = globalState?.planes && globalState?.planes[lastDetectedPlane];


    // Get all unique planes detected in reverse chronological order
    const allDetectedPlanes = React.useMemo(() => {
        if (!globalState?.planes) return [];
        
        const planes = Object.entries(globalState.planes)
            .filter(([tag, data]: [string, any]) => data?.predictionHistory?.length > 0)
            .map(([tag, data]: [string, any]) => ({
                tag,
                lastDetection: data.predictionHistory[data.predictionHistory.length - 1],
                timestamp: data.predictionHistory[data.predictionHistory.length - 1].timestamp || Date.now()
            }))
            .sort((a, b) => b.timestamp - a.timestamp);

        return planes;
  }, [globalState?.planes]);

  return (
    <Card className="w-full">
      <div className="flex h-full flex-col">
        <div className="flex w-full items-center space-x-5">
          <div className="flex w-full items-center space-x-4 text-xl font-bold">
            Last Detected Plane
          </div>
        </div>
        <div className="flex h-full w-full items-center space-x-4">
          {isDataAvailable ? (
            <>
              <div className="relative h-full w-full">
                <img
                  src={`${globalState?.planes[lastDetectedPlane]?.imgReport}`}
                  alt="Main Report"
                  className="h-full w-full rounded-2xl border-2 border-[#a6abc8]"
                />
                <span className="absolute top-2 left-2 font-semibold text-white">
                  {lastDetectedPlane}
                </span>
                <span className="absolute top-2 right-2 font-semibold text-white">
                  {globalState?.planes[lastDetectedPlane]?.score
                    ? Number(globalState?.planes[lastDetectedPlane]?.score).toFixed(2) + ' %'
                    : ''}
                </span>
              </div>

              <div className="grid h-full w-full grid-cols-2 gap-2 text-xs">
                {predictionHistory.map((item, index) => (
                  <div
                    className="relative aspect-video h-full w-full"
                    key={index}
                  >
                    <img
                      src={`${item?.imagePredictedPlane}`}
                      alt={`Prediction ${index}`}
                      className="h-full w-full rounded-2xl border-2 border-[#a6abc8]"
                    />
                    <span className="absolute top-2 left-2 w-1/2 font-semibold text-white">
                      {item?.lastDetectedPlane}
                    </span>
                    <span className="absolute top-2 right-2 font-semibold text-white">
                      {item?.score ? Number(item?.score).toFixed(2) + ' %' : ''}
                    </span>
                  </div>
                ))}

                {[...Array(skeletonCount)].map((_, index) => (
                  <div
                    className="relative aspect-video h-full w-full animate-pulse"
                    key={`skeleton-${index}`}
                  >
                    <div className="h-full w-full rounded-2xl border-2 border-gray-400 bg-gray-300"></div>
                    <span className="absolute top-2 left-2 h-4 w-1/2 rounded bg-gray-400"></span>
                    <span className="absolute top-2 right-2 h-4 w-10 rounded bg-gray-400"></span>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="relative h-full w-full animate-pulse">
                <div className="h-full w-full rounded-2xl bg-[#EEF5FF]"></div>
                <span className="absolute top-2 left-2 h-4 w-16 rounded bg-gray-400"></span>
                <span className="absolute top-2 right-2 h-4 w-10 rounded bg-gray-400"></span>
              </div>

              <div className="grid h-full w-full grid-cols-2 gap-2 text-xs">
                {[...Array(4)].map((_, index) => (
                  <div
                    className="relative aspect-video h-full w-full animate-pulse"
                    key={index}
                  >
                    <div className="h-full w-full rounded-2xl bg-[#EEF5FF]"></div>
                    <span className="absolute top-2 left-2 h-4 w-1/2 rounded bg-gray-400"></span>
                    <span className="absolute top-2 right-2 h-4 w-10 rounded bg-gray-400"></span>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default LastDetectedPlanes;