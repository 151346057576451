import React, { useState, useEffect } from 'react';
import '../style/index.css';
import Modal from '../../../../Components/modal';
import Dropdown from '../customize-dropdown';
import { FaGear } from 'react-icons/fa6';
import { RiArrowGoBackLine } from 'react-icons/ri';
import EditableTable from '../editable-table';
import axios from 'axios';
import { APIClient } from '../../../../../helpers/apiHelper';

function DevicesModal({
  inputDevices,
  setDeviceId,
  setInputDevices,
  globalState,
  setGlobalState,
  mediaTypeSwitcher,
  api,
  token,
}) {
  const [bool, setBool] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [lengthDeviceArray, setLengthDeviceArray] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [optionValue, setOptionValue] = useState('');
  const [isConfig, setIsConfig] = useState(false);
  const [itemDrop, setItemDrop] = useState();
  const apiClient = new APIClient();


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    mediaTypeSwitcher();
  };

  useEffect(() => {
    if (isChecked) {
      setOptionValue('Share');
      setGlobalState(prevState => ({
        ...prevState,
        optionValueDevice: 'Share',
      }));
    } else {
      setOptionValue('USB');
      setGlobalState(prevState => ({
        ...prevState,
        optionValueDevice: 'USB',
      }));
    }
  }, [isChecked]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkDeviceChanges();
    }, 5000); // Check every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultMachines = [
    // {
    //   label: 'Samsung WS80A',
    //   acronyme: 'SWS80A',
    //   machine_width: 1920,
    //   machine_height: 1080,
    //   output_width: 1920,
    //   output_height: 1080,
    //   crop_x: 420,
    //   crop_y: 78,
    //   crop_width: 1040,
    //   crop_height: 800,
    // },
    {
      label: 'GE Voluson E6',
      acronyme: 'GEVE6',
      machine_width: 1000,
      machine_height: 600,
      output_width: 1000,
      output_height: 600,
      crop_x: 350,
      crop_y: 50,
      crop_width: 1160,
      crop_height: 800,
    },
    // {
    //   label: 'GE Voluson WS',
    //   acronyme: 'GVWS',
    //   machine_width: 1000,
    //   machine_height: 600,
    //   output_width: 1000,
    //   output_height: 600,
    //   crop_x: 426,
    //   crop_y: 125,
    //   crop_width: 1000,
    //   crop_height: 738,
    // },
    // {
    //   label: 'GE Voluson LS',
    //   acronyme: 'GVLS',
    //   machine_width: 1600,
    //   machine_height: 900,
    //   output_width: 1600,
    //   output_height: 900,
    //   crop_x: 0,
    //   crop_y: 0,
    //   crop_width: 1600,
    //   crop_height: 900,
    // },
    // {
    //   label: 'GE Versana',
    //   acronyme: 'GV',
    //   machine_width: 1258,
    //   machine_height: 710,
    //   output_width: 1258,
    //   output_height: 710,
    //   crop_x: 330,
    //   crop_y: 38,
    //   crop_width: 700,
    //   crop_height: 700,
    // },
  ];

  const fetchUltrasoundMachines = async () => {
    try {
      const machines = await apiClient.getUltrasoundMachines();

      setItemDrop(machines.data?.length ? machines.data : defaultMachines);
    } catch (error) {
      console.error('Failed to fetch ultrasound machines:', error);
      setItemDrop(defaultMachines);
    }
  };
  useEffect(() => {
    fetchUltrasoundMachines();
  }, [isConfig]);
  const checkDeviceChanges = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          console.log('Available devices:', devices);
          setLengthDeviceArray(devices?.length);
          setInputDevices(
            devices.filter(device => {
              return (
                device.kind === 'videoinput' &&
                device.label.toLowerCase().indexOf('integrateaaad') === -1 &&
                device.label.toLowerCase().indexOf('cameraaa') === -1
              );
            })
          );
        })
        .catch(error => {
          console.error('Error enumerating devices: ', error);
        });
    } else {
      console.error('enumerateDevices is not supported on this browser');
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          console.log('Available devices:', devices);
          setLengthDeviceArray(devices?.length);
          setInputDevices(
            devices.filter(device => {
              return (
                device.kind === 'videoinput' &&
                device.label.toLowerCase().indexOf('integrated______') === -1 &&
                device.label.toLowerCase().indexOf('camera__________') === -1
              );
            })
          );
        })
        .catch(error => {
          console.error('Error enumerating devices: ', error);
        });
    } else {
      console.error('enumerateDevices is not supported on this browser');
    }
  }, []);

  return (
    <div className="relative h-full w-full">
      <div className="absolute top-4 right-4 z-50">
        <FaGear
          size={18}
          className="cursor-pointer text-[#2d84c7]"
          onClick={() => {
            setIsConfig(!isConfig);
          }}
        />
      </div>
      <Modal
        isOpen={bool}
        onClose={() => {}}
        size={isConfig ? 'large' : 'small'}
        overlayClassName="!py-10"
        className="bg-[#F2F8FF]"
      >
        <Modal.Content>
          {isConfig ? (
            <div className="w-full p-3">
              <div className="flex w-full justify-end">
                <div
                  className="inline-flex w-full cursor-pointer justify-between"
                  onClick={() => {
                    setIsConfig(!isConfig);
                  }}
                >
                  <div className="w-full text-lg font-medium">Configure your machine</div>
                  <RiArrowGoBackLine
                    size={18}
                    className="text-[#2d84c7]"
                  />
                </div>
              </div>
              <div>
                <EditableTable
                  api={api}
                  token={token}
                />
              </div>
            </div>
          ) : (
            <div className="p-3">
              <div className="inline-flex items-center justify-between">
                <div className="flex w-full items-center justify-between">
                  <div className="text-lg font-semibold text-[#1F384C]">Configure Your Setup</div>
                  <div className="flex justify-end">
                    <div className="checkbox-wrapper-35 flex items-center justify-start space-x-2">
                      <input
                        value="private"
                        name="switch"
                        id="switch"
                        type="checkbox"
                        className="switch"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="switch"
                        className="mt-1"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full py-5">
                <div className="text-lg font-semibold text-[#686C82]">Select Machine Type</div>
                <div className="mt-2">
                  <Dropdown
                    items={itemDrop}
                    isOpen={openDropdown}
                    setIsOpen={setOpenDropdown}
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                </div>
              </div>

              {globalState.machineType && (
                <>
                  <div className="text-lg font-semibold text-[#686C82]">Select Input Device</div>
                  <ul className="ml-1 mt-1 w-fit rounded-lg text-sm font-medium text-gray-900">
                    {inputDevices.map((device, index) => (
                      <li
                        key={index}
                        className="w-full rounded-t-lg"
                      >
                        <div className="ps-3 flex items-center space-x-3">
                          <input
                            id={`device-checkbox-${index}`}
                            type="checkbox"
                            name="inputDevice"
                            value={device.label}
                            className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 outline-none"
                            onChange={() => setDeviceId(device.deviceId)}
                          />
                          <label
                            htmlFor={`device-checkbox-${index}`}
                            className="ms-2 w-full py-3 text-sm font-medium text-gray-900"
                          >
                            {device.label}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          )}
        </Modal.Content>
      </Modal>
    </div>
  );
}
export default DevicesModal;
