import { apiClient } from '../../apiHelper';
import {
  useCreate,
  useEdit,
  usePaginated,
  useSingleFetch,
  usePost_old,
  useQuery,
} from '../react-query';
import { patientsQueryKeys } from './patients-query-keys';

import { Patient } from '../../../UI/Sections/PatientsList/patientsList.section.custom';
import * as url from '../../urlHelper';

interface Filters {
  [key: string]: string | number;
}

interface PatientData {
  patient_id: string | number;
  values: Record<string, unknown>;
}

interface PermissionData {
  patient_id: string | number;
  user_uid: string;
  permission: string;
}

// interface ApiResponse<T = any> {
//   data: T;
// }

// interface InputFilters {
//   global__ilike?: string;
//   [key: string]: any;
// }

// interface SanitizedFilters extends Omit<InputFilters, 'global__ilike'> {
//   condition__or?: {
//     patient_name__ilike: string;
//     patient_last_name__ilike: string;
//     email__ilike: string;
//   };
// }

// export function usePaginatedPatients(
//   page: number,
//   itemsPerPage: number,
//   filters: InputFilters = {},
//   config: QueryConfig = {}
// ) {
//   const getPaginatedPatientsFn = async (p = page, f = filters) => {
//     const prepareFilters = (inputFilters: InputFilters): SanitizedFilters => {
//       console.log('Input filters:', inputFilters);
//       if (!inputFilters) {
//         return {};
//       }

//       if (!inputFilters.global__ilike || inputFilters.global__ilike.length === 0) return {};

//       const sanitizedFilters: SanitizedFilters = { ...inputFilters };

//       if (sanitizedFilters.global__ilike) {
//         sanitizedFilters['condition__or'] = {
//           patient_name__ilike: `%${sanitizedFilters.global__ilike}%`,
//           patient_last_name__ilike: `%${sanitizedFilters.global__ilike}%`,
//           email__ilike: `%${sanitizedFilters.global__ilike}%`,
//         };

//         delete sanitizedFilters.global__ilike;

//       }

//       return sanitizedFilters;
//     };

//     const sanitizedFilters = prepareFilters(f);
//     const queryString = `page=${p}&items_per_page=${itemsPerPage}&filters=${encodeURIComponent(
//       JSON.stringify(sanitizedFilters)
//     )}`;

//     const patientUrl = `patients?${queryString}`;

//     const response = await apiClient.get(patientUrl);
//     return response;
//   };

//   return usePaginated(
//     getPaginatedPatientsFn,
//     patientsQueryKeys.pagination(page, filters),
//     page,
//     config
//   );
// }

export function usePaginatedPatients(
  page: number,
  itemsPerPage: number,
  filters: Filters = {},
  config = {}
) {
  const getPaginatedPatientsFn = async (p = page, f = filters) => {
    const filtersQuery = Object.keys(f)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(f[key])}`)
      .join('&');
    const url = `patients?page=${p}&items_per_page=${itemsPerPage}${
      filtersQuery ? `&${filtersQuery}` : ''
    }`;

    const response = await apiClient.get(url);
    return response.data;
  };

  return usePaginated(
    getPaginatedPatientsFn,
    patientsQueryKeys.pagination(page).map(String),
    page,
    config
  );
}

// export function usePaginatedPatients(
//   page: number,
//   itemsPerPage: number,
//   filters: InputFilters = {},
//   config = {}
// ) {
//   const getPaginatedPatientsFn = async (p = page, f = filters) => {
//     const prepareFilters = (inputFilters: InputFilters): SanitizedFilters => {
//       if (!inputFilters || !inputFilters.global__ilike) {
//         return {};
//       }

//       const sanitizedFilters: SanitizedFilters = { ...inputFilters };

//       sanitizedFilters['condition__or'] = {
//         patient_name__ilike: `%${sanitizedFilters.global__ilike}%`,
//         patient_last_name__ilike: `%${sanitizedFilters.global__ilike}%`,
//         email__ilike: `%${sanitizedFilters.global__ilike}%`,
//       };

//       delete sanitizedFilters.global__ilike;
//       return sanitizedFilters;
//     };

//     const sanitizedFilters = prepareFilters(f);
//     const queryString = `page=${p}&items_per_page=${itemsPerPage}&filters=${encodeURIComponent(
//       JSON.stringify(sanitizedFilters)
//     )}`;

//     const response = await apiClient.get(`patients?${queryString}`);
//     return response.data;
//   };

//   return usePaginated(
//     getPaginatedPatientsFn,
//     patientsQueryKeys.pagination(page, filters),
//     page,
//     config
//   );
// }

// export function usePaginatedPatients(
//   page: number,
//   itemsPerPage: number,
//   filters: InputFilters = {},
//   config = {}
// ) {
//   const getPaginatedPatientsFn = async (p = page, f = filters) => {
//     // Start with basic pagination parameters
//     let queryParams = new URLSearchParams({
//       page: p.toString(),
//       items_per_page: itemsPerPage.toString()
//     });

//     // Handle global search if present
//     if (f.global__ilike) {
//       queryParams.append('condition__or', JSON.stringify({
//         patient_name__ilike: `%${f.global__ilike}%`,
//         patient_last_name__ilike: `%${f.global__ilike}%`,
//         email__ilike: `%${f.global__ilike}%`,
//       }));
//       delete f.global__ilike;
//     }

//     // Add remaining filters as individual parameters
//     Object.entries(f).forEach(([key, value]) => {
//       queryParams.append(key, value.toString());
//     });

//     const response = await apiClient.get(`patients?${queryParams.toString()}`);
//     return response.data;
//   };

//   return usePaginated(
//     getPaginatedPatientsFn,
//     patientsQueryKeys.pagination(page, filters),
//     page,
//     config
//   );
// }

export function useRecentPatients(limit: number = 5, archived: boolean = false, config = {}) {
  const getRecentPatientsFn = async () => {
    const response = await apiClient.get(`patients/recent?limit=${limit}&archived=${archived}`);
    return response.data;
  };

  return useQuery(getRecentPatientsFn, patientsQueryKeys.recent().map(String), {
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    ...config,
  });
}

export function useSinglePatient(patientId: number | string, config = {}) {
  const fetchPatient = async () => {
    if (patientId === -1) {
      return null;
    }
    return await apiClient.get(`patients/${patientId}`);
  };

  return useSingleFetch(
    fetchPatient,
    patientsQueryKeys.detail(patientId).map(String),
    patientId,
    config
  );
}

export function useSinglePatientWithRelated(patient_id: number | string, config = {}) {
  const getPatientFn = async () => {
    if (patient_id === -1) {
      return null;
    }
    const response = await apiClient.get(`patients/${patient_id}/with_related`);
    return response;
  };

  return useSingleFetch(
    getPatientFn,
    patientsQueryKeys.detail(patient_id).map(String),
    patient_id,
    config
  );
}

export function usePermissionsUserPatient(
  patient_id: number | string,
  user_uid: string,
  config = {}
) {
  const getPatientFn = async () => {
    const response = await apiClient.get(`patients/${patient_id}/get_permissions/${user_uid}`);
    return response;
  };

  return useSingleFetch(
    getPatientFn,
    patientsQueryKeys.permissions(patient_id, user_uid).map(String),
    null,
    config
  );
}

export function useEditPatient() {
  const editPatientFn = async (data: PatientData) => {
    const { patient_id, values } = data;
    const response = await apiClient.put(`patients/${patient_id}`, values);
    return response;
  };

  return useEdit(
    editPatientFn,
    patientsQueryKeys.all.map(String),
    'Error while Editing patient',
    'Patient edited successfully'
  );
}

export function useTakeOwnership() {
  const takeOwnershipFn = async (patient_id: PermissionData) => {
    const response = await apiClient.post(`patients/${patient_id}/take_ownership`);
    return response;
  };

  return useEdit(
    takeOwnershipFn,
    patientsQueryKeys.all.map(String),
    'Error while taking ownership',
    'Ownership taken successfully'
  );
}

export function useGrantPermission() {
  const grantPermissionFn = async (data: PermissionData) => {
    const { patient_id, user_uid, permission } = data;
    const response = await apiClient.post(
      `patients/${patient_id}/grant_permission/${user_uid}/${permission}`
    );
    return response;
  };

  return useEdit(
    grantPermissionFn,
    patientsQueryKeys.all.map(String),
    'Error while granting Permission',
    'Permission granted successfully'
  );
}

export function useRevokePermission() {
  const revokePermissionFn = async (data: PermissionData) => {
    const { patient_id, user_uid, permission } = data;
    const response = await apiClient.post(
      `patients/${patient_id}/revoke_permission/${user_uid}/${permission}`
    );
    return response;
  };

  return useEdit(
    revokePermissionFn,
    patientsQueryKeys.all.map(String),
    'Error while revoking Permission',
    'Permission revoked successfully'
  );
}

export function useArchivePatient() {
  const archivePatientFn = async (patient_id: string | number) => {
    const response = await apiClient.post(`patients/archive/${patient_id}`);
    return response;
  };

  return useEdit(
    archivePatientFn,
    patientsQueryKeys.all.map(String),
    'Error while archiving patient',
    'Patient archived successfully'
  );
}

export function useUnarchivePatient() {
  const unarchivePatientFn = async (patient_id: string | number) => {
    const response = await apiClient.post(`patients/unarchive/${patient_id}`);
    return response;
  };

  return useEdit(
    unarchivePatientFn,
    patientsQueryKeys.all.map(String),
    'Error while unarchiving patient',
    'Patient unarchived successfully'
  );
}

export function useArchivePatients() {
  const archivePatientsFn = async (patient_ids: (string | number)[]) => {
    const response = await apiClient.post(`patients/archive`, patient_ids);
    return response;
  };

  return useEdit(
    archivePatientsFn,
    patientsQueryKeys.all.map(String),
    'Error while archiving patients',
    'Patients archived successfully'
  );
}

export function useUnarchivePatients() {
  const unarchivePatientsFn = async (patient_ids: (string | number)[]) => {
    const response = await apiClient.post(`patients/unarchive`, patient_ids);
    return response;
  };

  return useEdit(
    unarchivePatientsFn,
    patientsQueryKeys.all.map(String),
    'Error while unarchiving patients',
    'Patients unarchived successfully'
  );
}

export function useCreatePatient() {
  const createPatientFn = async (data: Record<string, unknown>) => {
    const response = await apiClient.post(`patients`, data);
    return response;
  };

  return useCreate(
    createPatientFn,
    [
      ...patientsQueryKeys.all.map(String),
      ...patientsQueryKeys.recent().map(String),
      ...patientsQueryKeys.pagination(1).map(String),
    ],
    'Error while creating patient',
    'Patient created successfully'
  );
}

// for rabi, i ll migrate it soon
export const useAddPatient = (
  updater: (oldData: Patient[], newData: Patient) => Patient[],
  onSuccess?: (response: unknown, data: Patient) => void,
  onError?: (error: Error, data: Patient) => void
) => usePost_old<Patient[], Patient>(url.POST_PATIENT, undefined, updater, onSuccess, onError);
