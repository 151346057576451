import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { User } from 'oidc-client-ts';

axios.defaults.baseURL = process.env.BACKEND_URL;
axios.defaults.withCredentials = true;

interface ErrorResponse {
  response?: {
    status?: number;
    data?: {
      detail?: string;
    };
  };
  config?: any;
}

interface RequestParams {
  [key: string]: string | number | boolean;
}

interface UltrasoundMachine {
  acronyme: string;
  [key: string]: any;
}

interface APIErrorDetails {
  sop_instance_uid: string;
  original_study_info: string;
}

interface APIErrorResponse {
  message: string;
  status_code: number;
  error: string;
  details: APIErrorDetails;
}

const token = localStorage.getItem('access_token') ?? null;
if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

axios.interceptors.response.use(
  async function (response: AxiosResponse) {
    return response.data ? response.data : response;
  },
  async function (error: ErrorResponse) {
    console.log('============ error', error);
    let message: any;
    const originalRequest = error.config;
    switch (error?.response?.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      case 404:
        message = 'Sorry! the data you are looking for could not be found';
        break;
      default:
        message = error?.response?.data || error.toString();
    }
    return Promise.reject(message);
  }
);

const setAuthorization = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

function getUser(): User | null {
  const client_id = process.env.CLIENT_ID;
  const authority_url = process.env.AUTHORITY_URL;
  const oidcStorage = localStorage.getItem(`oidc.user:${authority_url}:${client_id}`);

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

class APIClient {
  get = (url: string, params?: RequestParams) => {
    const user = getUser();
    const token = user?.access_token;
    let response;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys.length ? paramKeys.join('&') : '';
      response = axios.get(`${url}?${queryString}`, {
        ...params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      response = axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return response;
  };



  fetchImageAsBase64DataUrl = async (url: string): Promise<string> => {
    const user = getUser();
    const token = user?.access_token;
  
    try {
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      };
  
      const response = await axios.get(url, config);
      const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer): string => {
        let binary = '';
        const bytes = new Uint8Array(arrayBuffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      };
  
      // Use response.data directly since we bypassed the interceptor
      const base64String = arrayBufferToBase64(response);
      return `data:image/jpeg;base64,${base64String}`;
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  };

  getUltrasoundMachines = async () => {
    try {
      const machines = await this.get('/ultrasound_machines');
      return machines;
    } catch (error) {
      console.error('Error fetching ultrasound machines:', error);
      throw error;
    }
  };

  addUltrasoundMachine = async (newRow: UltrasoundMachine) => {
    const user = getUser();
    const token = user?.access_token;

    try {
      const response = await axios.post('/ultrasound_machine', newRow, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Ultrasound Machine added successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding ultrasound machine:', error);
      throw error;
    }
  };

  updateUltrasoundMachine = async (updatedRow: UltrasoundMachine) => {
    const user = getUser();
    const token = user?.access_token;

    try {
      const response = await axios.put(`/ultrasound_machines/${updatedRow.acronyme}`, updatedRow, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Ultrasound Machine updated successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating ultrasound machine:', error);
      throw error;
    }
  };

  post = async (url: string, data: any, config: any = {}) => {
    const user = getUser();
    const token = user?.access_token;
    try {
      const response = await axios.post(url, data, {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('============ POST request successful:', response.data);
      return response;
    } catch (error) {

      console.log('============ POST request failed:', error);
      throw error;
    }
  };

  put = (url: string, data: any) => {
    const user = getUser();
    const token = user?.access_token;
    return axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  delete = (url: string, data?: any) => {
    const user = getUser();
    const token = user?.access_token;
    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

const getJWT = (): string | null => {
  const jwt = localStorage.getItem('access_token');
  return jwt ?? null;
};

const apiClient = new APIClient();

export { APIClient, setAuthorization, getJWT, getUser, apiClient };





