import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';

import { Avatar, Badge, Row, Form, Button, Input, message, Select, Spin } from 'antd';

import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import Modal from '../../Components/modal';

import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  useActivateUser,
  useChangeRoleUser,
  useCreateUser,
  useDeactivateUser,
  useMe,
  usePaginatedUsers,
  usePasswordRecovery,
  useUserPasswordReset,
} from '../../../helpers/api/users/users';

import { APIClient, getUser } from '../../../helpers/apiHelper';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import Table from '../../Components/Table/Table';

export const api = new APIClient();

const handleValidDate = date => {
  const date1 = moment(new Date(date)).format('DD MMM Y');
  return date1;
};

const handleValidTime = time => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  let meridiem = '';
  if (getHour >= 12) {
    meridiem = 'PM';
  } else {
    meridiem = 'AM';
  }
  const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + ' ' + meridiem;
  return updateTime;
};

type Role = {
  id: string;
  fullName: string;
  email: string;
  roles: number;
  // teams: number;
  type: 'active' | 'inactive';
};

const style = {
  fontFamily: 'Outfit, sans-serif',
};

const UserManagement = () => {
  const passwordRecovery = usePasswordRecovery();
  const activateUser = useActivateUser({
    onSuccess: () => {
      users.refetch();
    },
  });
  const deactivateUser = useDeactivateUser({
    onSuccess: () => {
      users.refetch();
    },
  });
  const changeRoleUser = useChangeRoleUser();

  const [tabs, setTabs] = useState([
    {
      name: 'Users',
      icon: 'ri-user-line',
      active: true,
    },
    // {
    //   name: 'Teams',
    //   icon: 'ri-group-line',
    //   active: false,
    // },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [filtersForm] = Form.useForm();
  const [passwordResetForm] = Form.useForm();
  const [toReset, setToReset] = useState();
  const [useModalOpen, setUserModalOpen] = useState(false);
  const userPasswordReset = useUserPasswordReset();

  const createUser = useCreateUser();

  const handleCreateUser = async (values: any) => {
    try {
      createUser.mutate({
        username: values.username,
        name: values.name,
        email: values.email,
        role: values.role,
      });
      // const response = await api.post('/users/create', {
      //   username: values.username,
      //   name: values.name,
      //   email: values.email,
      //   role: values.role,
      // });

      // if (!response.status_code === 201) {
      //   throw new Error('Failed to create userr');
      // }

      // message.success('User created successfully');
      // setIsModalOpen(false);
      // form.resetFields();

      // // Refresh the users table
      // users.refetch();
    } catch (error) {
      // console.error('Error creating user:', error);
      // message.error('Failed to create user');
    }
  };

  useEffect(() => {
    if (createUser.isSuccess) {
      setIsModalOpen(false);
      form.resetFields();
    }
  }, [createUser.isSuccess]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showPasswordResetModal = () => {
    setIsPasswordResetModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleResetPasswordCancel = () => {
    setIsPasswordResetModalOpen(false);
    passwordResetForm.resetFields();
  };
  const openUserModal = () => {
    setUserModalOpen(true);
  };
  const [rowSelection, setRowSelection] = React.useState({});
  // const user = useMe();
  const user = getUser();

  console.log('userùe', user);

  const columns = React.useMemo<ColumnDef<Role>[]>(
    () => [
      {
        id: 'select', // Column for row selection
        header: ({ table }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              id="checkBoxAll"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        ),
      },
      {
        accessorKey: 'name',
        cell: cellProps => (
          <div className="flex items-center gap-2">
            <Avatar
              size={25}
              src={
                <img
                  src={cellProps.row.original.avatar}
                  alt="avatar"
                />
              }
            />
            <span>{cellProps.getValue()}</span>
          </div>
        ),
        header: () => <span>Username</span>,
      },
      ,
      {
        accessorKey: 'role',
        cell: cellProps => {
          const currentRole = cellProps.row.original.roles[0];
          const getBadgeColor = (role: string) => {
            switch (role?.toLowerCase()) {
              case 'doctor':
                return 'bg-green-400';
              case 'lead_doctor':
                return 'bg-blue-400';
              case 'user_manager':
                return 'bg-orange-400';
              default:
                return 'bg-red-400';
            }
          };

          return (
            <div className="flex items-center">
              <div
                className={`inline-flex items-center rounded px-1 text-sm font-medium text-white ${getBadgeColor(currentRole)}`}
              >
                {currentRole?.toLowerCase().replace('_', ' ') || '------'}
              </div>
            </div>
          );
        },
        header: () => <span>Role</span>,
      },
      {
        accessorKey: 'email',
        id: 'createdBy',
        cell: info => info.getValue(),
        header: () => <span>Email</span>,
      },
      {
        accessorKey: 'is_active',
        cell: cellProps => (
          <div className="flex items-center gap-2">
            {cellProps.row.original.is_active ? (
              <Badge
                count="ACTIVE"
                style={{
                  backgroundColor: '#52c41a',
                  fontSize: '9px',
                  width: '65px',
                  textAlign: 'center',
                }}
              />
            ) : (
              <Badge
                count="INACTIVE"
                style={{
                  fontSize: '9px',
                  width: '65px',
                  textAlign: 'center',
                }}
              />
            )}
          </div>
        ),
        header: () => <span>Active</span>,
      },
      {
        accessorKey: 'last_login',
        cell: cell => (
          <>
            {handleValidDate(cell.getValue())},
            <small className="text-muted">{handleValidTime(cell.getValue())}</small>
          </>
        ),
        header: () => <span>Last Login</span>,
      },
      {
        header: 'Actions',
        cell: cellProps => (
          <ul className="flex">
            <>
              <Link to={`/profile/${cellProps.row.original.uid}`}>
                <li className="mr-2 cursor-pointer text-blue-800">
                  <span className="text-primary d-inline-block">
                    <i className="ri-eye-fill fs-16"></i>
                  </span>
                </li>
              </Link>
              {!cellProps.row.original.is_superuser && (
                <>
                  {cellProps.row.original.is_active ? (
                    <li
                      className="mr-2 cursor-pointer text-red-500"
                      onClick={() => {
                        deactivateUser.mutate(cellProps.row.original.uid);
                      }}
                    >
                      <span className="text-primary d-inline-block">
                        <i className="ri-user-forbid-fill fs-16"></i>
                      </span>
                    </li>
                  ) : (
                    <li
                      className="mr-2 cursor-pointer text-green-500"
                      onClick={() => {
                        activateUser.mutate(cellProps.row.original.uid);
                      }}
                    >
                      <span className="text-primary d-inline-block">
                        <i className="ri-user-follow-fill fs-16"></i>
                      </span>
                    </li>
                  )}
                  <li
                    onClick={() => {
                      setToReset(cellProps.row.original.uid);
                      showPasswordResetModal();
                    }}
                    className="mr-2 cursor-pointer text-orange-500"
                  >
                    <span className="text-primary d-inline-block">
                      <i className="ri-lock-fill fs-16"></i>
                    </span>
                  </li>
                </>
              )}
            </>
          </ul>
        ),
      },
    ],
    []
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  // const buildFilters = (filters, searchMode) => {
  //   const updatedFilters = {};

  //   Object.keys(filters).forEach(key => {
  //     const newKey = `${key}__ilike`;
  //     const newValue = `%${filters[key]}%`;
  //     updatedFilters[newKey] = newValue;
  //   });

  //   if (searchMode === 'OR') {
  //     return { condition__or: updatedFilters };
  //   }

  //   return updatedFilters;
  // };

  const buildFilters = (filters, searchMode) => {
    const nonEmptyFilters = Object.entries(filters)
      .filter(([_, value]) => value !== '')
      .reduce((acc, [key, value]) => {
        acc[`${key}__ilike`] = `%${value}%`;
        return acc;
      }, {});

    if (Object.keys(nonEmptyFilters).length === 0) {
      return {};
    }

    return searchMode === 'OR' ? { condition__or: nonEmptyFilters } : nonEmptyFilters;
  };

  const defaultData = React.useMemo(() => [], []);

  const setActiveTab = (name: string) => {
    setTabs(prev =>
      prev.map(item =>
        item.name === name ? { ...item, active: true } : { ...item, active: false }
      )
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchMode, setSearchMode] = useState('OR');
  const toggleDropdown = () => {
    setFiltersVisible(prevState => !prevState);
  };
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    reset: true,
  });
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // const handleFilterChange = e => {
  //   const { name, value } = e.target;

  //   setFilters(prev => {
  //     const updatedFilters = { ...prev };

  //     if (value === '') {
  //       delete updatedFilters[name];
  //     } else {
  //       updatedFilters[name] = value;
  //     }

  //     return updatedFilters;
  //   });
  // };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value.trim(), // Trim whitespace from input
    }));
  };

  const debouncedFilterChange = useDebouncedCallback(handleFilterChange, 500);
  const toggleSearchMode = () => {
    setSearchMode(prevSearchMode => (prevSearchMode === 'AND' ? 'OR' : 'AND'));
  };
  useEffect(() => {
    if (userPasswordReset.isSuccess) {
      handleResetPasswordCancel();
    }
  }, [userPasswordReset.isSuccess]);
  //  const users = usePaginatedUsers(
  //     pagination.pageIndex + 1,
  //     pagination.pageSize,
  //     {...(Object.keys(buildFilters(filters, searchMode)).length > 0 && {
  //       filters: JSON.stringify({
  //         ...buildFilters(filters, searchMode),
  //         ...handleDateFilter(dateRange),
  //       }),
  //     })},
  //     { refetchInterval: 60000 }
  //   );
  // const { data, error, isLoading, isError } = getProfile();
  // console.log("test data ",data)
  const users = usePaginatedUsers(
    pagination.pageIndex + 1,
    pagination.pageSize,
    {
      ...(Object.keys(filters).length > 0 && {
        filters: JSON.stringify(buildFilters(filters, searchMode)),
      }),
    },
    { refetchInterval: 60000 }
  );

  const table = useReactTable({
    data: users.data?.items ?? [],
    columns,
    rowCount: users.data?.total_count,
    state: {
      pagination,
      rowSelection,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  const selectedRows = table.getSelectedRowModel().rows.map(row => row.original);
  console.log('Selected Rows:', selectedRows);

  // useEffect(() => {
  //   setTimeout(users.refetch, 100);
  // }, [dateRange, filters, tabs,searchMode]);
  useEffect(() => {
    users.refetch();
  }, [filters, searchMode]);

  return (
    <div
      className="flex h-full flex-col px-4"
      style={style}
    >
      <div className="mb-2"></div>
      <div className="relative my-4 h-full rounded-md border border-gray-200 bg-white">
        <div className="p-2">
          <div className="flex h-8 w-full flex-row-reverse">
            <div
              className="relative"
              ref={dropdownRef}
            >
              <button
                id="new-role"
                onClick={showModal}
                className="ml-2 flex h-8 items-center justify-between rounded bg-blue-600 font-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
              >
                <i className="ri-add-large-line flex h-full w-8 items-center justify-center rounded-l bg-[rgba(255,255,255,0.1)] py-2 text-center"></i>
                <span className="px-2 py-2">New</span>
              </button>
            </div>

            <div className="flex h-full items-center justify-center">
              <button
                onClick={toggleDropdown}
                className={classNames(
                  'border-sm flex h-8 items-center justify-between rounded border font-normal transition duration-150 ease-in-out focus:outline-none focus:ring-2',
                  {
                    'border-blue-600 bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-300':
                      filtersVisible,
                    'border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white focus:ring-blue-200':
                      !filtersVisible,
                  }
                )}
              >
                <i className="ri-equalizer-line flex h-full w-8 items-center justify-center py-2 text-center"></i>
                <span className="py-2 pr-2">Filters</span>
              </button>
            </div>
          </div>
          <div className="container mx-auto">
            <div
              className={classNames(
                'mt-4 flex flex-col justify-center gap-2 transition-all duration-300 md:flex-row',
                {
                  'h-0 overflow-hidden': !filtersVisible,
                  'h-auto': filtersVisible,
                }
              )}
            >
              <Form
                form={filtersForm}
                className="w-full"
              >
                <div className="flex flex-wrap gap-4">
                  <Form.Item
                    className="w-full md:w-1/4"
                    name="name"
                  >
                    <Input
                      name="name"
                      placeholder="User name"
                      onChange={debouncedFilterChange}
                    />
                  </Form.Item>
                  <Form.Item
                    className="w-full md:w-1/4"
                    name="email"
                  >
                    <Input
                      name="email"
                      placeholder="Email"
                      onChange={debouncedFilterChange}
                    />
                  </Form.Item>
                  <div className="flex gap-2">
                    <Button
                      onClick={toggleSearchMode}
                      className="bg-blue-500 text-white"
                    >
                      {searchMode}
                    </Button>
                    <Button
                      type="primary"
                      className="bg-blue-500 text-white"
                      onClick={() => {
                        form.resetFields();
                        setFilters({});
                        setDateRange({
                          startDate: new Date(),
                          endDate: new Date(),
                          reset: true,
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <div>
          <Table
            table={table}
            totalCount={users.data?.total_count}
            isLoading={users.isLoading}
            pageSize={pagination.pageSize}
            columnsLength={columns.length}
          />
        </div>
      </div>

      <Modal
        okButtonProps={{ disabled: true }}
        onClose={handleCancel}
        isOpen={isModalOpen}
        size={'small'}
        overlayClassName="top-0 right-0 left-0"
      >
        <Modal.Header
          title={
            <div className="flex w-full justify-between">
              <div className="flex w-full justify-start text-lg font-semibold">Create New User</div>
            </div>
          }
          onClose={() => {
            handleCancel();
          }}
          displayClose={true}
        />
        <Modal.Content>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleCreateUser}
          >
            {createUser.error ? (
              <div
                className="mb-4 rounded-lg border bg-red-100 p-4 text-sm text-red-800"
                role="alert"
              >
                <span className="font-medium">Error!</span> An error has occured while creating the
                user.
              </div>
            ) : null}
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: 'Please input username!' }]}
            >
              <Input placeholder="Enter username" />
            </Form.Item>
            <Form.Item
              name="name"
              label="Full Name"
              rules={[{ required: true, message: 'Please input full name!' }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please input email!' },
                { type: 'email', message: 'Please enter a valid email!' },
              ]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Please select role!' }]}
            >
              <Select>
                <Select.Option value="doctor">Doctor</Select.Option>
                <Select.Option value="user_manager">User Manager</Select.Option>
                <Select.Option value="lead_doctor">Lead Doctor</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item className="flex w-full justify-end">
              <button
                type="submit"
                className={`inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm ${createUser.isPending ? 'cursor-not-allowed' : 'hover:bg-blue-600'}`}
                disabled={createUser.isPending} // Disable button when isPending
              >
                {createUser.isPending ? <Spin size="small bg-white" /> : 'Create'}
              </button>
            </Form.Item>
          </Form>
        </Modal.Content>
      </Modal>

      <Modal
        okButtonProps={{ disabled: true }}
        onClose={handleResetPasswordCancel}
        isOpen={isPasswordResetModalOpen}
        size={'small'}
        overlayClassName="top-0 right-0 left-0"
      >
        <Modal.Header
          title={
            <div className="flex w-full justify-between">
              <div className="flex w-full justify-start text-lg font-semibold">Reset Password</div>
            </div>
          }
          onClose={() => {
            handleResetPasswordCancel();
          }}
          displayClose={true}
        />
        <Modal.Content>
          <Form
            form={passwordResetForm}
            layout="vertical"
            onFinish={values => {
              const formData = new FormData();
              formData.append('new_password', values.password);
              userPasswordReset.mutate({ uid: toReset, form: formData });
            }}
          >
            {userPasswordReset.error ? (
              <div
                className="mb-4 rounded-lg border bg-red-100 p-4 text-sm text-red-800"
                role="alert"
              >
                <span className="font-medium">Error!</span> An error has occured while resetting the
                password.
              </div>
            ) : null}
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please input password!' }]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item
              name="repeat_password"
              label="Repeat password"
              dependencies={['password']} // This ensures that validation runs when 'password' changes
              rules={[
                { required: true, message: 'Please repeat password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Repeat password" />
            </Form.Item>
            <Form.Item className="flex w-full justify-end">
              <button
                type="submit"
                className={`inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm ${userPasswordReset.isPending ? 'cursor-not-allowed' : 'hover:bg-blue-600'}`}
                disabled={userPasswordReset.isPending} // Disable button when isPending
              >
                {userPasswordReset.isPending ? <Spin size="small bg-white" /> : 'Reset'}
              </button>
            </Form.Item>
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default UserManagement;
