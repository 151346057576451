// External
import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'remixicon/fonts/remixicon.css';
import i18n from '@ohif/i18n';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Compose from './routes/Mode/Compose';
import { ServicesManager, ExtensionManager, CommandsManager, HotkeysManager } from '@ohif/core';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import 'bootstrap/dist/css/bootstrap.min.css'; //test
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  DialogProvider,
  Modal,
  ModalProvider,
  SnackbarProvider,
  ThemeWrapper,
  ViewportDialogProvider,
  ViewportGridProvider,
  CineProvider,
  UserAuthenticationProvider,
} from '@ohif/ui';
import { AppConfigProvider } from '@state';
import createRoutes from './routes';
import appInit from './appInit.js';
import OpenIdConnectRoutes from './utils/OpenIdConnectRoutes';

import AppLayout from './Deepecho/AppLayout';
import { RealtimeGlobalStateProvider, SideBarCollapsedProvider } from './Deepecho/state';
import { ProtectedPages } from './Deepecho/Pages';
import { Provider } from 'react-redux';
import { configureStore } from './Deepecho/store';
import publicPages from './Deepecho/PublicPages';
import DefaultLayout from './Deepecho/DefaultLayout';
import ProtectedRoutes from './Deepecho/ProtectedRoutes';
import PublicRoutes from './Deepecho/PublicRoutes';

import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore, User } from 'oidc-client-ts';
// import { DrawingModeProvider } from 'platform/ui/src/contextProviders';
import DrawingModeProvider from '../../ui/src/contextProviders/DrawingModeProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

let commandsManager: CommandsManager,
  extensionManager: ExtensionManager,
  servicesManager: ServicesManager,
  hotkeysManager: HotkeysManager;

const client_id = process.env.CLIENT_ID;
const authority_url = process.env.AUTHORITY_URL;
const redirect_uri = window.location.origin;

const oidcConfig = {
  authority: `${authority_url}`,
  client_id: `${client_id}`,
  redirect_uri: redirect_uri,
  post_logout_redirect_uri: redirect_uri,
  scope: 'openid profile email offline_access',
  userStore: new WebStorageStateStore({ store: localStorage }),
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
    // window.location.reload();
  },
};

function App({ config, defaultExtensions, defaultModes }) {
  const [init, setInit] = useState(null);
  useEffect(() => {
    const run = async () => {
      appInit(config, defaultExtensions, defaultModes).then(setInit).catch(console.error);
    };

    run();
  }, []);

  if (!init) {
    return null;
  }

  // Set above for named export
  commandsManager = init.commandsManager;
  extensionManager = init.extensionManager;
  servicesManager = init.servicesManager;
  hotkeysManager = init.hotkeysManager;

  // Set appConfig
  const appConfigState = init.appConfig;
  const { routerBasename, modes, dataSources, oidc, showStudyList } = appConfigState;
  const queryClient = new QueryClient();

  const {
    uiDialogService,
    uiModalService,
    uiNotificationService,
    uiViewportDialogService,
    viewportGridService,
    cineService,
    userAuthenticationService,
    customizationService,
  } = servicesManager.services;

  const providers = [
    [DrawingModeProvider],
    [RealtimeGlobalStateProvider],
    [SideBarCollapsedProvider],
    [AppConfigProvider, { value: appConfigState }],
    [UserAuthenticationProvider, { service: userAuthenticationService }],
    [I18nextProvider, { i18n }],
    [ThemeWrapper],
    [ViewportGridProvider, { service: viewportGridService }],
    [ViewportDialogProvider, { service: uiViewportDialogService }],
    [CineProvider, { service: cineService }],
    [SnackbarProvider, { service: uiNotificationService }],
    [DialogProvider, { service: uiDialogService }],
    [ModalProvider, { service: uiModalService, modal: Modal }],
  ];
  const CombinedProviders = ({ children }) => Compose({ components: providers, children });

  let authRoutes = null;

  const url = process.env.BACKEND_URL;

  // Should there be a generic call to init on the extension manager?
  customizationService.init(extensionManager);

  // Use config to create routes
  const appRoutes = createRoutes({
    modes,
    dataSources,
    extensionManager,
    servicesManager,
    commandsManager,
    hotkeysManager,
    routerBasename,
    showStudyList,
  });

  if (oidc) {
    authRoutes = (
      <OpenIdConnectRoutes
        oidc={oidc}
        routerBasename={routerBasename}
        userAuthenticationService={userAuthenticationService}
      />
    );
  }

  return (
    <div className="bg-white">
      {process.env.IS_AUTH_ACTIVATED === 'True' ? (
        <AuthProvider {...oidcConfig}>
          {' '}
          <Provider store={configureStore({})}>
          <QueryClientProvider client={queryClient}>
            <CombinedProviders>
              <BrowserRouter basename={routerBasename}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    {appRoutes}

                    {ProtectedPages.map((page, key) => (
                      <Route
                        key={key}
                        exact={true}
                        path={page.path}
                        element={
                          <ProtectedRoutes allowedRoles={page.allowedRoles}>
                            <AppLayout>{page.element}</AppLayout>
                          </ProtectedRoutes>
                        }
                      />
                    ))}

                    {publicPages.map((page, key) => (
                      <Route
                        key={key}
                        exact={true}
                        path={page.path}
                        element={
                          <PublicRoutes>
                            <DefaultLayout>{page.element}</DefaultLayout>
                          </PublicRoutes>
                        }
                      />
                    ))}
                  </Routes>
                </Suspense>
              </BrowserRouter>
            </CombinedProviders>
          </QueryClientProvider>
          </Provider>
        </AuthProvider>
      ) : (
        <Provider store={configureStore({})}>
          <CombinedProviders>
            <BrowserRouter basename={routerBasename}>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  {appRoutes}

                  {ProtectedPages.map((page, key) => (
                    <Route
                      key={key}
                      exact={true}
                      path={page.path}
                      element={<AppLayout>{page.element}</AppLayout>}
                    />
                  ))}

                  {publicPages.map((page, key) => (
                    <Route
                      key={key}
                      exact={true}
                      path={page.path}
                      element={
                        <PublicRoutes>
                          <DefaultLayout>{page.element}</DefaultLayout>
                        </PublicRoutes>
                      }
                    />
                  ))}
                </Routes>
              </Suspense>
            </BrowserRouter>
          </CombinedProviders>
        </Provider>
      )}
    </div>
  );
}

App.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
      oidc: PropTypes.array,
      whiteLabeling: PropTypes.object,
      extensions: PropTypes.array,
    }),
  ]).isRequired,
  /* Extensions that are "bundled" or "baked-in" to the application.
   * These would be provided at build time as part of they entry point. */
  defaultExtensions: PropTypes.array,
};

App.defaultProps = {
  config: {
    /**
     * Relative route from domain root that OHIF instance is installed at.
     * For example:
     *
     * Hosted at: https://ohif.org/where-i-host-the/viewer/
     * Value: `/where-i-host-the/viewer/`
     * */
    routerBaseName: '/',
    /**
     *
     */
    showLoadingIndicator: true,
    showStudyList: true,
    oidc: [],
    extensions: [],
  },
  defaultExtensions: [],
};

export default App;

export { commandsManager, extensionManager, servicesManager };
