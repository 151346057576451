import { useRef, useState } from 'react';
import { VIDEO_DIMENSIONS, DISPLAY_DIMENSIONS, CROP_REGION } from '../constants';


// it s redundant, ill remove it later
interface CropRegion {
  width: number;
  height: number;
  x: number;
  y: number;
}

interface VideoProcessingResult {
  videoStream: MediaStream | null;
  videoRef: React.RefObject<HTMLVideoElement>;
  processingCanvasRef: React.RefObject<HTMLCanvasElement>;
  processedStreamRef: React.RefObject<MediaStream | null>;
  isVideoLoading: boolean;
  createCroppedStream: (inputStream: MediaStream, cropRegion: CropRegion) => MediaStream | null;
  startVideoStream: (deviceId: string, optionValue: string) => Promise<void>;
  stopVideoStream: () => void;
  setIsVideoLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getVideoStream: () => Promise<MediaStream | null>;
}

export const useVideoProcessing = (): VideoProcessingResult => {
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const processingCanvasRef = useRef<HTMLCanvasElement>(null);
  const processedStreamRef = useRef<MediaStream | null>(null);

  const createCroppedStream = (
    inputStream: MediaStream, 
    cropRegion: typeof CROP_REGION
  ): MediaStream | null => {
    const processingCanvas = processingCanvasRef.current;
    if (!processingCanvas) return null;

    const ctx = processingCanvas.getContext('2d');
    if (!ctx) return null;

    // Set canvas dimensions to match crop region
    processingCanvas.width = cropRegion.width;
    processingCanvas.height = cropRegion.height;

    // Create a video element to receive the input stream
    const videoElement = document.createElement('video');
    videoElement.srcObject = inputStream;
    videoElement.autoplay = true;

    // Create output stream from canvas
    const outputStream = processingCanvas.captureStream();

    // Process frames
    const processFrame = () => {
      if (!videoElement.paused && !videoElement.ended) {
        ctx.drawImage(
          videoElement,
          cropRegion.x, cropRegion.y, cropRegion.width, cropRegion.height,
          0, 0, cropRegion.width, cropRegion.height
        );
        requestAnimationFrame(processFrame);
      }
    };

    videoElement.addEventListener('play', () => {
      processFrame();
    });

    return outputStream;
  };

  const startVideoStream = async (deviceId: string, optionValue: string) => {
    // Stop existing tracks before creating new stream
    console.log("startVideoStream",videoStream)
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
    }

    try {
      setIsVideoLoading(true);
      let stream: MediaStream;

      if (optionValue === 'Share') {
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            deviceId: deviceId,
            width: { ideal: VIDEO_DIMENSIONS.width },
            height: { ideal: VIDEO_DIMENSIONS.height },
          }
        });
      } else if (optionValue === 'USB') {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: deviceId,
            width: { ideal: VIDEO_DIMENSIONS.width },
            height: { ideal: VIDEO_DIMENSIONS.height },
          }
        });
      } else {
        throw new Error('Invalid option value');
      }

      if (videoRef.current) {
        console.log("test aaa",stream)
        videoRef.current.srcObject = stream;
        setVideoStream(stream);
        
        // Auto-play the video
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.error('Error playing video:', error);
          });
        }
      }
    } catch (error) {
      console.error('Error accessing the video stream: ', error);
    } finally {
      setIsVideoLoading(false);
    }
  };

  const stopVideoStream = () => {
    console.log("ask,oa:",videoStream)
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
      setVideoStream(null);
    }
    
    if (processedStreamRef.current) {
      processedStreamRef.current.getTracks().forEach(track => track.stop());
      processedStreamRef.current = null;
    }
  };

  const getVideoStream = async (deviceId: string, optionValue: string): Promise<MediaStream> => {
    const constraints = {
      video: {
        deviceId: deviceId,
        width: { ideal: VIDEO_DIMENSIONS.width },
        height: { ideal: VIDEO_DIMENSIONS.height }
      }
    };

    switch (optionValue) {
      case 'Share':
        return await navigator.mediaDevices.getDisplayMedia(constraints);
      case 'USB':
        return await navigator.mediaDevices.getUserMedia(constraints);
      default:
        throw new Error(`Unsupported video source: ${optionValue}`);
    }
  };

  return {
    videoStream,
    videoRef,
    processingCanvasRef,
    processedStreamRef,
    isVideoLoading,
    setIsVideoLoading,
    createCroppedStream,
    startVideoStream,
    stopVideoStream,
    getVideoStream,
  };
};