
export enum MediaType {
    USER_MEDIA = 'UserMedia',
    DISPLAY_MEDIA = 'DisplayMedia',
  }
  
  export const mediaTypeMap = new Map([
    ['Share', MediaType.DISPLAY_MEDIA],
    ['USB', MediaType.USER_MEDIA],
  ]);
  

  export const VIDEO_DIMENSIONS = {
    width: 1920,
    height: 1080,
    aspectRatio: 16 / 9,
  } as const;

  
  export const DISPLAY_DIMENSIONS = {
    width: VIDEO_DIMENSIONS.width,
    height: Math.round(VIDEO_DIMENSIONS.width / VIDEO_DIMENSIONS.aspectRatio), // Calculate dynamically
  };


  export const CROP_REGION = {
    x: 300,
    y: 100,
    width: 800,
    height: 600
  } as const;

  

  export const INITIAL_GLOBAL_STATE = {
    // deviceId: '',
    // inputDevices: [],
    isStart: false,
    isVideoFrozen: true,
    isExamEnded: false,
    // optionValueDevice: '',
    reportModalOpened: false,
    openPanel: true,
    patient: {
      name: 'Uknown',
    },
    // selectedItem: 'Select your machine',
    selectedItemsMultidrop: [],
    configMachine: {},
    planes: {
      femur: {
        score: 0,
        scoreOrigin: 0,
      },
      abdominal: {
        score: 0,
        scoreOrigin: 0,
      },
      cephalic: {
        score: 0,
        scoreOrigin: 0,
      },
      af_pocket: {
        score: 0,
        scoreOrigin: 0,
      },
    },
    ratingScore: 0,
    lastDetectedPlane: '',
  } as const;