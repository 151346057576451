import React from 'react';
import { useParams } from 'react-router';
import { useSinglePatient } from '../../../helpers/api/patients/patients';
import { Card, Tabs, Table, Tag, Button, Row, Col, Skeleton } from 'antd';
import placeholderImg from '../../../../assets/80x60.png';
import { Link } from 'react-router-dom';
import { getInitials } from './utils';

const { TabPane } = Tabs;


const PatientProfile = () => {
  const params = useParams();
  const patient = useSinglePatient(params.id);

  if (patient.isLoading) {
    return <Skeleton active />;
  }

  const patientData = patient.data?.data;

  const vitalSignsColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Tag color={status === 'Normal' ? 'black' : 'red'}>{status.toUpperCase()}</Tag>
      ),
    },
  ];

  const vitalSignsData = [
    { key: '1', name: 'Blood Pressure', value: '120/80 mmHg', status: 'Normal' },
    { key: '2', name: 'Heart Rate', value: '72 bpm', status: 'Normal' },
    { key: '3', name: 'Respiratory Rate', value: '16 breaths/min', status: 'warning' },
    { key: '4', name: 'Temperature', value: '98.6°F (37°C)', status: 'Normal' },
  ];

  const examHistoryData = [
    {
      id: 1,
      examName: 'Q1 Exam',
      description: 'Lorem Ipsum',
      date: '2 months ago',
    },
    {
      id: 2,
      examName: 'Q1 Exam',
      description: 'Lorem Ipsum',
      date: '2 months ago',
    },
    {
      id: 3,
      examName: 'Q1 Exam',
      description: 'Lorem Ipsum',
      date: '2 months ago',
    },
  ];

  const labResultsColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Tag color={status === 'Normal' ? 'black' : 'red'}>{status.toUpperCase()}</Tag>
      ),
    },
  ];

  const labResultsData = [{ key: '1', name: 'Hemoglobin', value: '14.2 g/dL', status: 'Normal' }];

  return (
    <div className="p-6">
      <Row gutter={16}>
        <Col
          xs={24}
          lg={16}
        >
          <Card className="mb-4 w-full">
            <div className="mb-4 flex items-center">
              <div className="flex h-16 w-16 items-center justify-center rounded-full bg-blue-500 text-2xl text-white">
                {getInitials(`${patientData?.patient_name} ${patientData?.patient_last_name}`)}
              </div>
              <div className="ml-4">
                <h2 className="text-xl font-bold">
                  {patientData?.patient_name} {patientData?.patient_last_name}
                </h2>
                <p className="text-gray-500">Patient ID: #{patientData?.patient_id}</p>
                <div className="mt-2 flex space-x-4">
                  <p className="flex items-center text-gray-600">
                    <i className="ri-mail-line mr-2"></i>
                    {patientData?.email}
                  </p>
                  <p className="flex items-center text-gray-600">
                    <i className="ri-phone-line mr-2"></i>
                    {patientData?.phone_number}
                  </p>
                </div>
                <Link
                  to={`/profile/${patientData?.owner?.uid}`}
                  className="mt-2 flex items-center text-gray-600"
                >
                  <i className="ri-user-2-line mr-2"></i>
                  {patientData?.owner?.name}
                </Link>
              </div>
            </div>

            <Tabs defaultActiveKey="1">
              <TabPane
                tab="Exam History"
                key="1"
              >
                <div className="exam-history">
                  {examHistoryData.map(exam => (
                    <Card
                      key={exam.id}
                      className="mb-2 p-4"
                    >
                      <Row
                        justify="space-between"
                        align="middle"
                      >
                        <Col span={20}>
                          <div className="flex items-center">
                            <i className="ri-calendar-line mr-4 text-2xl text-blue-500"></i>
                            <div>
                              <h3 className="text-lg font-semibold">{exam.examName}</h3>
                              <p className="text-gray-500">{exam.description}</p>
                              <p className="text-gray-400">{exam.date}</p>
                            </div>
                          </div>
                        </Col>
                        <Col
                          span={4}
                          className="text-right"
                        >
                          <Button type="link">View Details</Button>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </div>
              </TabPane>
              <TabPane
                tab="Reports"
                key="2"
              >
                <div className="mb-4">
                  <h3 className="font-semibold">Fetal Biometry</h3>
                  <p>
                    <i className="ri-calendar-line mr-2"></i> Date: 2022-12-21 12:03 PM
                  </p>
                  <p>
                    <i className="ri-user-line mr-2"></i> Patient: Mrs. Simone Well
                  </p>
                  <div className="mb-4 flex items-center justify-between">
                    <div>
                      <p>
                        <strong>DOB:</strong> <i className="ri-calendar-line mr-2"></i> 10 - 07 -
                        2000
                      </p>
                      <p>
                        <strong>LMP:</strong> <i className="ri-calendar-line mr-2"></i> 10 - 07 -
                        2000
                      </p>
                      <p>
                        <strong>EXAM:</strong> <i className="ri-check-line mr-2"></i> 2nd Semester
                      </p>
                    </div>
                    <Tag color="green">
                      <i className="ri-checkbox-circle-line mr-1"></i> Completed
                    </Tag>
                  </div>
                  <Button type="link">
                    <i className="ri-file-list-line mr-2"></i> View Full Report
                  </Button>
                </div>

                <div className="mb-4">
                  <h3 className="font-semibold">Fetal Biometry</h3>
                  <p>
                    <i className="ri-calendar-line mr-2"></i> Date: 2022-12-21 12:03 PM
                  </p>
                  <p>
                    <i className="ri-user-line mr-2"></i> Patient: Mrs. Simone Well
                  </p>
                  <div className="mb-4 flex items-center justify-between">
                    <div>
                      <p>
                        <strong>DOB:</strong> <i className="ri-calendar-line mr-2"></i> 10 - 07 -
                        2000
                      </p>
                      <p>
                        <strong>LMP:</strong> <i className="ri-calendar-line mr-2"></i> 10 - 07 -
                        2000
                      </p>
                      <p>
                        <strong>EXAM:</strong> <i className="ri-time-line mr-2"></i> 2nd Semester
                      </p>
                    </div>
                    <Tag color="blue">
                      <i className="ri-time-line mr-1"></i> In Review
                    </Tag>
                  </div>
                  <Button type="link">
                    <i className="ri-file-list-line mr-2"></i> View Full Report
                  </Button>
                </div>
              </TabPane>
              <TabPane
                tab="Medical Background"
                key="3"
              >
                <h3 className="mb-2 font-semibold">Vital Signs</h3>
                <Table
                  columns={vitalSignsColumns}
                  dataSource={vitalSignsData}
                  pagination={false}
                  className="mb-6"
                />

                <h3 className="mb-2 font-semibold">Lab Results</h3>
                <Table
                  columns={labResultsColumns}
                  dataSource={labResultsData}
                  pagination={false}
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>

        <Col
          xs={24}
          lg={8}
        >
          <div className="mb-4 flex justify-end gap-2">
            <Button
              type="primary"
              icon={<i className="ri-pencil-line" />}
            >
              Edit Profile
            </Button>
            <Button
              type="primary"
              icon={<i className="ri-add-large-line" />}
            >
              New Exam
            </Button>
          </div>
          <Card className="w-full p-2">
            <div className="rounded-md">
              <h3 className="mb-4 text-lg font-semibold">Historical Imagery</h3>

              <h4 className="font-semibold">Final Reports</h4>
              <div className="mb-4 grid grid-cols-3 gap-2">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className="text-center"
                  >
                    <img
                      src={placeholderImg}
                      alt="Report"
                      className="mb-1"
                    />
                    <p className="text-sm">2023-12-11 12:03 PM</p>
                    <p className="text-sm">Dr. Andrew Thompson</p>
                  </div>
                ))}
              </div>

              <h4 className="font-semibold">Raw Exams Imagery</h4>
              <div className="grid grid-cols-3 gap-2">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className="text-center"
                  >
                    <img
                      src={placeholderImg}
                      alt="Raw Exam"
                      className="mb-1"
                    />
                    <p className="text-sm">2023-12-11 12:03 PM</p>
                    <p className="text-sm">Dr. Andrew Thompson</p>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PatientProfile;
