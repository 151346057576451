import React, { useEffect, useState } from 'react';
import Modal from '../../../../Components/modal';
import { RiArrowGoBackLine } from 'react-icons/ri';
import EditableTable from '../editable-table';
import Dropdown from '../customize-dropdown';
import { FaGear } from 'react-icons/fa6';
import './style/index.css';
import Stepper from './stepper';
import DevicesModal from '../devices-modal';
import PatientTableSelectionRealtime from '../../../../Components/select-patient';
import PixelSpacingModal from '../pixel-spacing';


interface SelectedRow {
  row_id: any;
  patient: any;
}
function StepperComponent({
  globalState,
  setGlobalState,
  mediaTypeSwitcher,
  api,
  token,
  videoRef,
  bool,
  setBool,
  currentStep,
  setCurrentStep,
}) {
  const [isConfig, setIsConfig] = useState(false);
  const [selectedRow, setSelectedRow] = useState<SelectedRow | null>(null);
  const steps = ['1', '2', '3'];

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleGoToExam = () => {
    // Reset any existing WebRTC connections before starting the exam
    if (globalState.isStart) {
      setGlobalState(prevState => ({
        ...prevState,
        isStart: false,
        isVideoFrozen: true
      }));
    }
    
    setBool(!bool);
    
    // Start the video stream after a short delay to ensure clean state
    setTimeout(() => {
      setGlobalState(prevState => ({
        ...prevState,
        isStart: true,
        isVideoFrozen: false
      }));
    }, 100);
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(()=>{
    setSelectedRow(null)
  },[globalState?.deviceId])

  return (
    <div
      className={`${bool ? 'visible' : 'hidden'} absolute inset-0 z-50 flex h-full items-center justify-center bg-white`}
    >
      <div className="absolute top-4 right-4 z-50">
        <FaGear
          size={18}
          className="cursor-pointer text-[#2d84c7]"
          onClick={() => {
            setIsConfig(!isConfig);
          }}
        />
      </div>
      <Modal
        isOpen={bool}
        onClose={() => {}}
        size={isConfig || currentStep == 3 ? 'large' : 'small'}
        className="bg-[#F2F8FF]"
      >
        <Modal.Content>
          {isConfig ? (
            <div className="w-full p-3">
              <div className="flex w-full justify-end">
                <div
                  className="inline-flex w-full cursor-pointer justify-between"
                  onClick={() => {
                    setIsConfig(!isConfig);
                  }}
                >
                  <div className="w-full text-lg font-medium">Configure your machine</div>
                  <RiArrowGoBackLine
                    size={18}
                    className="text-[#2d84c7]"
                  />
                </div>
              </div>
              <div>
                <EditableTable
                  api={api}
                  token={token}
                />
              </div>
            </div>
          ) : (
            <div className="h-full w-full p-4">
              <Stepper
                steps={steps}
                currentStep={currentStep}
                handleNext={handleNext}
                handleBack={handleBack}
              />
              {currentStep == 1 ? (
                <DevicesModal
                  mediaTypeSwitcher={mediaTypeSwitcher}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  inputDevices={globalState.inputDevices}
                  handleNext={handleNext}
                  api={api}
                  token={token}
                  setDeviceId={value =>
                    setGlobalState(prevState => ({
                      ...prevState,
                      deviceId: value,
                    }))
                  }
                  setInputDevices={value =>
                    setGlobalState(prevState => ({
                      ...prevState,
                      inputDevices: value,
                    }))
                  }
                />
              ) : currentStep == 2 ? (
                <PixelSpacingModal
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  videoRef={videoRef}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  currentStep={currentStep}
                />
              ) : (
                <PatientTableSelectionRealtime
                  handleBack={handleBack}
                  handleNext={handleGoToExam}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                />
              )}
            </div>
          )}
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default StepperComponent;
