import { Plane, PlaneConfig } from '../types/planes.types';

export const buildPlane = (config: PlaneConfig, globalState: any): Plane => {
  const planData = globalState?.planes?.[config.tag];
  
  return {
    label: config.label,
    imgPlane: planData?.imgReport ?? config.defaultImage,
    tag: config.tag,
    quality_score: planData?.selectedViewData?.score ?? 0,
    quality_criteria: planData?.selectedViewData?.quality_criteria ?? [],
    isInReport: !!planData?.selectedViewData,
    selectedImageReport: planData?.selectedViewData?.imagePredictedPlane ?? config.defaultImage,
  };
};

export const buildPlanes = (planeConfigs: PlaneConfig[], globalState: any): Plane[] => {
  return planeConfigs.map(config => buildPlane(config, globalState));
};