// import React, { useEffect, useState } from 'react';
// import Table from '../../Components/Table/Table';
// import {
//   CellContext,
//   ColumnDef,
//   getCoreRowModel,
//   PaginationState,
//   useReactTable,
// } from '@tanstack/react-table';
// import {useDownloadDataset, usePaginatedSampleData } from '../../../helpers/api/datasamples/datasample';
// import { IoCloudDownload } from 'react-icons/io5';

// const SamplesData: React.FC = () => {
//   const style = {
//     fontFamily: 'Outfit, sans-serif',
//   };
//   const [downloading, setDownloading] = useState(new Set());
//   const [datasetNames, setDatasetNames] = useState<{[key: string]: string}>({});
  
//   const [pagination, setPagination] = React.useState<PaginationState>({
//     pageIndex: 0,
//     pageSize: 10,
//   });
//   const { data, isError, isLoading, refetch } = usePaginatedSampleData(
//     pagination.pageIndex + 1,
//     pagination.pageSize
//   );
 
//   const downloadMutation = useDownloadDataset();

//   const handleDownload = (datasetId: string) => {
//     setDownloading((prev) => new Set(prev).add(datasetId));

//     downloadMutation.mutate(datasetId, {
//       onSettled: () => {
//         setDownloading((prev) => {
//           const next = new Set(prev);
//           next.delete(datasetId);
//           return next;
//         });
//       },
//     });
//   };

//   const getRandomDatasetName = () => {
//     const prefixes = ['Sample', 'Test', 'Demo', 'Training', 'Research'];
//     const suffixes = ['Dataset', 'Collection', 'Series', 'Study', 'Scan'];
    
//     const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)];
//     const randomSuffix = suffixes[Math.floor(Math.random() * suffixes.length)];
    
//     const uuid = Math.random().toString(36).substring(2, 15) + 
//                 Math.random().toString(36).substring(2, 15);
    
//     return `${randomPrefix} ${randomSuffix} ${uuid}`;
//   };

//   const columns = React.useMemo<ColumnDef<any>[]>(
//     () => [
//       {
//         id: 'select',
//         header: ({ table }) => (
//           <div className="flex items-center">
//             <input
//               type="checkbox"
//               id="checkBoxAll"
//               checked={table.getIsAllRowsSelected()}
//               onChange={table.getToggleAllRowsSelectedHandler()}
//             />
//           </div>
//         ),
//         cell: ({ row }) => (
//           <div className="flex items-center">
//             <input
//               type="checkbox"
//               checked={row.getIsSelected()}
//               onChange={row.getToggleSelectedHandler()}
//             />
//           </div>
//         ),
//       },
//       {
//         accessorKey: 'name',
//         header: () => <span>Dataset Name</span>,
//         cell: cell => {
//           const datasetId = cell.row.original.dataset_id;
//           return <>{datasetNames[datasetId] || cell.getValue()}</>;
//         },
//         footer: props => props.column.id,
//       },
//       {
//         header: ' ',// keep this empty and with space
//         size: 5,
//         cell: (cellProps: any) => {
//           const currentId = cellProps.row.original.dataset_id;
//           const isDownloading = downloading.has(currentId);
//           return (
//             <ul className="flex justify-end">
//               <li
//                 className="mr-2 pl-4 cursor-pointer text-blue-800"
//                 onClick={() => handleDownload(currentId)}
//               >
//                 {isDownloading ? (
//                    <i className="ri-loader-4-line spinner fs-16" />
//                 ) : (
//                   <IoCloudDownload />
//                 )}
//               </li>
//             </ul>
//           );
//         },
//       },
//     ],
//     [downloading, datasetNames]
//   );
//   const table = useReactTable({
//     data: data?.data || [],
//     columns,
//     rowCount: data?.total_count || 0,
//     state: {
//       pagination,
//     },
//     onPaginationChange: setPagination,
//     getCoreRowModel: getCoreRowModel(),
//     manualPagination: true,
//     debugTable: true,
//   });

//   useEffect(() => {
//     if (data?.data) {
//       const newDatasetNames = {};
//       data.data.forEach((dataset) => {
//         if (!datasetNames[dataset.dataset_id]) {
//           newDatasetNames[dataset.dataset_id] = getRandomDatasetName();
//         }
//       });
//       setDatasetNames(prev => ({
//         ...prev,
//         ...newDatasetNames
//       }));
//     }
//   }, [data?.data]);

//   return (
//     <div
//       className="flex h-full flex-col px-4"
//       style={style}
//     >
//       <div className="relative my-4 h-full rounded-md border border-gray-200 bg-white">
//         <div className="px-2 py-5"></div>

//         <Table
//           table={table}
//           totalCount={data?.total_count}
//           isLoading={isLoading}
//           pageSize={pagination.pageSize}
//           columnsLength={columns.length}
//         />
//       </div>
//     </div>
//   );
// };

// export default SamplesData;



import React, { useState } from 'react';
import { api } from '../../../helpers/backendHelper';
const SamplesData: React.FC = () => {
  const [datasets, setDatasets] = useState<any>(null);
  const fetchDatasets = async () => {
    try {
      const response = await api.get('/sample-datasets');
      setDatasets(response);
      console.log('Datasets:', response);
    } catch (error) {
      console.error('Error fetching datasets:', error);
    }
  };
  const handleDownload = async (datasetId: string) => {
    try {
      const response = await api.get(`/sample-datasets/${datasetId}/download`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${datasetId}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading dataset:', error);
    }
  };
  return (
    <div style={{ height: '100vh', overflow: 'auto', padding: '20px' }}>
      <h1>Sample Datasets</h1>
      <button 
        onClick={fetchDatasets}
        style={{
          padding: '10px 20px',
          margin: '20px 0',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        Get Sample Info
      </button>
      {datasets && (
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '2px solid #dee2e6' }}>Dataset Name</th>
                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '2px solid #dee2e6' }}>Description</th>
                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '2px solid #dee2e6' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(datasets).map(([id, dataset]: [string, any]) => (
                <tr key={id} style={{ borderBottom: '1px solid #dee2e6' }}>
                  <td style={{ padding: '12px' }}>{dataset.name}</td>
                  <td style={{ padding: '12px' }}>{dataset.description}</td>
                  <td style={{ padding: '12px' }}>
                    <button
                      onClick={() => handleDownload(id)}
                      style={{
                        padding: '6px 12px',
                        backgroundColor: '#28a745',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                      }}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default SamplesData;