import React, { useEffect, useState } from 'react';
import { useVideoProcessing } from '../RealtimeInferencingPageUDP/hooks/useVideoProcessing';
import { api } from '../../../helpers/backendHelper';


// let CROP_REGION = {
//   width: 1009,
//   height: 694,
//   x: 230,  
//   y: 220  
// };


// let CROP_REGION = {
//   width: 1010,
//   height: 720,
//   x: 420,
//   y: 120
// };


interface CropBox {
  left: number;
  upper: number;
  right: number;
  lower: number;
}

interface CropRegion {
  width: number;
  height: number;
  x: number;
  y: number;
}

const convertBackendCropToFrontend = (cropBox: [number, number, number, number]): CropRegion => {
  const [left, upper, right, lower] = cropBox;
  return {
    width: right - left,
    height: lower - upper,
    x: left,
    y: upper
  };
};

let CROP_REGION = convertBackendCropToFrontend([420, 120, 1430, 840]);

const MACHINE_TYPES = [
  'voluson_p8',
  'voluson_s10',
  'voluson_s8',
  'voluson_e6',
  'voluson_e10',
  'versana',
  'affiniti70',
  'mindray_dc_40',
];


const DASH_POSITIONS = ['left', 'bottom'];
const DEPTH_INFO_POSITIONS = ['right', 'left'];


const PixelTest: React.FC = () => {

  console.log("============= PixelTest", CROP_REGION);
  
  const [detectionResults, setDetectionResults] = useState<{
    pixel_spacing: number;
    zoom: number;
    depth: number;
  } | null>(null);

  const [isDetecting, setIsDetecting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [machineType, setMachineType] = useState<string>(MACHINE_TYPES[0]);
  const [dashPosition, setDashPosition] = useState<string>(DASH_POSITIONS[0]);
  const [depthInfoPosition, setDepthInfoPosition] = useState<string>(DEPTH_INFO_POSITIONS[0]);

  const [sourceType, setSourceType] = useState<'USB' | 'Share'>('USB');
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string>('');
  const [isScreenSharing, setIsScreenSharing] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [originalDimensions, setOriginalDimensions] = useState<{width: number, height: number} | null>(null);

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const croppedVideoRef = React.useRef<HTMLVideoElement>(null);
    
  const { getVideoStream } = useVideoProcessing();

  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const captureFrameFromCroppedVideo = () => {
    if (croppedVideoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = CROP_REGION.width;
      canvas.height = CROP_REGION.height;
      
      const ctx = canvas.getContext('2d', { 
        alpha: false,  // Disable alpha channel
        willReadFrequently: true  // Optimize for pixel manipulation
      });
      
      if (ctx) {
        // Set image smoothing quality to high
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        
        ctx.drawImage(croppedVideoRef.current, 0, 0);
        
        // Use higher quality JPEG instead of PNG
        const imageUrl = canvas.toDataURL('image/jpeg', 1.0); // 1.0 is maximum quality
        setCapturedImage(imageUrl);
      }
    }
  };


  const captureFrame = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      // Use full video dimensions
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      
      const ctx = canvas.getContext('2d', { 
        alpha: false,
        willReadFrequently: true
      });
      
      if (ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        
        // Draw full video frame
        ctx.drawImage(
          videoRef.current,
          0, 0,
          videoRef.current.videoWidth, videoRef.current.videoHeight,
          0, 0,
          canvas.width, canvas.height
        );
        
        // Add dimension validation here
        if (canvas.width < 500 || canvas.height < 500) {
          console.warn('Captured image dimensions might be too small for accurate processing');
          // Optionally, you could also:
          // - Show a warning to the user
          // - Prevent the capture
          // - Add a notification
          return;  // Optional: prevent small image capture
        }
        
        const imageUrl = canvas.toDataURL('image/jpeg', 1.0);
        setCapturedImage(imageUrl);
      }
    }
  };

  const detectPixelSpacing = async () => {
    if (!capturedImage) return;
  
    try {
      setIsDetecting(true);
      setError(null);
      setDetectionResults(null);
  
      const response = await fetch(capturedImage);
      const blob = await response.blob();
  
      const formData = new FormData();
      formData.append('image', blob, `capture_${Math.random().toString(36).substring(2, 15)}.jpg`);
      formData.append('machine_type', machineType);
      formData.append('machine_dash_position', dashPosition);
      formData.append('machine_depthinfo_position', depthInfoPosition);
      formData.append('crop', 'true');
  
      const result = await api.post('/pixel_spacing_detection', formData);
      console.log("============= result", result);
      
      if (result && typeof result.pixel_spacing === 'number') {
        setDetectionResults(result);
        setError(null);
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error detecting pixel spacing:', error);
      setDetectionResults(null);
      setError(error instanceof Error ? error.message : 'Failed to detect pixel spacing');
    } finally {
      setIsDetecting(false);
    }
  };

  // Get available video devices
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
      })
      .catch(err => console.error('Error accessing media devices:', err));
  }, []);

  const handleDeviceChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const deviceId = event.target.value;
    setSelectedDevice(deviceId);
    setIsScreenSharing(false);
    
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }

    if (deviceId) {
      try {
        const newStream = await getVideoStream(deviceId, sourceType);
        setStream(newStream);
        updateVideoStreams(newStream);
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    }
  };


  const handleStopStream = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
      setIsScreenSharing(false);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      if (croppedVideoRef.current) {
        croppedVideoRef.current.srcObject = null;
      }
    }
  };


  const handleSourceTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSourceType = e.target.value as 'USB' | 'Share';
    setSourceType(newSourceType);
  
    // Stop existing stream
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  
    // If switching to screen share, initiate it
    if (newSourceType === 'Share') {
      try {
        const newStream = await getVideoStream('', 'Share');
        setStream(newStream);
        setIsScreenSharing(true);
        updateVideoStreams(newStream);
      } catch (err) {
        console.error('Error sharing screen:', err);
        setIsScreenSharing(false);
      }
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);


  const updateVideoStreams = (newStream: MediaStream) => {
    if (videoRef.current) {
      videoRef.current.srcObject = newStream;
      
      videoRef.current.onloadedmetadata = () => {
        const sourceWidth = videoRef.current!.videoWidth;
        const sourceHeight = videoRef.current!.videoHeight;
        console.log('Original Video Dimensions:', { width: sourceWidth, height: sourceHeight });
        setOriginalDimensions({ width: sourceWidth, height: sourceHeight });

        // Calculate center crop region
        CROP_REGION = {
          width: CROP_REGION.width,
          height: CROP_REGION.height,
          x: (sourceWidth - CROP_REGION.width) / 2,  // Center horizontally
          y: (sourceHeight - CROP_REGION.height) / 2   // Center vertically
        };

      };
    }
  
    // Create cropped stream
    if (croppedVideoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = CROP_REGION.width;
      canvas.height = CROP_REGION.height;
      
      const ctx = canvas.getContext('2d');
      if (ctx && videoRef.current) {
        videoRef.current.onplay = () => {
          const drawFrame = () => {
            ctx.drawImage(
              videoRef.current!, 
              CROP_REGION.x, CROP_REGION.y,           // Source x,y
              CROP_REGION.width, CROP_REGION.height,  // Source width,height
              0, 0,                                   // Destination x,y
              CROP_REGION.width, CROP_REGION.height      // Destination width,height
            );
            requestAnimationFrame(drawFrame);
          };
          requestAnimationFrame(drawFrame);
        };
      }
      
      const croppedStream = canvas.captureStream();
      croppedVideoRef.current.srcObject = croppedStream;
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'auto' }}>
      <div style={{ marginBottom: '1rem' }}>
        <select 
          value={sourceType}
          onChange={handleSourceTypeChange}
          style={{ marginRight: '1rem' }}
        >
          <option value="USB">Camera</option>
          <option value="Share">Screen Share</option>
        </select>

        <select 
          value={selectedDevice} 
          onChange={handleDeviceChange}
          disabled={sourceType === 'Share'}
          style={{ marginRight: '1rem' }}
        >
          <option value="">Select a camera</option>
          {devices.map(device => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${device.deviceId}`}
            </option>
          ))}
        </select>

        {stream && (
          <button 
            onClick={handleStopStream}
            style={{ marginLeft: '1rem' }}
          >
            Stop Stream
          </button>
        )}
      </div>

      <div style={{ 
      display: 'grid', 
      gridTemplateColumns: '1fr 1fr',
      gap: '1rem',
      marginTop: '1rem'
    }}>
      {/* Original video stream */}

      <div>
        <p>Original Stream {originalDimensions && `(${originalDimensions.width}x${originalDimensions.height})`}</p>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          style={{ width: '100%', maxWidth: '640px' }}
        />
      </div>

      {/* Cropped video stream */}
      <div>
        <p>{CROP_REGION.width}x{CROP_REGION.height}</p>
          <video
            ref={croppedVideoRef}
            autoPlay
            playsInline
            style={{ 
              width: '100%',
              maxWidth: CROP_REGION.width,
              border: '1px solid #ccc'
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: '1rem' }}>
        <select 
          value={machineType}
          onChange={(e) => setMachineType(e.target.value)}
          style={{ marginRight: '1rem' }}
        >
          {MACHINE_TYPES.map(type => (
            <option key={type} value={type}>
              {type.replace(/_/g, ' ').toUpperCase()}
            </option>
          ))}
        </select>

        <select 
          value={dashPosition}
          onChange={(e) => setDashPosition(e.target.value)}
          style={{ marginRight: '1rem' }}
        >
          {DASH_POSITIONS.map(pos => (
            <option key={pos} value={pos}>
              Dash Position: {pos.charAt(0).toUpperCase() + pos.slice(1)}
            </option>
          ))}
        </select>

        <select 
          value={depthInfoPosition}
          onChange={(e) => setDepthInfoPosition(e.target.value)}
          style={{ marginRight: '1rem' }}
        >
          {DEPTH_INFO_POSITIONS.map(pos => (
            <option key={pos} value={pos}>
              Depth/Zoom Info: {pos.charAt(0).toUpperCase() + pos.slice(1)}
            </option>
          ))}
        </select>
      </div>

      <div>
        <button 
          onClick={captureFrame}
          disabled={!stream}
          style={{ marginBottom: '1rem' }}
        >
          Capture Frame
        </button>
        <button 
          onClick={detectPixelSpacing}
          disabled={!capturedImage || isDetecting}  // Disable while detecting
          style={{ 
            marginBottom: '1rem',
            marginLeft: '1rem',
            padding: '8px 16px',
            backgroundColor: capturedImage && !isDetecting ? '#4CAF50' : '#ccc',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: capturedImage && !isDetecting ? 'pointer' : 'not-allowed',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          {isDetecting ? (
            <>
              <div 
                style={{ 
                  width: '16px', 
                  height: '16px', 
                  border: '2px solid #ffffff',
                  borderTop: '2px solid transparent',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite',
                }}
              />
              Detecting...
            </>
          ) : (
            'Detect Pixel Spacing'
          )}
        </button>
        
        <div style={{
          height: '100vh',
          overflow: 'auto',  // Enable scrolling for the entire page
          padding: '2rem'
        }}>
          {capturedImage && (
            <div style={{ 
              marginBottom: '2rem',
              display: 'flex',
              gap: '2rem',
              alignItems: 'flex-start'
            }}>
              <div style={{
                flex: '1',
                maxHeight: '80vh',  // Limit height to 80% of viewport
                overflow: 'auto'    // Enable scrolling for the image container
              }}>
                <img 
                  src={capturedImage} 
                  style={{ 
                    width: '100%',
                    height: 'auto',
                    display: 'block'  // Remove any extra spacing below image
                  }} 
                  alt="Captured frame" 
                />
              </div>
              

              <div style={{ 
                minWidth: '300px',
                position: 'sticky',
                top: '2rem'
              }}>
                {isDetecting ? (
                  <div style={{
                    padding: '1rem',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                    textAlign: 'center'
                  }}>
                    <div style={{ marginBottom: '0.5rem' }}>Processing image...</div>
                    <div 
                      style={{ 
                        width: '24px', 
                        height: '24px', 
                        border: '3px solid #4CAF50',
                        borderTop: '3px solid transparent',
                        borderRadius: '50%',
                        animation: 'spin 1s linear infinite',
                        margin: '0 auto'
                      }}
                    />
                  </div>
                ) : error ? (
                  <div style={{
                    padding: '1rem',
                    backgroundColor: '#ffebee',
                    borderRadius: '4px',
                    color: '#c62828'
                  }}>
                    <h3 style={{ marginTop: 0, marginBottom: '0.5rem' }}>Error:</h3>
                    <div>{error}</div>
                  </div>
                ) : detectionResults && (
                  <div style={{
                    padding: '1rem',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px'
                  }}>
                    <h3 style={{ marginTop: 0, marginBottom: '1rem' }}>Detection Results:</h3>
                    <div style={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem' 
                    }}>
                      <div>
                        <strong>Pixel Spacing:</strong>
                        <div>{detectionResults.pixel_spacing.toFixed(4)}</div>
                      </div>
                      <div>
                        <strong>Zoom:</strong>
                        <div>{detectionResults.zoom?.toFixed(2) || 'N/A'}</div>
                      </div>
                      <div>
                        <strong>Depth:</strong>
                        <div>{detectionResults.depth?.toFixed(2) || 'N/A'}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};

export default PixelTest;