import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrag } from 'react-dnd';
import { Icon } from '../';
import { StringNumber } from '../../types';
import { Button } from 'antd';
import { SiCodemagic } from 'react-icons/si';

/**
 * Display a thumbnail for a display set.
 */
const Thumbnail = ({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  seriesNumber,
  numInstances,
  countIcon,
  dragData,
  isActive,
  onClick,
  onDoubleClick,
  currentSeriePredictionState,
  sr_payload,
  generateSRHandler,
  isGenerating,

}): React.ReactNode => {
  // TODO: We should wrap our thumbnail to create a "DraggableThumbnail", as
  // this will still allow for "drag", even if there is no drop target for the
  // specified item.
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function (monitor) {
      return Object.keys(dragData).length !== 0;
    },
  });

  const renderStatusButton = () => {
    if (status === 'PREDICTED') {
      return (
        <span className="rounded-md border border-[#40d672] bg-[#caf4d8] px-1 text-xs text-[#40d672]">
          Predicted
        </span>
      );
    }

    if (status === 'INPROGRESS' || isGenerating) {
      return (
        <span className="rounded-md border border-[#f4944e] bg-[#f7e2c2] px-1 text-xs text-[#f4944e]">
          In Progress
        </span>
      );
    }

    return (
      <span
        className="inline-flex items-center justify-center space-x-2 rounded-md border bg-[#2886c7] px-1.5 py-0.5 text-xs text-white hover:bg-white hover:text-[#2886c7]"
        onClick={generateSRHandler}
      >
        <SiCodemagic />
        <span>Predict</span>
      </span>
    );
  };


  return (
    <div
      className={classnames(
        className,
        // 'group mb-8 flex flex-1 cursor-pointer select-none flex-col px-3 outline-none'
        'group w-full cursor-pointer select-none flex-col space-x-3 px-2 outline-none'
      )}
      id={`thumbnail-${displaySetInstanceUID}`}
      data-cy={`study-browser-thumbnail`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      role="button"
      tabIndex="0"
    >
      <div
        ref={drag}
        className="inline-flex space-x-2"
      >
        {/* <div
          className={classnames(
            'min-h-32 flex flex-1 items-center justify-center overflow-hidden rounded-md bg-black text-base text-white',
            isActive
              ? 'border-primary-light border-2'
              : 'border-secondary-light border hover:border-blue-300'
          )}
          style={{
            margin: isActive ? '0' : '1px',
          }}
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={imageAltText}
              className="min-h-32 object-none"
              crossOrigin="anonymous"
            />
          ) : (
            <div>{imageAltText}</div>
          )}
        </div> */}
        <div className="">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={imageAltText}
              className="h-12 w-12 rounded-md object-none"
              crossOrigin="anonymous"
            />
          ) : (
            <div>{imageAltText}</div>
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <div className="inline-flex items-center justify-center space-x-2 text-sm font-semibold text-[#21426d]">
            <span>Serie {seriesNumber}</span>
            {renderStatusButton()}
          </div>
          <span className="text-xs text-[#a6abc8]">Number of frames {numInstances}</span>
        </div>

      </div>

    </div>
  );
};

Thumbnail.propTypes = {
  displaySetInstanceUID: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  /**
   * Data the thumbnail should expose to a receiving drop target. Use a matching
   * `dragData.type` to identify which targets can receive this draggable item.
   * If this is not set, drag-n-drop will be disabled for this thumbnail.
   *
   * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
   */
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  imageAltText: PropTypes.string,
  description: PropTypes.string.isRequired,
  seriesNumber: StringNumber.isRequired,
  numInstances: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};

Thumbnail.defaultProps = {
  dragData: {},
};

export default Thumbnail;
