// Study
export const GET_STUDIES = '/studies';
export const STUDY_ADD = '/studies';
export const GET_PATIENT_STUDIES = '/studies/patient';
export const ADD_STUDY_2 = '/study';
export const ADD_STUDY = '/dcm4chee-arc/aets/DCM4CHEE/rs/studies_from_deepecho';

export const PREDICT = '/api/reports/create';
export const GET_REPORT = '/study_report/report';
export const GET_STUDY = '/api/studies';
export const DELETE_REPORT = '/api/reports/delete';
export const DELETE_STUDY = '/api/studies/delete';
export const ARCHIVE_STUDY = '/studies/archive';
export const UNARCHIVE_STUDY = '/studies/unarchive';

export const GET_GLOBAL_REPORT = '/report';

export const GET_REPORTS = '/api/reports?page=0&size=1';
export const GET_ALL_REPORTS = '/study_report/all';
export const GET_STATE_REPORT = '/study_report';
export const UPDATE_STATE_REPORT = '/study_reports';
export const FINISH_REPORT = '/study_report/complete';

// Profile
export const POST_GET_JWT_PROFILE = '/users/me';

// SR
export const GENERATE_SR = '/model_api/generate_sr';

// Patient
export const GET_PATIENTS = '/patients';
export const POST_PATIENT = '/patients';
export const PUT_PATIENT = '/patients';
export const ARCHIVE_PATIENT = '/patients/archive';
export const UNARCHIVE_PATIENT = '/patients/unarchive';
// Pixel Spacing
export const GET_PIXEL_SPACING = '/pixelspacing';
