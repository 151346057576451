import React, { useMemo, useState, useEffect } from 'react';
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';

import { usePaginatedPatients } from '../../../../helpers/api/patients/patients';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import MinimunPatientForm from '../MinimunPatientForm';
import { Patient } from '../../../Sections/PatientsList/patientsList.section.custom';
import { useDebouncedCallback } from 'use-debounce';


interface PatientTableSelectionProps {
  studyUID: string;
  onPatientSelect: (
    studyUID: string,
    patient: {
      patient_id?: string;
      patient_name?: string;
    }
  ) => void;
}

function PatientTableSelection({ studyUID, onPatientSelect }: PatientTableSelectionProps) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 6,
  });

  const [filters, setFilters] = useState({});
  const [searchMode, setSearchMode] = useState('OR');

  const buildFilters = (filters, searchMode) => {
    const updatedFilters = {};

    Object.keys(filters).forEach(key => {
      const newKey = `${key}__ilike`;
      const newValue = `%${filters[key]}%`;
      updatedFilters[newKey] = newValue;
    });

    if (searchMode === 'OR') {
      return { condition__or: updatedFilters };
    }

    return updatedFilters;
  };

  const { data, isError, isLoading, refetch } = usePaginatedPatients(
    pagination.pageIndex + 1,
    pagination.pageSize,
    {
      archived: false,
      ...(Object.keys(buildFilters(filters, searchMode)).length > 0 && {
        filters: JSON.stringify(buildFilters(filters, searchMode)),
      }),
    },
    { refetchInterval: 60000 }
  );

  const handleFilterChange = e => {
    console.log("==================== oo", e.target.value);
    
    const { value } = e.target;

    setFilters(prev => {
      const updatedFilters = { ...prev };

      if (value === '') {
        delete updatedFilters['patient_name'];
        delete updatedFilters['patient_last_name']; 
        delete updatedFilters['email'];
      } else {
        updatedFilters['patient_name'] = value;
        updatedFilters['patient_last_name'] = value;
        updatedFilters['email'] = value;
      }

      console.log("================= updatedFilters ", updatedFilters);
      
      return updatedFilters;
    });
  };


  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'patient_last_name',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
      },
      {
        accessorKey: 'patient_name',
        cell: info => info.getValue(),
        header: () => <span>First Name</span>,
      },
      {
        accessorKey: 'email',
        cell: info => info.getValue(),
        header: () => <span>Email</span>,
      },
      {
        accessorKey: 'birth_date',
        cell: info => formatBirthDate(info.getValue()),
        header: () => <span>Date Of Birth</span>,
      },
      {
        accessorKey: 'phone_number',
        cell: info => info.getValue(),
        header: () => <span>Phone Number</span>,
      },
      {
        accessorKey: 'policy_number',
        cell: info => info.getValue(),
        header: () => <span>Policy Number</span>,
      },
    ],
    []
  );
  function formatBirthDate(birthDate: any) {
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(birthDate).toLocaleDateString('en-US', options);
  }
  const table = useReactTable({
    data: (data?.items || (data as any)?.data?.items) ?? [],
    columns,
    rowCount: (data?.total_count || (data as any)?.data?.total_count) ?? 0,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const handleRowClick = (row: any) => {
    console.log('row', row);
    const patient = {
      patient_id: row.original.id,
      patient_name: `${row.original.patient_name} ${row.original.patient_last_name}`.trim(),
    };

    onPatientSelect(studyUID, patient);
  };
  const handlePatientAddClick = (patient: Patient) => {
    const patientData = {
      patient_id: String(patient.id),
      patient_name: `${patient.patient_name} ${patient.patient_last_name}`.trim(),
    };
    onPatientSelect(studyUID, patientData);
  };

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 })); // Reset to first page when filters change
    refetch();
  }, [filters]);
  
  const debouncedFilterChange = useDebouncedCallback(handleFilterChange, 500);

  return (
    <>
      <div className="rounded-md bg-[#F7FAFE] p-2">
        <div className="flex w-full items-center justify-between pb-1">
          <div className="text-lg font-medium text-gray-900">Select Patient</div>
          <div className="flex space-x-2">
            <input
              type="text"
              onChange={debouncedFilterChange}
              placeholder="Search Patients..."
              className="h-7 w-full rounded-md border p-1 focus:outline-none focus:ring-0"
            />
          </div>
        </div>
        <div className="w-full">
          <MinimunPatientForm handlePatientAddClick={handlePatientAddClick} />
        </div>
        <table className="w-full border-collapse rounded-md border border-gray-200 text-sm">
          <thead className="h-10 border-t border-dashed border-gray-200 bg-gray-100 p-2 text-left text-gray-500">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    className="p-2"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-sm font-medium leading-5 text-[#686C82]">
            {isLoading ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center"
                >
                  <Spin size="large" /> {/* Loading spinner */}
                  <div>Loading patients...</div>
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center text-red-500"
                >
                  Error loading patients. Please try again.
                </td>
              </tr>
            ) : table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map(row => (
                <tr
                  id={`table-row-${row.id}`}
                  key={row.id}
                  className="h-10 cursor-pointer border-t border-b border-gray-200 hover:bg-blue-100"
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="p-2"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex items-center justify-end gap-2 pt-1">
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
            </strong>
          </span>
          <button
            className="text-[14px] font-semibold text-[#005DD4]"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <LeftOutlined />
          </button>
          <button
            className="text-[14px] font-semibold text-[#005DD4]"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <RightOutlined />
          </button>
        </div>
      </div>
    </>
  );
}

export default PatientTableSelection;
