import { apiClient } from '../../apiHelper';
import { useCreate, useEdit, usePaginated, useSingleFetch } from '../react-query';
import { usersQueryKeys } from './users-query-keys';

interface User {
  id: number;
  uid: string;
  name: string;
  username: string;
  email: string;
  avatar?: string;
  role: string;
  is_active: boolean;
  is_superuser: boolean;
  authentik_pk: number;
  last_login?: Date;
}

interface PaginatedResponse<T> {
  data: {
    items: T[];
    total: number;
    page: number;
    items_per_page: number;
  };
}

interface PasswordResetData {
  uid: string;
  form: {
    password: string;
    password_confirm: string;
  };
}

interface UserProfile {
  id: number;
  name: string;
  username: string;
  avatar: string;
  email: string;
  uid: string;
  authentik_pk: number;
  is_superuser: boolean;
  is_active: boolean;
  last_login: string; // You can use Date type depending on how you want to handle the date
  roles: string[];
}
interface ChangeRoleData {
  uid: string;
  role: string;
}

export function getProfile(config = {}) {
  const getProfileFn = async (): Promise<UserProfile> => {
    const response = await apiClient.get(`users/getProfile`);
    return response;
  };

  return useSingleFetch(getProfileFn, usersQueryKeys.detail('profile'), 'profile', config);
}
export function useSingleUser(uid: string, config = {}) {
  const getUserFn = async (): Promise<User> => {
    const response = await apiClient.get<User>(`users/${uid}`);
    return response;
  };

  return useSingleFetch(getUserFn, usersQueryKeys.detail(uid), uid, config);
}

export function useMe(config = {}) {
  const getMeFn = async (): Promise<User> => {
    const response = await apiClient.get<User>(`users/me`);
    return response;
  };

  return useSingleFetch(getMeFn, usersQueryKeys.detail('me'), 'me', config);
}

// export function usePaginatedUsers(
//   page: number,
//   itemsPerPage: number,
//   filters: Record<string, string> = {},
//   config = {}
// ) {
//   const getPaginatedUsersFn = async (
//     p: number = page,
//     f: Record<string, string> = filters
//   ): Promise<PaginatedResponse<User>> => {
//     const filtersQuery = Object.keys(f)
//       .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(f[key])}`)
//       .join('&');
//     const url = `users/?page=${p}&items_per_page=${itemsPerPage}${
//       filtersQuery ? `&${filtersQuery}` : ''
//     }`;

//     const response = await apiClient.get<PaginatedResponse<User>>(url);
//     return response.data;
//   };

//   return usePaginated(getPaginatedUsersFn, usersQueryKeys.pagination(page), page, config);
// }

export function usePaginatedUsers(
  page: number,
  itemsPerPage: number,
  filters: Record<string, any> = {},
  config = {}
) {
  const getPaginatedUsersFn = async (
    p: number = page,
    f: Record<string, any> = filters
  ): Promise<PaginatedResponse<User>> => {
    let url = `users/?page=${p}&items_per_page=${itemsPerPage}`;

    // Add filters to URL if they exist
    if (f.filters) {
      url += `&filters=${encodeURIComponent(f.filters)}`;
    }

    const response = await apiClient.get<PaginatedResponse<User>>(url);
    return response.data;
  };

  return usePaginated(
    () => getPaginatedUsersFn(page, filters),
    usersQueryKeys.pagination(page, filters),
    page,
    config
  );
}

export function usePasswordRecovery() {
  const passwordRecoveryFn = async (uid: string): Promise<void> => {
    const response = await apiClient.post(`users/recovery/${uid}`);
    return response;
  };

  return useEdit(
    passwordRecoveryFn,
    usersQueryKeys.all,
    'Error while performing password recovery',
    'Password recovery link sent successfully'
  );
}

export function usePasswordReset() {
  const passwordResetFn = async (data: {
    password: string;
    password_confirm: string;
  }): Promise<void> => {
    const response = await apiClient.post(`users/reset_password`, data);
    return response;
  };

  return useEdit(
    passwordResetFn,
    usersQueryKeys.all,
    'Error while performing password reset',
    'Password reset successfully'
  );
}

export function useUserPasswordReset() {
  const passwordResetFn = async (data: PasswordResetData): Promise<void> => {
    const response = await apiClient.post(`users/reset_password/${data.uid}`, data.form);
    return response;
  };

  return useEdit(
    passwordResetFn,
    usersQueryKeys.all,
    'Error while performing password reset',
    'Password reset successfully'
  );
}

export function useChangeRoleUser() {
  const changeRoleUserFn = async (data: ChangeRoleData): Promise<void> => {
    const response = await apiClient.post(`users/roles/${data.uid}/${data.role}`);
    return response;
  };

  return useEdit(
    changeRoleUserFn,
    usersQueryKeys.all,
    'Error while updating role of User',
    'Role updated successfully'
  );
}

export function useActivateUser(config: { onSuccess?: () => void; onError?: () => void } = {}) {
  const activateUserFn = async (uid: string): Promise<void> => {
    const response = await apiClient.post(`users/activate/${uid}`);
    return response;
  };

  return useEdit(
    activateUserFn,
    usersQueryKeys.all,
    'Error while activating User',
    'User activated successfully',
    config.onSuccess,
    config.onError
  );
}

export function useDeactivateUser(config: { onSuccess?: () => void; onError?: () => void } = {}) {
  const deactivateUserFn = async (uid: string): Promise<void> => {
    const response = await apiClient.post(`users/deactivate/${uid}`);
    return response;
  };

  return useEdit(
    deactivateUserFn,
    usersQueryKeys.all,
    'Error while deactivating User',
    'User deactivated successfully',
    config.onSuccess,
    config.onError
  );
}

export function useCreateUser() {
  const createUserFn = async (data: Record<string, unknown>) => {
    const response = await apiClient.post(`users/create`, data);
    return response;
  };

  return useCreate(
    createUserFn,
    [
      ...usersQueryKeys.all.map(String),
    ],
    'Error while creating user',
    'User created successfully'
  );
}
