// GlobalStateContext.js
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { getUser } from '../helpers/apiHelper';
import {
  decodeRLE,
  drawMask,
} from '../UI/Pages/RealtimeInferencingPageUDP/components/reusable-functions/reusable-functions';
import { CATEGORIES } from '../UI/Pages/RealtimeInferencingPageUDP/vars';
import { INITIAL_GLOBAL_STATE, VIDEO_DIMENSIONS } from '../UI/Pages/RealtimeInferencingPageUDP/constants';


const GlobalStateContext = createContext(undefined);

export const RealtimeGlobalStateProvider = ({ children }) => {
  const [clientId, setClientId] = useState(null);
  const websocketRef = useRef(null);
  const API = process.env.BACKEND_URL.replace(/\/api\/v1$/, '');
  const [receivedData, setReceivedData] = useState(null);

  function createBlobUrlFromCanvasSync(base64Img, width, height, uniqueReport, callback) {
    console.log("==================== base64Img", width, height);
    
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');
    offscreenCanvas.width = width;
    offscreenCanvas.height = height;
  
    const img = new Image();
    img.src = base64Img;
    console.log("width and heigh",offscreenCanvas.width,offscreenCanvas.height)
    img.onload = () => {
      offscreenCtx.drawImage(img, 0, 0, width, height);

      // Draw the masks
      uniqueReport.forEach(result => {
        const category = CATEGORIES.find(category => category.id === result.label);
        drawMask(
          offscreenCtx,
          decodeRLE(result.mask_realtime, width, height),
          category.color,
          category.name
        );
      });

      // Generate the Blob URL and call the callback with it
      offscreenCanvas.toBlob(blob => {
        if (blob) {
          const blobUrl = URL.createObjectURL(blob);
          callback(blobUrl); // Return the Blob URL via the callback
        } else {
          console.error('Blob creation failed');
          callback(null); // In case of failure
        }
      }, 'image/png'); // You can choose other formats if needed
    };
  }

  useEffect(() => {
    if (true) {
      const user = getUser();
      const token = user?.access_token;

      // Create WebSocket with reconnection logic
      websocketRef.current = new ReconnectingWebSocket(
        `${API.replace('http', 'ws')}/ws/?token=${token}`,
        [],
        {
          maxRetries: 10, // Max attempts to reconnect
          connectionTimeout: 1000,
        }
      );

      websocketRef.current.onopen = () => {
        websocketRef.current.send(
          JSON.stringify({
            event: 'add_client',
            data: {
              client_id: clientId,
            },
          })
        );
      };

      websocketRef.current.onmessage = event => {
        const receivedData = JSON.parse(event.data);
        console.log("============================ receivedData", receivedData);
        setReceivedData(receivedData)
      };

      // Handle connection closure with reconnection
      websocketRef.current.onclose = event => {
        console.warn('WebSocket connection closed:', event);
        setGlobalState(prevState => ({
          ...prevState,
          encounterError: true,
        }));
        if (event.code !== 1000) {
          // Code 1000 means normal closure; otherwise, reconnect
          console.log('Attempting to reconnect WebSocket...');
        }
      };

      // Handle WebSocket error
      websocketRef.current.onerror = error => {
        console.error('WebSocket encountered an error:', error);
        setGlobalState(prevState => ({
          ...prevState,
          encounterError: true,
        }));
      };
    }

    return () => websocketRef.current?.close();
  }, []);

  const [globalState, setGlobalState] = useState(INITIAL_GLOBAL_STATE);

  useEffect(() => {
    console.log('============ receivedData:', receivedData);
    const results = receivedData?.results;
    const qualityResults = receivedData?.quality_results;
    const qualityCritariaPrediction = [];
    const updateBool = (title, newBool) => {
      const item = qualityCritariaPrediction.find(item => item.title === title);
      if (item) {
        item.bool = newBool; // Update the bool value for the found item
      }
    };
    if (qualityResults && typeof qualityResults === 'object') {
      console.log('============ qualityResults:', qualityResults);
      let score = 0;
      let planeType = '';
      const qualityCriteria = {};
      let totalCount = 0;
      let structureToDraw = [];
      if ('STD_PLANE__abdominal' in qualityResults) {
        console.log('============ Processing abdominal plane');
        planeType = 'abdominal';
        totalCount = 4;
        qualityCritariaPrediction.push({ title: 'Stomach buble', bool: false });
        qualityCritariaPrediction.push({ title: 'Portal Sinus', bool: false });
        qualityCritariaPrediction.push({ title: 'Fetal Kidneys', bool: false });
        qualityCritariaPrediction.push({ title: 'Zoom > 50%', bool: false });
        if (qualityResults.QUAL_FLAG__abdo_stomach_bubble) {
          score += 25;
          qualityCriteria['QUAL_FLAG__abdo_stomach_bubble'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 6));
          updateBool('Stomach buble', true);
        }
        if (qualityResults.QUAL_FLAG__abdo_portal_sinus) {
          score += 25;
          qualityCriteria['QUAL_FLAG__abdo_portal_sinus'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 4));
          updateBool('Portal Sinus', true);
        }
        if (qualityResults.QUAL_FLAG__abdo_fetal_kidneys_not_visible) {
          score += 25;
          qualityCriteria['QUAL_FLAG__abdo_fetal_kidneys_not_visible'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 7));
          updateBool('Fetal Kidneys', true);
        }
        if (qualityResults.QUAL_VAL__ZOOM >= 0.5) {
          score += 25;
          qualityCriteria['QUAL_VAL__ZOOM'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 5));
          updateBool('Zoom > 50%', true);
        }
      } else if ('STD_PLANE__femur' in qualityResults) {
        console.log('============ Processing femur plane');
        planeType = 'femur';
        totalCount = 3;
        qualityCritariaPrediction.push({ title: 'Femural Angle', bool: false });
        qualityCritariaPrediction.push({ title: 'Both Extremities Visible', bool: false });
        qualityCritariaPrediction.push({ title: 'Zoom > 50%', bool: false });
        if (qualityResults.QUAL_VAL__FEMURAL_ANGLE) {
          score += 33.3;
          qualityCriteria['QUAL_VAL__FEMURAL_ANGLE'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 9));
          updateBool('Femural Angle', true);
        }
        if (qualityResults.QUAL_VAL__ZOOM >= 0.5) {
          score += 33.3;
          qualityCriteria['QUAL_VAL__ZOOM'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 9));
          updateBool('Zoom > 50%', true);
        }
        if (qualityResults.QUAL_VAL__BOTH_EXTREMITIES_VISIBLE) {
          score += 33.4;
          qualityCriteria['QUAL_VAL__BOTH_EXTREMITIES_VISIBLE'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 10));
          updateBool('Both Extremities Visible', true);
        }
      } else if ('STD_PLANE__cephalic' in qualityResults) {
        console.log('============ Processing cephalic plane');
        planeType = 'cephalic';
        totalCount = 4;
        qualityCritariaPrediction.push({ title: 'Cephal Thalamus', bool: false });
        qualityCritariaPrediction.push({ title: 'Cerebellum Not Visble', bool: false });
        qualityCritariaPrediction.push({ title: 'Cavum Septi Pellucidi', bool: false });
        qualityCritariaPrediction.push({ title: 'Zoom > 50%', bool: false });
        if (qualityResults.QUAL_FLAG__cephal_thalamus) {
          score += 25;
          qualityCriteria['QUAL_FLAG__cephal_thalamus'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 3));
          updateBool('Cephal Thalamus', true);
        }
        if (qualityResults.QUAL_FLAG_Cerebellum_not_visble) {
          score += 25;
          qualityCriteria['QUAL_FLAG_Cerebellum_not_visble'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 2));
          updateBool('Cerebellum Not Visble', true);
        }
        if (qualityResults.QUAL_FLAG_cavum_septi_pellucidi) {
          score += 25;
          qualityCriteria['QUAL_FLAG_cavum_septi_pellucidi'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 4));
          updateBool('Cavum Septi Pellucidi', true);
        }
        if (qualityResults.QUAL_VAL__ZOOM >= 0.5) {
          score += 25;
          qualityCriteria['QUAL_VAL__ZOOM'] = true;
          structureToDraw = structureToDraw.concat(results.filter(item => item.label === 0));
          updateBool('Zoom > 50%', true);
        }
      } else if ('STD_PLANE_af_pocket' in qualityResults) {
        console.log('============ Processing af_pocket plane');
        planeType = 'af_pocket';
        structureToDraw = structureToDraw.concat(results.filter(item => item.label === 12));
        // teh score??????????????????
      }
      if (score > 0) {
        console.log('============ Score:', score);
        console.log("==================== receivedData?.image", receivedData?.image);
        
        createBlobUrlFromCanvasSync("data:image/jpeg;base64," + receivedData?.image.data, receivedData?.image.width, receivedData?.image.height, structureToDraw, (blobUrl) => {
          setGlobalState((prevState) => {
            const currentHistory = Array.isArray(prevState.planes[planeType]?.predictionHistory)
              ? prevState.planes[planeType].predictionHistory
              : [];
        
            // Stop the process if the array already contains 5 elements
            if (currentHistory.length >= 5) {
              console.log('============ History limit reached');
              return prevState; // No changes to the state
            }
        
            const scoreFromQualityCriteria = Object.keys(qualityCriteria).length;
            const score = (scoreFromQualityCriteria * 100) / totalCount;
            
            console.log('============ Updating state with new score:', score);
            return {
              ...prevState,
              lastDetectedPlane: planeType,
              ratingScore: (scoreFromQualityCriteria * 100) / totalCount,
              planes: {
                ...prevState.planes,
                [planeType]: {
                  ...prevState.planes[planeType],
                  predictionHistory: [
                    ...currentHistory,
                    {
                      imagePredictedPlane: blobUrl,
                      prediction: receivedData,
                      score: score,
                      // lastDetectedPlane: planeType,
                      quality_criteria:qualityCritariaPrediction,
                    },
                  ],
                  frame: receivedData?.image,
                  qualityCriteria:
                    score > (prevState.planes[planeType]?.score || 0)
                      ? qualityCriteria
                      : prevState.planes[planeType]?.qualityCriteria || qualityCriteria,
                  score: score,
                  imgReport: blobUrl, // Set the imgReport with the Blob URL
                },

              }
            };
          });

          
        });
      } else {
        setGlobalState(prevState => ({
          ...prevState,
          ratingScore: 1
        }))
      }
    }
  }, [receivedData]);

  useEffect(() => {
    console.log('============ globalState:', globalState);
  }, [globalState.planes]);
  
  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
