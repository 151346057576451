import React, { useState, useCallback } from 'react';

interface ButtonWithCursorMovementProps {
  begin: boolean;
  setBegin: (value: boolean) => void;
  deviceId: string;
}

const ButtonWithCursorMovement: React.FC<ButtonWithCursorMovementProps> = ({ 
  begin, 
  setBegin, 
  deviceId 
}) => {
  const [isDragging, setIsDragging] = useState(false);

  // Memoize the click handler to prevent unnecessary re-renders
  const handleClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    if (!isDragging) {
      setBegin(!begin);
    }
  }, [isDragging, begin, setBegin]);

  // Handle mouse events to prevent unwanted clicks during drag
  const handleMouseDown = useCallback(() => setIsDragging(false), []);
  const handleMouseMove = useCallback(() => setIsDragging(true), []);
  const handleMouseUp = useCallback(() => setIsDragging(false), []);

  const buttonClasses = [
    // Position and layout
    'absolute',
    'inset-0',
    'z-10',
    
    // Flex properties
    'flex',
    'items-center',
    'justify-center',
    
    // Dimensions
    'h-full',
    'w-full',
    
    // Interaction
    'cursor-pointer',
    'select-none',
    'transition-all',
    'duration-200',
    'ease-in-out',
    
    // Visibility
    deviceId ? 'visible' : 'invisible',
    
    // Backdrop effect
    begin ? [
      'bg-black/30',
      'backdrop-blur-sm',
      'backdrop-filter',
    ].join(' ') : '',
    
    // Hover effect (only when not frozen)
    !begin ? 'hover:bg-black/10' : '',
    
  ].filter(Boolean).join(' ');

  return (
    <button
      className={buttonClasses}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      aria-label="Toggle video freeze"
      role="button"
      tabIndex={0}
      style={{
        WebkitTapHighlightColor: 'transparent', // Prevent tap highlight on mobile
        transform: 'translate3d(0, 0, 0)', // Force GPU acceleration
      }}
    />
  );
};

export default React.memo(ButtonWithCursorMovement); // Prevent unnecessary re-renders