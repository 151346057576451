import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { useAuth } from 'react-oidc-context';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Navbar: React.FC = () => {
  const auth = useAuth();
  const modalRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleLogout = () => {
    auth.signoutRedirect();
  };

  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center justify-between bg-white  py-3 pl-3">
      <div className="relative w-1/2"></div>
      <div className="flex w-1/3 items-center justify-end space-x-4 px-3 ">
        <div
          onClick={() => setOpen(prev => !prev)}
          className="flex h-full cursor-pointer items-center justify-center "
        >
          <div
            ref={modalRef}
            className="relative flex h-full w-full items-center justify-between space-x-3 rounded-lg bg-[#f8fbff] px-5 py-1.5 shadow-md"
          >
            <div className="flex items-center space-x-3">
              <div className="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-[#E3EDFA] font-medium text-[#1F384C]">
                {auth?.user?.profile?.name ? auth.user.profile.name.charAt(0) : 'N'}
              </div>
              <span className="text-sm font-medium text-[#1F384C]">
                {auth?.user?.profile?.name
                  ? 'Dr. ' + auth?.user?.profile?.name
                  : 'Not Authenticated'}
              </span>
            </div>
            <button className="text-[#8E94A6]">
              {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>

            {open && (
              <div
                className="absolute right-[0px] top-11 z-50 w-32 bg-white text-sm text-[#21426D] shadow-lg"
                onClick={e => e.stopPropagation()}
              >
                <ul>
                  <Link
                    to={`/profile/${auth?.user?.profile?.sub}`}
                    className="text-[#21426D] hover:text-blue-500"
                  >
                    <li
                      onClick={() => setOpen(false)}
                      className="cursor-pointer rounded py-2 px-3 hover:bg-[#E3EDFA] hover:text-blue-500 "
                    >
                      My Account{' '}
                    </li>
                  </Link>
                  <Link
                    to={`/profile/${auth?.user?.profile?.sub}#Settings  `}
                    className="text-[#21426D] hover:text-blue-500"
                  >
                    <li
                      onClick={() => setOpen(false)}
                      className="cursor-pointer rounded py-2 px-3 hover:bg-[#E3EDFA] hover:text-blue-500"
                    >
                      Settings{' '}
                    </li>
                  </Link>
                  {/* <Link
                    to="/settings"
                    className="text-[#21426D] hover:text-blue-500"
                  >
                    <li
                      onClick={() => setOpen(false)}
                      className="cursor-pointer rounded border-b-[1px] py-2 px-3 hover:bg-[#E3EDFA] hover:text-blue-500"
                    >
                      Help{' '}
                    </li>
                  </Link> */}
                  <li
                    onClick={handleLogout}
                    className="cursor-pointer rounded py-2 px-3 text-red-500 hover:bg-red-300"
                  >
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
