import React, { useState } from 'react';
import { Avatar, Tooltip, Select } from 'antd';
import { useMe, usePaginatedUsers } from '../../../helpers/api/users/users';
import { PaginationState } from '@tanstack/react-table';
import {
  useGrantPermission,
  usePermissionsUserPatient,
  useRevokePermission,
  useSinglePatientWithRelated,
  useTakeOwnership,
} from '../../../helpers/api/patients/patients';
import Modal from '../../Components/modal';
import { getInitials } from './utils';
import { Link } from 'react-router-dom';

const ManageAccess: React.FC = ({ isModalOpen, patient_id, setIsModalOpen }) => {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const me = useMe();
  const takeOwnership = useTakeOwnership();
  const grantPermission = useGrantPermission();
  const revokePermission = useRevokePermission();
  const [selectedUser, setSelectedUser] = useState(null);
  const users = usePaginatedUsers(
    pagination.pageIndex + 1,
    pagination.pageSize,
    {},
    { refetchInterval: 60000 }
  );

  const userPermissions = usePermissionsUserPatient(patient_id, selectedUser, {
    enabled: selectedUser ? true : false,
  });

  const patient = useSinglePatientWithRelated(patient_id, { enabled: isModalOpen });

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const groupedUsers = (related = []) => {
    const manage = related.filter(user => user.permission === 'can_manage');
    const access = related.filter(user => user.permission === 'can_access');
    return { manage, access };
  };

  const { manage, access } = groupedUsers(patient.data?.data.related);

  return (
    <>
      <Modal
        okButtonProps={{ disabled: true }}
        onClose={handleCancel}
        isOpen={isModalOpen}
        size={'small'}
        overlayClassName="top-0 right-0 left-0"
      >
        <Modal.Header
          title={
            <div className="flex w-full justify-between">
              <div className="flex w-full justify-start text-lg font-semibold">Manage Access</div>
            </div>
          }
          onClose={handleCancel}
          displayClose={true}
        />

        <Modal.Content>
          {patient.isLoading
            ? 'Loading patient info...'
            : patient.data?.data && (
                <>
                  <div className="flex items-center justify-between">
                    <div className="mb-3 flex items-center space-x-4">
                      <Avatar size="large">
                        {getInitials(
                          patient.data.data.patient_name,
                          patient.data.data.patient_last_name
                        )}
                      </Avatar>

                      <div>
                        <div className="text-lg font-bold">
                          {patient.data.data.patient_name} {patient.data.data.patient_last_name}
                        </div>
                        <div className="text-sm text-gray-500">
                          ID: #{patient.data.data.patient_id}
                        </div>
                        <div className="text-sm text-gray-500">
                          Doctor in charge:{' '}
                          {patient.data.data?.owner?.uid ? (
                            <Link to={`/profile/${patient.data.data.owner.uid}`}>
                              {patient.data.data.owner.name}
                            </Link>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {me.data?.data?.uid !== patient.data?.data?.owner?.uid ? (
                        <button
                          type="button"
                          onClick={() => {
                            takeOwnership.mutate(patient.data?.data?.id);
                          }}
                          className={`inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm ${takeOwnership.isPending ? 'cursor-not-allowed' : 'hover:bg-blue-600'}`}
                          disabled={takeOwnership.isPending}
                        >
                          Take Ownership
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            revokePermission.mutate({
                              patient_id,
                              user_uid: me.data?.data?.uid,
                              permission: 'owner',
                            });
                          }}
                          className={`inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm ${revokePermission.isPending ? 'cursor-not-allowed' : 'hover:bg-red-600'}`}
                          disabled={revokePermission.isPending}
                        >
                          Drop Ownership
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mt-2">
                      <div className="text-sm font-medium text-gray-600">Managers</div>
                      <Avatar.Group size={40}>
                        {manage.map(user => (
                          <Tooltip
                            key={user.user_uid}
                            className="cursor-pointer"
                            title={`${users.data?.items.find(u => u.uid === user.user_uid)?.name}`}
                            placement="top"
                          >
                            <Link to={`/profile/${user.user_uid}`}>
                              <Avatar style={{ backgroundColor: '#87d068' }}>
                                {getInitials(user.user_uid)}
                              </Avatar>
                            </Link>
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                      {manage.length === 0 && (
                        <div className="text-sm text-gray-500">No managers</div>
                      )}
                    </div>

                    <div className="mt-4">
                      <div className="text-sm font-medium text-gray-600">Accessors</div>
                      <Avatar.Group size={40}>
                        {access.map(user => (
                          <Tooltip
                            key={user.user_uid}
                            className="cursor-pointer"
                            title={`${users.data?.items.find(u => u.uid === user.user_uid)?.name}`}
                            placement="top"
                          >
                            <Link to={`/profile/${user.user_uid}`}>
                              <Avatar style={{ backgroundColor: '#1890ff' }}>
                                {getInitials(user.user_uid)}
                              </Avatar>
                            </Link>
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                      {access.length === 0 && (
                        <div className="text-sm text-gray-500">No accessors</div>
                      )}
                    </div>
                  </div>
                </>
              )}

          {users.isLoading ? (
            'loading'
          ) : (
            <>
              <Select
                onChange={value => {
                  setSelectedUser(value);
                }}
                centered
                showSearch
                style={{ width: '100%' }}
                placeholder="Search to Select"
                optionFilterProp="label"
                options={users.data?.items
                  ?.filter(user => user.roles[0] === 'doctor')
                  .map(user => {
                    return { value: user.uid, label: user.name };
                  })}
              />
              {selectedUser && !userPermissions.isLoading ? (
                <ul className="mt-2 space-y-4">
                  <li className="flex items-center justify-between">
                    <span className="font-medium">OWNER</span>
                    <div className="space-x-2">
                      {userPermissions.data?.includes('*') ? (
                        <button
                          className="rounded-md bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                          disabled={grantPermission.isPending || revokePermission.isPending}
                          onClick={() => {
                            revokePermission.mutate({
                              patient_id,
                              user_uid: selectedUser,
                              permission: 'owner',
                            });
                          }}
                        >
                          Revoke
                        </button>
                      ) : (
                        <button
                          className="rounded-md bg-green-500 px-3 py-1 text-white hover:bg-green-600"
                          disabled={grantPermission.isPending || revokePermission.isPending}
                          onClick={() => {
                            grantPermission.mutate({
                              patient_id,
                              user_uid: selectedUser,
                              permission: 'owner',
                            });
                          }}
                        >
                          Grant
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="flex items-center justify-between">
                    <span className="font-medium">CAN MANAGE</span>
                    <div className="space-x-2">
                      {userPermissions.data?.includes('*') ? (
                        <button className="cursor-default rounded-md bg-green-500 px-3 py-1 text-white">
                          Inherited
                        </button>
                      ) : (
                        <>
                          {userPermissions.data?.includes('*') ||
                          userPermissions.data?.includes('can_manage') ? (
                            <button
                              className="rounded-md bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                              disabled={grantPermission.isPending || revokePermission.isPending}
                              onClick={() => {
                                revokePermission.mutate({
                                  patient_id,
                                  user_uid: selectedUser,
                                  permission: 'can_manage',
                                });
                              }}
                            >
                              Revoke
                            </button>
                          ) : (
                            <button
                              className="rounded-md bg-green-500 px-3 py-1 text-white hover:bg-green-600"
                              disabled={grantPermission.isPending || revokePermission.isPending}
                              onClick={() => {
                                grantPermission.mutate({
                                  patient_id,
                                  user_uid: selectedUser,
                                  permission: 'can_manage',
                                });
                              }}
                            >
                              Grant
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                  <li className="flex items-center justify-between">
                    <span className="font-medium">CAN ACCESS</span>
                    <div className="space-x-2">
                      {userPermissions.data?.includes('*') ||
                      userPermissions.data?.includes('can_manage') ? (
                        <button className="cursor-default rounded-md bg-green-500 px-3 py-1 text-white">
                          Inherited
                        </button>
                      ) : (
                        <>
                          {userPermissions.data?.includes('*') ||
                          userPermissions.data?.includes('can_access') ? (
                            <button
                              className="rounded-md bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                              disabled={grantPermission.isPending || revokePermission.isPending}
                              onClick={() => {
                                revokePermission.mutate({
                                  patient_id,
                                  user_uid: selectedUser,
                                  permission: 'can_access',
                                });
                              }}
                            >
                              Revoke
                            </button>
                          ) : (
                            <button
                              className="rounded-md bg-green-500 px-3 py-1 text-white hover:bg-red-600"
                              disabled={grantPermission.isPending || revokePermission.isPending}
                              onClick={() => {
                                grantPermission.mutate({
                                  patient_id,
                                  user_uid: selectedUser,
                                  permission: 'can_access',
                                });
                              }}
                            >
                              Grant
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ManageAccess;
