import React, { useEffect, useRef, useState } from 'react';
import { ImShrink2 } from 'react-icons/im';
import AlertView from '../alert-view';
import { LuLoader2 } from 'react-icons/lu';

const VideoModal = ({
  isOpen,
  onClose,
  videoStream,
  viseaRtcStream,
  globalState,
  displayDimensions,
  handleVideoClick,
  isInferencing,
  isChecked,
  handleChange,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const modalVideoRef = useRef(null);
  const modalWebrtcVideoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  useEffect(() => {
    if (modalVideoRef.current && videoStream) {
      modalVideoRef.current.srcObject = videoStream; // Set the stream to modal's video element
    }
    if (modalWebrtcVideoRef.current && viseaRtcStream) {
      modalWebrtcVideoRef.current.srcObject = viseaRtcStream; // Set the stream to modal's video element
    }
  }, [viseaRtcStream, videoStream, isOpen]); // Re-run this effect if the videoStream changes
  useEffect(() => {
    if (isInferencing) {
      setIsLoading(true); // Start loading
      const timeout = setTimeout(() => setIsLoading(false), 1200); // Simulate loading time
      return () => clearTimeout(timeout); // Cleanup timeout
    } else {
      setIsLoading(false);
    }
  }, [isInferencing, open]);

  if (!isOpen) {
    return null;
  } // Don't render the modal if it's not open
  return (
    <div
      className="absolute inset-0 z-50 flex h-full items-center justify-center bg-white/30 backdrop-blur-sm" // Changed to bg-opacity for better visibility
      onClick={onClose}
    >
      <div
        className="relative flex h-full w-full items-center justify-center rounded-lg" // Add background gradient
        onClick={onClose}
      >
        <div
          className="absolute top-0 z-50 w-full bg-white/30 backdrop-blur-sm"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between px-6 xl:py-2">
            <div className="w-full"></div>
            <div className="flex w-full flex-col items-center justify-center space-y-1">
              <div className="font-medium text-[#A6ABC8]">Followed BY</div>
              <div className="font-medium text-[#1F384C]">Dr, Saad Slimani</div>
            </div>
            <div className="flex w-full items-center justify-end">
              <label className="switch">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={isChecked}
                  onChange={handleChange}
                />
                <div className="slider"></div>
              </label>
            </div>
          </div>
        </div>
        <div
          className="relative flex justify-center items-center"
          onClick={e => e.stopPropagation()}
        >
          <button
            className={`${
              globalState?.deviceId ? 'visible' : 'invisible'
            } rounded-[50%]} absolute z-50 flex h-full w-full cursor-pointer items-center justify-center  text-white ${!globalState.isVideoFrozen ? '' : 'bg-black/30 backdrop-blur-sm'}`}
            onClick={() => {
              handleVideoClick();
            }}
          ></button>
          {isLoading && (
            <div className="absolute inset-0 z-50 flex items-center justify-center">
              <div className="inline-flex items-center space-x-2 text-blue-600">
                <LuLoader2
                  className="animate-spin"
                  size={18}
                />
              </div>
            </div>
          )}
          
          <video
            ref={modalVideoRef}
            autoPlay
            playsInline
            width={displayDimensions.width}
            height={displayDimensions.height}
            onClick={handleVideoClick}
            style={{
              filter: globalState.isVideoFrozen ? 'blur(10px)' : 'none',
              cursor: 'pointer',
              display: !isInferencing && isLoading == false ? 'block' : 'none',
            }}
          />

          {/* WebRTC video element - shows when inferencing */}
          <video
            ref={modalWebrtcVideoRef}
            autoPlay
            playsInline
            width={displayDimensions.width}
            height={displayDimensions.height}
            onClick={handleVideoClick}
            style={{
              cursor: 'pointer',
              display: isInferencing && isLoading == false  ? 'block' : 'none',
            }}
          />
          {isLoading == false &&
          <div
            className="absolute bottom-3 right-3 z-50 cursor-pointer text-white"
            onClick={onClose}
          >
            <ImShrink2 size={18} />
          </div>
          }
          
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
