import { Button, Form, Input, Menu, Spin } from 'antd';
import React, { useState } from 'react';
import { usePasswordReset } from '../../../helpers/api/users/users';

const Settings = () => {
  const [form] = Form.useForm();
  const passwordReset = usePasswordReset();
  const [selectedMenu, setSelectedMenu] = useState('resetPassword');

  const handleMenuClick = e => {
    setSelectedMenu(e.key);
  };

  return (
    <div className="mt-2 flex">
      <div className="h-screen w-1/4 p-4">
        <Menu
          mode="vertical"
          selectedKeys={[selectedMenu]}
          onClick={handleMenuClick}
        >
          <Menu.Item key="resetPassword">Reset Password</Menu.Item>
          <Menu.Item key="profileSettings">Profile Settings</Menu.Item>
          <Menu.Item key="notifications">Notifications</Menu.Item>
          <Menu.Item key="preferences">Preferences</Menu.Item>
        </Menu>
      </div>

      <div className="w-3/4 p-6">
        {selectedMenu === 'resetPassword' && (
          <Form
            form={form}
            layout="vertical"
            onFinish={values => {
              const formData = new FormData();
              formData.append('new_password', values.password);
              passwordReset.mutate(formData);
              form.resetFields();
            }}
          >
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please input password!' }]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item
              name="repeat_password"
              label="Repeat password"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please repeat password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Repeat password" />
            </Form.Item>
            <Form.Item className="flex w-full justify-end">
              <button
                type="submit"
                className={`inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm ${passwordReset.isPending ? 'cursor-not-allowed' : 'hover:bg-blue-600'}`}
                disabled={passwordReset.isPending} // Disable button when isPending
              >
                {passwordReset.isPending ? <Spin size="small text-white" /> : 'Reset'}
              </button>
            </Form.Item>
          </Form>
        )}

        {selectedMenu === 'profileSettings' && <div>Profile Settings Form (Coming Soon)</div>}
        {selectedMenu === 'notifications' && <div>Notification Settings Form (Coming Soon)</div>}
        {selectedMenu === 'preferences' && <div>Preferences Settings Form (Coming Soon)</div>}
      </div>
    </div>
  );
};

export default Settings;
