import { PlaneConfig } from '../types/planes.types';

import Four_Chamber_View_of_the_Heart from '../../../assets/iconsPlans/Four_Chamber_View_of_the_Heart.png';
import Fetal_Abdominal_Standard_Plane from '../../../assets/iconsPlans/Fetal_Abdominal_Standard_Plane.png';
import View_of_Genitalia from '../../../assets/iconsPlans/View_of_Genitalia.png';
import View_of_Upper_Limb from '../../../assets/iconsPlans/View_of_Upper_Limb.png';
import View_of_Lower_Limb_ from '../../../assets/iconsPlans/View_of_Lower_Limb_.png';
import Axial_View_of_Kidneys from '../../../assets/iconsPlans/Axial_View_of_Kidneys.png';
import Axial_View_of_Fetal_Bladder from '../../../assets/iconsPlans/Axial_View_of_Fetal_Bladder.png';
import Coronal_View_of_Upper_Lip_Nose_Nostrils from '../../../assets/iconsPlans/Coronal_View_of_Upper_Lip_Nose_Nostrils.png';
import Femur_Plane from '../../../assets/iconsPlans/Femur_Plane.png';
import Sagittal_View_of_Cervix_with_Placenta from '../../../assets/iconsPlans/Sagittal_View_of_Cervix_with_Placenta.png';
import Sagittal_View_for_CRL_and_NC_Measurements from '../../../assets/iconsPlans/Sagittal_View_for_CRL_and_NC_Measurements.png';
import Transcerebellar_View from '../../../assets/iconsPlans/Transcerebellar_View.png';
import Transventricular_View from '../../../assets/iconsPlans/Transventricular_View.png';
import Transthalamic_View from '../../../assets/iconsPlans/Transthalamic_View.png';
import Sagittal_View_of_the_Entire_Spine from '../../../assets/iconsPlans/Sagittal_View_of_the_Entire_Spine.png';
import Coronal_View_of_the_Spine from '../../../assets/iconsPlans/Coronal_View_of_the_Spine.png';
import Sagittal_View_of_the_Brain from '../../../assets/iconsPlans/Sagittal_View_of_the_Brain.png';
import LVOT from '../../../assets/iconsPlans/LVOT.png';
import RVOT from '../../../assets/iconsPlans/RVOT.png';
import Three_Vessel_View from '../../../assets/iconsPlans/Three_Vessel_View.png';
import Three_Vessel_View_Trachea from '../../../assets/iconsPlans/Three_Vessel_View_Trachea.png';
import Sagittal_View_of_the_Face from '../../../assets/iconsPlans/Sagittal_View_of_the_Face.png';


export const PLANES_CONFIGS: PlaneConfig[] = [
    {
      label: 'Four Chamber View of the Heart',
      tag: 'Four_Chamber_View_of_the_Heart',
      defaultImage: Four_Chamber_View_of_the_Heart,
    },
    {
      label: 'LVOT (Left Ventricle Outflow Tract) View', 
      tag: 'LVOT',
      defaultImage: LVOT,
    },
    {
      label: 'RVOT (Right Ventricle Outflow Tract) View',
      tag: 'RVOT', 
      defaultImage: RVOT,
    },
    {
      label: 'Vessel View',
      tag: 'Three_Vessel_View',
      defaultImage: Three_Vessel_View,
    },
    {
      label: 'Vessel View & Trachea',
      tag: 'Three_Vessel_View_Trachea',
      defaultImage: Three_Vessel_View_Trachea,
    },
    {
      label: 'Sagittal View of the Face',
      tag: 'Sagittal_View_of_the_Face',
      defaultImage: Sagittal_View_of_the_Face,
    },
    {
      label: 'Sagittal View of the Brain',
      tag: 'Sagittal_View_of_the_Brain',
      defaultImage: Sagittal_View_of_the_Brain,
    },
    {
      label: 'Transcerebellar View',
      tag: 'Transcerebellar_View',
      defaultImage: Transcerebellar_View,
    },
    {
      label: 'Transventricular View',
      tag: 'cephalic',
      defaultImage: Transventricular_View,
    },
    {
      label: 'Transthalamic View',
      tag: 'cephalic',
      defaultImage: Transthalamic_View,
    },
    {
      label: 'Sagittal View of the Entire Spine',
      tag: 'Sagittal_View_of_the_Entire_Spine',
      defaultImage: Sagittal_View_of_the_Entire_Spine,
    },
    {
      label: 'Coronal View of the Spine',
      tag: 'Coronal_View_of_the_Spine',
      defaultImage: Coronal_View_of_the_Spine,
    },
    {
      label: 'Fetal Abdominal Standard Plane',
      tag: 'abdominal',
      defaultImage: Fetal_Abdominal_Standard_Plane,
    },
    {
      label: 'View of Genitalia',
      tag: 'View_of_Genitalia',
      defaultImage: View_of_Genitalia,
    },
    {
      label: 'View of Upper Limb',
      tag: 'View_of_Upper_Limb',
      defaultImage: View_of_Upper_Limb,
    },
    {
      label: 'View of Lower Limb',
      tag: 'View_of_Lower_Limb_',
      defaultImage: View_of_Lower_Limb_,
    },
    {
      label: 'Axial View of Kidneys',
      tag: 'Axial_View_of_Kidneys',
      defaultImage: Axial_View_of_Kidneys,
    },
    {
      label: 'Axial View of Fetal Bladder',
      tag: 'Axial_View_of_Fetal_Bladder',
      defaultImage: Axial_View_of_Fetal_Bladder,
    },
    {
      label: 'Coronal View of Upper Lip, Nose & Nostrils',
      tag: 'Coronal_View_of_Upper_Lip_Nose_Nostrils',
      defaultImage: Coronal_View_of_Upper_Lip_Nose_Nostrils,
    },
    {
      label: 'Femur Plane',
      tag: 'femur',
      defaultImage: Femur_Plane,
    },
    {
      label: 'Sagittal View for CRL and NC Measurements',
      tag: 'Sagittal_View_for_CRL_and_NC_Measurements',
      defaultImage: Sagittal_View_for_CRL_and_NC_Measurements,
    },
    {
      label: 'Sagittal View of Cervix with Placenta',
      tag: 'Sagittal_View_of_Cervix_with_Placenta',
      defaultImage: Sagittal_View_of_Cervix_with_Placenta,
    }
  ];