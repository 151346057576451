import React, { useEffect, useState } from 'react';

import * as rdrLocales from 'react-date-range/dist/locale';

import { PaginationState, useReactTable, getCoreRowModel } from '@tanstack/react-table';

import { Form, Input, Button } from 'antd';

import { Link } from 'react-router-dom';

import { APIClient } from '../../../helpers/apiHelper';
import {
  usePaginatedPatients,
  useArchivePatient,
  useUnarchivePatient,
  useArchivePatients,
  useUnarchivePatients
} from '../../../helpers/api/patients/patients';
import classNames from 'classnames';
import PatientModal from './patient.modal';
import DateRangePicker from '../../Components/date-range-picker';
import ManageAccess from './ManageAccess';
import { handleDateFilter, handleValidDate } from './date_utils';
import Table from '../../Components/Table/Table';
import { useDebouncedCallback } from 'use-debounce';

export const api = new APIClient();

const style = {
  fontFamily: 'Outfit, sans-serif',
};

const PatientManagement = () => {

  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [pendingOperations, setPendingOperations] = useState(new Set());
  const [refetchingIds, setRefetchingIds] = useState<string[]>([]);
  const [isBulkOperationPending, setIsBulkOperationPending] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [searchMode, setSearchMode] = useState('OR');




  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    reset: true,
  });

  const [form] = Form.useForm();

  const [tabs, setTabs] = useState([
    {
      name: 'Active',
      icon: 'ri-checkbox-blank-circle-line',
      active: true,
    },
    {
      name: 'Archived',
      icon: 'ri-archive-line',
      active: false,
    },
  ]);
  const [toEdit, setToEdit] = useState();

  const [isEditing, setIsEditing] = useState(false);

  const [filters, setFilters] = useState({});
  const handleFilterChange = e => {
    const { name, value } = e.target;

    setFilters(prev => {
      const updatedFilters = { ...prev };

      if (value === '') {
        delete updatedFilters[name];
      } else {
        updatedFilters[name] = value;
      }
      console.log("================= updatedFilters pm", updatedFilters);
      return updatedFilters;
    });
  };

  const debouncedFilterChange = useDebouncedCallback(handleFilterChange, 500);


  const handleRefetch = async (ids: string[]) => {
    setRefetchingIds(prev => [...prev, ...ids]);
    await patients.refetch();
    setRefetchingIds(prev => prev.filter(id => !ids.includes(id)));
  };

  const toggleSearchMode = () => {
    setSearchMode(prevSearchMode => (prevSearchMode === 'AND' ? 'OR' : 'AND'));
  };

  const [patientModalOpen, setPatientModalOpen] = useState(false);

  const openNewPatientModal = () => {
    setIsEditing(false);
    setPatientModalOpen(true);
  };

  const openEditPatientModal = id => {
    setIsEditing(true);
    setPatientModalOpen(true);
    setToEdit(id);
  };

  const handleOperation = async (id: number, operation: 'archive' | 'unarchive') => {
    // Don't check patient state for unarchive operations since it won't be in the current view
    if (pendingOperations.has(id) || 
        (operation === 'archive' && patients?.data?.items.find(patient => 
            patient.id === id)?.archived)) {
      return;
    }

    setPendingOperations(prev => new Set([...Array.from(prev), id]));
    
    try {
      const mutateFunction = operation === 'archive' ? archivePatient : unarchivePatient;
      await mutateFunction.mutateAsync([id], {
        onSuccess: async () => {
          await handleRefetch([id.toString()]);
          table.resetRowSelection();
        }
      });
    } catch (error) {
      console.error(`Failed to ${operation} patient:`, error);
    } finally {
      setPendingOperations(prev => {
        const next = new Set(prev);
        next.delete(id);
        return next;
      });
    }
  };


  const handleBulkAction = async (ids: number[]) => {
    const newIds = ids.filter(id => !Array.from(pendingOperations).includes(id));
    if (newIds.length === 0) return;

    setIsBulkOperationPending(true);
    setPendingOperations(prev => new Set([...Array.from(prev), ...newIds]));

    try {
      if (tabs.find(tab => tab.active)?.name === 'Active') {
        await archivePatients.mutateAsync(newIds, {
          onSuccess: async () => {
            await handleRefetch(newIds.map(id => id.toString()));
            table.resetRowSelection();
          },
          onSettled: () => {
            setIsBulkOperationPending(false);
            setPendingOperations(prev => 
              new Set(Array.from(prev).filter((id: number) => !newIds.includes(id)))
            );
          }
        });
      } else {
        await unarchivePatients.mutateAsync(newIds, {
          onSuccess: async () => {
            await handleRefetch(newIds.map(id => id.toString()));
            table.resetRowSelection();
          },
          onSettled: () => {
            setIsBulkOperationPending(false);
            setPendingOperations(prev =>
              new Set(Array.from(prev).filter((id: number) => !newIds.includes(id)))
            );
          }
        });
      }
    } catch (error) {
      console.error('Failed to process patients:', error);
    }
  };

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select', // Row selection column
        header: ({ table }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              id="checkBoxAll"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        ),
      },
      {
        accessorKey: 'patient_name',
        header: () => <span>First Name</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'patient_last_name',
        header: () => <span>Last Name</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'birth_date',
        cell: cell => <>{handleValidDate(cell.getValue())}</>,
        header: () => <span>Date of Birth</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'email',
        cell: info => info.getValue(),
        header: () => <span>Email</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'owner',
        cell: cellProps => (
          <div className="flex items-center gap-2">
            {cellProps.row.original.owner ? cellProps.row.original.owner.name : '_'}
          </div>
        ),
        header: () => <span>Doctor In Charge</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'insurance_provider',
        cell: info => info.getValue(),
        header: () => <span>Insurance Provider</span>,
        footer: props => props.column.id,
      },
      {
        header: 'Actions',
        cell: cellProps => {
          console.log("================== cellProps.row.original", cellProps.row.original);
          
          const currentId = cellProps.row.original.id;
          const isAnyOperationPending = pendingOperations.size > 0 || refetchingIds.length > 0;
          const isCurrentOperationPending = pendingOperations.has(currentId) || refetchingIds.includes(currentId);
      
          return (
            <ul className="flex">
              <>
                {!cellProps.row.original.archived && (
                  <Link to={`/patients/${cellProps.row.original.id}`}>
                    <li className="mr-2 cursor-pointer text-blue-800">
                      <span className="text-primary d-inline-block">
                        <i className="ri-eye-fill fs-16"></i>
                      </span>
                    </li>
                  </Link>
                )}
                
                {!cellProps.row.original.archived && (
                  <li
                    className="mr-2 cursor-pointer text-green-500"
                    onClick={() => openEditPatientModal(cellProps.row.original.id)}
                  >
                    <span className="text-primary d-inline-block">
                      <i className="ri-pencil-line fs-16"></i>
                    </span>
                  </li>
                )}
      
                <li
                  onClick={() => !isAnyOperationPending && 
                    handleOperation(
                      cellProps.row.original.id, 
                      cellProps.row.original.archived ? 'unarchive' : 'archive'
                    )}
                  className={`mr-2 ${
                    isAnyOperationPending || isCurrentOperationPending
                      ? 'cursor-not-allowed text-gray-400' 
                      : `cursor-pointer ${cellProps.row.original.archived ? 'text-green-500' : 'text-red-500'}`
                  }`}
                >
                  <span className="text-primary d-inline-block">
                    {isCurrentOperationPending ? (
                      <i className="ri-loader-4-line animate-spin fs-16"></i>
                    ) : (
                      <i className={`ri-inbox-${cellProps.row.original.archived ? 'unarchive' : 'archive'}-line fs-16`}></i>
                    )}
                  </span>
                </li>
      
                {!cellProps.row.original.archived && (
                  <button
                    className=""
                    onClick={() => {
                      setSelectedPatient(cellProps.row.original.id);
                      setIsPermissionsModalOpen(true);
                    }}
                  >
                    <li className="cursor-pointer text-orange-500">
                      <span className="text-primary d-inline-block">
                        <i className="ri-lock-fill fs-16"></i>
                      </span>
                    </li>
                  </button>
                )}
              </>
            </ul>
          );
        },
      }
    ],
    [pendingOperations, refetchingIds]
  );

  const buildFilters = (filters, searchMode) => {
    const updatedFilters = {};

    Object.keys(filters).forEach(key => {
      const newKey = `${key}__ilike`;
      const newValue = `%${filters[key]}%`;
      updatedFilters[newKey] = newValue;
    });

    if (searchMode === 'OR') {
      return { condition__or: updatedFilters };
    }

    return updatedFilters;
  };

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const patients = usePaginatedPatients(
    pagination.pageIndex + 1,
    pagination.pageSize,
    {
      archived: !(tabs.find(tab => tab.active)?.name === 'Active'),
      ...(Object.keys(buildFilters(filters, searchMode)).length > 0 && {
        filters: JSON.stringify({
          ...buildFilters(filters, searchMode),
          ...handleDateFilter(dateRange),
        }),
      }),
    },
    { refetchInterval: 60000 }
  );

  console.log("==================== patients", patients);
  

  const archivePatient = useArchivePatient();
  const unarchivePatient = useUnarchivePatient();
  const archivePatients = useArchivePatients();
  const unarchivePatients = useUnarchivePatients()


  console.log("==================== archivePatient", archivePatient);
  

  const table = useReactTable({
    data: patients?.data?.items ?? [],
    columns,
    rowCount: patients?.data?.total_count,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  const selectedRecords = table.getSelectedRowModel().rows.map(row => row.original);
  const setActiveTab = (name: string) => {
    setTabs(prev =>
      prev.map(item =>
        item.name === name ? { ...item, active: true } : { ...item, active: false }
      )
    );
  };

  
  const toggleDropdown = () => {
    setFiltersVisible(prevState => !prevState);
  };

  const closePatientModal = () => {
    setIsEditing(false);
    setPatientModalOpen(false);
  };

  useEffect(() => {
    setTimeout(patients.refetch, 100);
  }, [dateRange, filters, tabs, searchMode]);





  return (
    <div
      className="flex h-full flex-col px-4"
      style={style}
    >
      <ManageAccess
        patient_id={selectedPatient}
        isModalOpen={isPermissionsModalOpen}
        setIsModalOpen={setIsPermissionsModalOpen}
      />
      {patientModalOpen && (
        <PatientModal
          closePatientModal={closePatientModal}
          edit={isEditing}
          isOpen={patientModalOpen}
          toEdit={toEdit}
        />
      )}
      {/* <div className="mb-2">
        <h5 className="text-gray-600">Manage Patients</h5>
      </div> */}
      <div className="relative my-4 h-full rounded-md border border-gray-200 bg-white">
        <div className="p-2">
          <div>
            <div className="flex h-8 w-full flex-row-reverse">
              <div className="relative">
                <button
                  onClick={openNewPatientModal}
                  id="new-role"
                  className="ml-2 flex h-8 items-center justify-between rounded bg-blue-600 font-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
                >
                  <i className="ri-add-large-line flex h-full w-8 items-center justify-center rounded-l bg-[rgba(255,255,255,0.1)] py-2 text-center"></i>
                  <span className="px-2 py-2">New</span>
                </button>
              </div>

              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className={classNames(
                    'border-sm flex h-8 items-center justify-between rounded border font-normal transition duration-150 ease-in-out focus:outline-none focus:ring-2',
                    {
                      'border-blue-600 bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-300':
                        filtersVisible,
                      'border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white focus:ring-blue-200':
                        !filtersVisible,
                    }
                  )}
                >
                  <i className="ri-equalizer-line flex h-full w-8 items-center justify-center py-2 text-center"></i>
                  <span className="py-2 pr-2">Filters</span>
                </button>
              </div>
            </div>

            <div className="container mx-auto">
              <div
                className={classNames(
                  'mt-4 flex flex-col gap-2 transition-all duration-300 md:flex-row',
                  {
                    'h-0 overflow-hidden': !filtersVisible,
                    'h-auto': filtersVisible,
                  }
                )}
              >
                <DateRangePicker
                  locale={rdrLocales.fr}
                  range={dateRange}
                  type="range"
                  color="#f85a32"
                  onChange={dates => {
                    setDateRange(dates);
                  }}
                  onReset={dates => {
                    setDateRange(dates);
                  }}
                  isDisabled={false}
                  isLoading={false}
                  inputRanges={[]}
                  showDateDisplay={true}
                  showMonthAndYearPickers={true}
                  maxDate={new Date()}
                />
                <Form
                  form={form}
                  className="w-full"
                >
                  <div className="flex flex-wrap gap-4">
                    {' '}
                    {/* Flexbox container for form items */}
                    <Form.Item
                      className="w-full md:w-1/4" // Full width on small screens, 1/4 width on medium+
                      name="patient_name"
                    >
                      <Input
                        name="patient_name"
                        placeholder="First name"
                        onChange={debouncedFilterChange}
                      />
                    </Form.Item>
                    <Form.Item
                      className="w-full md:w-1/4"
                      name="patient_last_name"
                    >
                      <Input
                        name="patient_last_name"
                        placeholder="Last name"
                        onChange={debouncedFilterChange}
                      />
                    </Form.Item>
                    <Form.Item
                      className="w-full md:w-1/4"
                      name="email"
                    >
                      <Input
                        name="email"
                        placeholder="Email"
                        onChange={debouncedFilterChange}
                      />
                    </Form.Item>
                    <div className="w-full md:w-auto">
                      {' '}
                      {/* Full width on small screens, auto on larger */}
                      <Button
                        onClick={toggleSearchMode}
                        className="w-full bg-blue-500 text-white md:w-auto"
                      >
                        {searchMode}
                      </Button>
                    </div>
                    <div className="w-full md:w-auto">
                      {' '}
                      {/* Full width on small screens, auto on larger */}
                      <Button
                        type="primary"
                        className="w-full bg-blue-500 text-white md:w-auto"
                        onClick={() => {
                          form.resetFields(); // Reset all form fields
                          setFilters({});
                          setDateRange({
                            startDate: new Date(),
                            endDate: new Date(),
                            reset: true,
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="mb-2 flex justify-between border-b border-gray-200">
            <ul
              id="type-tab"
              className="-mb-px flex flex-wrap text-center text-sm font-normal text-gray-500"
            >
              {tabs.map((tab, index) => (
                <li
                  className="mr-3"
                  key={index}
                >
                  <span
                    onClick={() => {
                      setActiveTab(tab.name);
                    }}
                    className={`group inline-flex cursor-pointer items-center justify-center gap-1 p-2 transition duration-150 ease-in-out ${
                      tab.active
                        ? 'border-b-2 border-blue-600 font-semibold text-blue-600'
                        : 'border-b-2 border-transparent text-gray-700 hover:text-gray-600'
                    }`}
                    aria-current={tab.active ? 'page' : undefined}
                  >
                    <i className={`${tab.icon} flex h-4 w-4 items-center text-lg`}></i>
                    {tab.name}
                  </span>
                </li>
              ))}
            </ul>
            {selectedRecords.length > 0 && (
              <button
                onClick={() => handleBulkAction(selectedRecords.map(patient => patient.id))}
                disabled={isBulkOperationPending || (pendingOperations.length > 0 || refetchingIds.length > 0)}
                className={`flex items-center gap-1 px-1 text-sm rounded ${
                  isBulkOperationPending || (pendingOperations.length > 0 || refetchingIds.length > 0)
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700'
                } text-white transition-colors`}
              >
                {isBulkOperationPending || (pendingOperations.length > 0 || refetchingIds.length > 0) ? (
                  <span className="flex items-center gap-1">
                    <i className="ri-loader-4-line animate-spin"></i>
                    Processing
                  </span>
                ) : (
                  <span className="flex items-center gap-1">
                    <i className={tabs.find(tab => tab.active)?.name === 'Active' 
                      ? 'ri-archive-line' 
                      : 'ri-inbox-unarchive-line'}
                    />
                    {tabs.find(tab => tab.active)?.name === 'Active' 
                      ? `Archive Selected (${selectedRecords.length})` 
                      : `Unarchive Selected (${selectedRecords.length})`}
                  </span>
                )}
              </button>
            )}
          </div>
        </div>

        <Table
          table={table}
          totalCount={patients?.data?.total_count}
          isLoading={patients.isLoading}
          pageSize={pagination.pageSize}
          columnsLength={columns.length}
        />
      </div>
    </div>
  );
};

export default PatientManagement;