import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, ConfigProvider, DatePicker, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import Modal from '../../Components/modal';
import Input from '../../Components/Input/Input.component';
import {
  useCreatePatient,
  useEditPatient,
  usePaginatedPatients,
  useRecentPatients,
  useSinglePatient,
} from '../../../helpers/api/patients/patients';

const PatientForm = ({ closePatientModal, isOpen, edit, toEdit }) => {
  const { refetch: refetchRecentPatients } = useRecentPatients(30, false);
  const { refetch: refetchPatients } = usePaginatedPatients(1, 15, {
    archived: false,
  });
  const createPatient = useCreatePatient();
  console.log('========== createPatient', createPatient);
  const editPatient = useEditPatient();

  const patient = useSinglePatient(edit ? toEdit : -1);

  const validationSchema = Yup.object({
    patient_name: Yup.string()
      .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, 'First Name should contain only alphabets')
      .required('Please enter the first name'),
    patient_last_name: Yup.string()
      .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, 'Last Name should contain only alphabets')
      .required('Please enter the last name'),
    birth_date: Yup.date()
      .nullable()
      .transform((value, originalValue) =>
        dayjs(originalValue, 'YYYY-MM-DD', true).isValid() ? value : new Date('')
      )
      .typeError('Date of Birth must be in the format YYYY-MM-DD')
      .required('Please enter a valid date'),
    phone_number: Yup.string()
      .nullable()
      .matches(/^\+?[0-9]+(?:[ ]?[0-9]+)*$/, 'Must be a valid phone number'),
    email: Yup.string().email('Please enter a valid email address').notRequired(),
    policy_number: Yup.string().nullable(),
    insurance_provider: Yup.string().nullable(),
    emergency_details: Yup.string().nullable(),
    address: Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      patient_name: patient?.data?.data?.patient_name || '',
      patient_middle_name: patient?.data?.data?.patient_middle_name || '',
      patient_last_name: patient?.data?.data?.patient_last_name || '',
      policy_number: patient?.data?.data?.policy_number || '',
      insurance_provider: patient?.data?.data?.insurance_provider || '',
      phone_number: patient?.data?.data?.phone_number || '',
      email: patient?.data?.data?.email || '',
      emergency_details: patient?.data?.data?.emergency_details || '',
      address: patient?.data?.data?.address || '',
      birth_date: patient?.data?.data?.birth_date
        ? dayjs(new Date(patient?.data?.data?.birth_date))
        : '',
    },
    validationSchema,
    onSubmit: values => {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => value !== '')
      );
      if (!edit) {
        createPatient.mutate(filteredValues);
      } else {
        editPatient.mutate({ patient_id: toEdit, values: filteredValues });
      }
    },
  });

  useEffect(() => {
    if (createPatient.isSuccess || editPatient.isSuccess) {
      closePatientModal();
      refetchRecentPatients();
      refetchPatients();
    }
  }, [createPatient.isSuccess, editPatient.isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        closePatientModal();
      }}
      size={'medium'}
      overlayClassName="top-0 right-0 left-0"
    >
      <Modal.Header
        title={
          <div className="flex w-full justify-between">
            <div className="flex w-full justify-start text-lg font-semibold">
              {edit ? 'Edit Patient' : 'Add New Patient'}
            </div>
          </div>
        }
        onClose={() => {
          closePatientModal();
        }}
        displayClose={true}
      />
      <Modal.Content>
        {editPatient.error || createPatient.error ? (
          <div
            className="mb-4 rounded-lg bg-red-100 p-4 text-sm text-red-800 border"
            role="alert"
          >
            <span className="font-medium">Error!</span> An error has occured while{' '}
            {editPatient.error ? 'editing' : 'creating'} the patient.
          </div>
        ) : null}
        {edit && patient.isLoading ? (
          <>loading</>
        ) : (
          <div className="py-3 px-5">
            <form
              onSubmit={formik.handleSubmit}
              className="grid grid-cols-1 gap-4 sm:grid-cols-2"
            >
              {/* First Name */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <Input
                  name="patient_name"
                  placeholder="First Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.patient_name}
                  invalid={formik.touched.patient_name && Boolean(formik.errors.patient_name)}
                />
                {formik.touched.patient_name && formik.errors.patient_name && (
                  <div className="text-sm text-red-500">{formik.errors.patient_name}</div>
                )}
              </div>

              {/* Middle Name */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Middle Name</label>
                <Input
                  name="patient_middle_name"
                  placeholder="Middle Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.patient_middle_name}
                  invalid={
                    formik.touched.patient_middle_name && Boolean(formik.errors.patient_middle_name)
                  }
                />
              </div>

              {/* Last Name */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <Input
                  name="patient_last_name"
                  placeholder="Last Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.patient_last_name}
                  invalid={
                    formik.touched.patient_last_name && Boolean(formik.errors.patient_last_name)
                  }
                />
                {formik.touched.patient_last_name && formik.errors.patient_last_name && (
                  <div className="text-sm text-red-500">{formik.errors.patient_last_name}</div>
                )}
              </div>

              {/* Date of Birth */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    value={
                      formik.values.birth_date ? dayjs(new Date(formik.values.birth_date)) : null
                    }
                    onChange={date =>
                      formik.setFieldValue(
                        'birth_date',
                        date ? dayjs(date).format('YYYY-MM-DD') : ''
                      )
                    }
                    className={`w-full ${formik.touched.birth_date && formik.errors.birth_date ? 'border-red-500' : ''}`}
                  />
                </ConfigProvider>
                {formik.touched.birth_date && formik.errors.birth_date && (
                  <div className="text-sm text-red-500">{formik.errors.birth_date}</div>
                )}
              </div>

              {/* Policy Number */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Policy Number</label>
                <Input
                  name="policy_number"
                  placeholder="Policy Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.policy_number}
                />
              </div>

              {/* Insurance Provider */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">
                  Insurance Provider
                </label>
                <Input
                  name="insurance_provider"
                  placeholder="Insurance Provider"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.insurance_provider}
                />
              </div>

              {/* Phone Number */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                <Input
                  name="phone_number"
                  placeholder="Phone Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone_number}
                />
                {formik.touched.phone_number && formik.errors.phone_number && (
                  <div className="text-sm text-red-500">{formik.errors.phone_number}</div>
                )}
              </div>

              {/* Email */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <Input
                  name="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-sm text-red-500">{formik.errors.email}</div>
                )}
              </div>

              {/* Emergency Details */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Emergency Details</label>
                <Input
                  name="emergency_details"
                  placeholder="Emergency Details"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emergency_details}
                />
              </div>

              {/* Address */}
              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <Input
                  name="address"
                  placeholder="Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
              </div>

              <div className="col-span-2 flex justify-end">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default PatientForm;
