import {
  CopyFilled,
  CopyOutlined,
  FileDoneOutlined,
  UserOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Action from '../../Components/Action/action.component';
import './recentPatient.section.css';
import { Avatar, List, message } from 'antd';
import VirtualList from 'rc-virtual-list';
import { Input, Space } from 'antd';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Patient } from '../PatientsList/patientsList.section.custom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPatients } from '../../../store/actions';
import { useDebouncedCallback } from 'use-debounce';

interface RecentPatientsProps {
  PatientsList: Patient[];
}

const RecentPatients: React.FC<RecentPatientsProps> = ({ PatientsList }) => {
  const [search, setSearch] = useState('');
  const onSearch = (value: string) => console.log(value);
  const dispatch = useDispatch();
  const searchPatients = (search: string) => {
    const dateFormatRegex = /^(\d{4}-\d{2}-\d{2}|\d{3}\/\d{2}-\d{2})$/;
    if (dateFormatRegex.test(search)) {
      dispatch(
        getPatients({
          filter_options: {
            birth_date__lt: search /* dayjs(search).format('YYYY/MM/DD')*/,
            page: 1,
            // items_per_page: itemsPerPage
          },
        })
      );
      // setPage(1);
    } else {
      // ask about the default values for the page and items_per_page
      dispatch(
        getPatients({
          filter_options: {
            patient_name__contains: search,
            page: 1,
            // items_per_page: itemsPerPage
          },
        })
      );
      // setPage(1);
    }
  };
  const debouncedSearchBackend = useDebouncedCallback(searchPatients, 500, {
    maxWait: 2000,
    leading: true,
    trailing: false,
  });
  useEffect(() => {
    if (search.length > 0) {
      debouncedSearchBackend(search);
    } else {
      dispatch(
        getPatients({
          filter_options: {
            page: 1,
            // items_per_page: itemsPerPage,
          },
        })
      );
      // setPage(1);
    }
    return () => {
      debouncedSearchBackend.cancel();
    };
  }, [search, debouncedSearchBackend]);

  console.log('patient', PatientsList);
  return (
    <div className="flex h-full w-full flex-col">

      <div className="h-[calc(100%-20px)] w-full overflow-auto custom-scrollbar">
        {PatientsList?.length === 0 && (
          <div className="flex h-full w-full items-center justify-center">
            <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
              No Patient available
            </p>
          </div>
        )}
        <List>
          {PatientsList.map((patient, index) => {
            const firstInitial = patient.patient_name ? patient.patient_name[0] : '';
            const lastInitial = patient.patient_last_name ? patient.patient_last_name[0] : '';
            return (
              <List.Item key={index}>
                <Link
                  to={`/patients/${patient.id}`}
                  className="flex items-center justify-center space-x-3"
                >
                  {/* <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrXh66ZR5-pcHt-LvRPo40zHoCCnzJa_hqzBaO6FSm5pE9j7FMUcD26sIgdOdh-oUILn8&usqp=CAU"
                  alt=""
                  className="h-8 w-8 rounded-full"
                /> */}
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#deeafa] p-2 text-[#2d84c7]">
                    {' '}
                    {firstInitial}
                    {lastInitial}
                  </div>
                  <div className="text-[#1F384C]">
                    <p className="text-sm font-bold">{patient.patient_name}</p>
                    <p className="text-xs font-medium">36 weeks, 5 days</p>
                  </div>
                </Link>
              </List.Item>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default RecentPatients;
